import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup2 } from '@common/antd/HgbAntdRadioGroup2';
import { HgbResponsive } from '@common/components';
import { HgbSelect, parseToHgbSelectOptions } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { emptyToUndefined } from '@utils/text';
import { Checkbox } from 'antd';
import { includes, isEmpty } from 'lodash';
import { FunctionComponent, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQuery, useHgbQueryDownload } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
  GetStatusListBoxRequest,
  GetStatusListBoxResponse,
} from 'services/types/bank';

type ExportType = 'EXCEL' | 'CSV';

type FormData = {
  bankId: string;
  exportType: ExportType;
  parentStatuses: string;
  childStatuses: string;
};

type DownloadRequest = {
  bankId?: string;
  exportType: ExportType;
  parentStatuses: string;
  childStatuses: string;
};

export const E02: FunctionComponent = (props) => {
  const { user } = useContext(AuthContext)!;
  const {
    text: { E02, common },
    language,
  } = useContext(LanguageContext)!;
  const { addMessage } = useContext(PortalContext)!;

  const [isOpen, setIsOpen] = useState(false);

  const methods = useForm<FormData>({
    values: {
      bankId: '',
      exportType: 'EXCEL',
      parentStatuses: '',
      childStatuses: '',
    },
  });

  const { register, watch, setValue } = methods;
  const formValues = watch();
  const [loading, setLoading] = useState(false);

  const { data: banksQuery, isFetchedAfterMount: isBankLBFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      undefined,
      {
        enabled: user.isPresidingBank,
      },
    );

  const { data: statusQuery } = useHgbQuery<
    GetStatusListBoxResponse,
    GetStatusListBoxRequest
  >(
    API_URLS.ENTERPRISE_STATUS_API_URL,
    {
      hasChildStatuses: true,
    },
    {
      queryKey: [API_URLS.ENTERPRISE_STATUS_API_URL],
    },
  );

  const { refetch: downloadReport } = useHgbQueryDownload<any, DownloadRequest>(
    API_URLS.ENTERPRISE_OUTPUT_REPORT,
    {
      bankId: emptyToUndefined(formValues.bankId),
      exportType: formValues.exportType,
      parentStatuses: formValues.parentStatuses,
      childStatuses: formValues.childStatuses,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        const { file, fileName } = data ?? {};
        setLoading(false);
        setIsOpen(false);
        if (!data) {
          addMessage('error', common.message.noResult);
          return;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([file]));
        link.setAttribute('download', decodeURIComponent(fileName));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  );

  const bankIdOptions = parseToHgbSelectOptions(banksQuery?.result);
  const statusOption = statusQuery?.result;

  const emptyDescription = useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(banksQuery?.result) && isBankLBFetchedAfterMount)
        return common.message.partnerBanksEmpty;
      if (isEmpty(formValues.bankId))
        return common.message.choosePartnerBankAndCompany;
    }
  }, [
    user.isPresidingBank,
    user.isManagementBank,
    banksQuery?.result,
    isBankLBFetchedAfterMount,
    common.message.partnerBanksEmpty,
    common.message.choosePartnerBankAndCompany,
    common.message.companyEmpty,
    common.message.chooseCompany,
    formValues.bankId,
  ]);

  const disableSubmit = useMemo(() => {
    return (
      (isEmpty(formValues.bankId) && user.isPresidingBank) ||
      (isEmpty(formValues.parentStatuses) && isEmpty(formValues.childStatuses))
    );
  }, [formValues]);

  const exportTypeOptions = useMemo(
    () => [
      {
        label: E02.label.excel,
        value: 'EXCEL',
      },
      {
        label: E02.label.csv,
        value: 'CSV',
      },
    ],
    [language],
  );

  const onSelectAll = () => {
    const parentStatuses = statusOption?.map((s) => s.value).join(',');
    const statusInGroup = statusOption
      ?.map((s) => s.statusInGroup)
      .filter((st) => st);
    const childStatuses = statusInGroup
      ?.map((s) => s?.map((gr) => gr.value).join(','))
      .join(',');
    setValue('parentStatuses', parentStatuses ?? '');
    setValue('childStatuses', childStatuses ?? '');
  };

  const deSelectAll = () => {
    setValue('parentStatuses', '');
    setValue('childStatuses', '');
  };

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={
          // <h1 className="tw-w-[100vw] tw-break-words tw-font-bold">
          E02.pageTitle
          // </h1>
        }
      >
        <div className="tw-grid tw-grid-rows-[auto_1fr] tw-gap-24">
          <form className="tw-mx-auto tw-grid tw-gap-24 tw-self-start tw-pt-24 pc:tw-min-w-584">
            <FormProvider {...methods}>
              <HgbResponsive
                gap
                className="tw-grid pc:tw-grid-cols-[repeat(auto-fill,minmax(220px,auto))]"
              >
                {(user.isPresidingBank || user.isManagementBank) && (
                  <>
                    {user.isPresidingBank && (
                      <HgbSelect
                        {...register('bankId')}
                        options={bankIdOptions}
                        label={common.placeholder.bankId}
                        disabled={isEmpty(bankIdOptions)}
                        showSearch
                      />
                    )}
                  </>
                )}
              </HgbResponsive>

              <div className="tw-space-y-8">
                <h4 className="tw-font-bold">{E02.label.format}</h4>
                <HgbAntdRadioGroup2
                  options={exportTypeOptions}
                  name={'exportType'}
                  optionsClassName='tw-w-180'
                />
              </div>

              <div className="tw-space-y-8">
                <h4 className="tw-font-bold">{E02.label.status}</h4>
                <div className="tw-flex tw-flex-col tw-gap-8 pc:tw-flex-row">
                  <HgbAntdButton
                    type="primary"
                    htmlType="button"
                    ghost
                    onClick={onSelectAll}
                  >
                    {E02.label.selectAll}
                  </HgbAntdButton>
                  <HgbAntdButton
                    type="primary"
                    htmlType="button"
                    ghost
                    onClick={deSelectAll}
                  >
                    {E02.label.deselectAll}
                  </HgbAntdButton>
                </div>
              </div>

              <div className="tw-space-y-8">
                <div className="tw-flex tw-flex-col tw-gap-24">
                  {(statusOption ?? [])?.map((status, index) => {
                    return (
                      <div className="tw-flex tw-flex-col tw-gap-8" key={index}>
                        <Checkbox
                          checked={
                            (
                              formValues.parentStatuses.split(',') ?? []
                            ).findIndex((s) => s === status.value) >= 0
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            let parentStatuses = isEmpty(
                              formValues.parentStatuses,
                            )
                              ? []
                              : formValues.parentStatuses.split(',');
                            let childStatuses = isEmpty(
                              formValues.childStatuses,
                            )
                              ? []
                              : formValues.childStatuses.split(',');
                            if (parentStatuses.includes(value)) {
                              parentStatuses = parentStatuses.filter(
                                (s) => s !== value,
                              );
                              childStatuses = childStatuses.filter(
                                (st) =>
                                  !status.statusInGroup
                                    ?.map((st) => st.value)
                                    .includes(st),
                              );
                            } else {
                              parentStatuses.push(value);
                              childStatuses = childStatuses.concat(
                                status.statusInGroup?.map((st) => st.value) ??
                                  [],
                              );
                            }
                            setValue(
                              'parentStatuses',
                              parentStatuses.join(','),
                            );
                            setValue('childStatuses', childStatuses.join(','));
                          }}
                          value={status.value}
                        >
                          {status.name}
                        </Checkbox>
                        <div className="tw-flex tw-flex-col tw-gap-8 tw-pl-24">
                          {status.statusInGroup &&
                            status.statusInGroup.map((group, index) => {
                              return (
                                <Checkbox
                                  checked={formValues.childStatuses.includes(
                                    group.value,
                                  )}
                                  value={group.value}
                                  key={index}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    let parentStatuses = isEmpty(
                                      formValues.parentStatuses,
                                    )
                                      ? []
                                      : formValues.parentStatuses.split(',');
                                    let childStatuses = isEmpty(
                                      formValues.childStatuses,
                                    )
                                      ? []
                                      : formValues.childStatuses.split(',');

                                    if (
                                      formValues.childStatuses.includes(value)
                                    ) {
                                      childStatuses = childStatuses.filter(
                                        (st) => st !== value,
                                      );
                                    } else {
                                      childStatuses.push(value);
                                      if (
                                        !parentStatuses.includes(status.value)
                                      ) {
                                        parentStatuses.push(status.value);
                                      }
                                    }

                                    let checked = false;

                                    childStatuses.forEach((s) => {
                                      if (
                                        includes(
                                          (status.statusInGroup ?? []).map(
                                            (s) => s.value,
                                          ),
                                          s,
                                        )
                                      ) {
                                        checked = true;
                                      }
                                    });

                                    if (!checked) {
                                      parentStatuses = parentStatuses.filter(
                                        (pst) => pst !== status.value,
                                      );
                                    }

                                    setValue(
                                      'childStatuses',
                                      childStatuses.join(','),
                                    );
                                    setValue(
                                      'parentStatuses',
                                      parentStatuses.join(','),
                                    );
                                  }}
                                >
                                  {group.name}
                                </Checkbox>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <HgbAntdButton
                className="tw-mx-auto"
                type="primary"
                htmlType="button"
                disabled={disableSubmit}
                onClick={() => {
                  setLoading(true);
                  setIsOpen(true);
                }}
                loading={loading}
              >
                {common.button.export}
              </HgbAntdButton>
            </FormProvider>
          </form>
          {emptyDescription && <HgbAntdEmpty description={emptyDescription} />}
        </div>
      </DataTableTemplate>
      <HgbAntdModal
        onCancel={() => {
          setIsOpen(false);
          setLoading(false);
        }}
        onOk={() => downloadReport()}
        cancelText={common.button.cancel}
        okText={common.button.export}
        title={E02.modal.confirmTitle}
        open={isOpen}
      >
        {E02.label.confirmContent}
      </HgbAntdModal>
    </ManagementTemplate>
  );
};
