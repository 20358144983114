export const objectToPairsNameValue = (
  originObject: object,
): { name: string; value: number }[] => {
  return Object.entries(originObject).map(([name, value]: [string, any]) => ({
    name,
    value: Number(value),
  }));
};

export const sumOfObject = (obj: Record<string, number>) => {
  return Object.values(obj).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
};
