import { HgbTreeSelectOption } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { SPLIT_FORMULA_FIELDS_REGEX } from '@constants/regex';
import { TreeSelectProps } from 'antd';

export const getParentKey = (path = '') => {
  if (!path) return;
  const arrPaths = path.split('-');
  return arrPaths.length > 1 && arrPaths[arrPaths.length - 2];
};

type Tree = {
  name: string;
  value: string;
  children?: TreeSelectProps[];
};

type RootTree = {
  hierarchicalPath: string;
  name: string;
  value: string;
  children?: any;
};

export const convertObjectToTree = (arr: Tree[], rootArr: RootTree[]) => {
  return arr.map((item) => {
    const children = rootArr.filter(
      (root) => getParentKey(root.hierarchicalPath) === item.value,
    );
    if (children.length > 0) {
      convertObjectToTree(children, rootArr);
      item.children = (children ?? []).map((child) => ({
        title: child?.name,
        value: child?.value?.toString(),
        children: child?.children,
      }));
    }
    return {
      title: item?.name,
      value: item?.value?.toString(),
      children: item?.children,
    };
  }) as HgbTreeSelectOption[];
};

export type TableTreeItem = {
  id: number;
  hierarchicalPath: string;
  children?: TableTreeItem[];
};
const getParentHierarchicalPath = (hierarchicalPath: string) => {
  const splitArr = hierarchicalPath.split('-');
  splitArr.pop();
  return splitArr.join('-');
};

export function convertObjectToTableTree<T extends TableTreeItem>(
  arr: Array<any>,
  rootArr: Array<any>,
): T[] {
  return arr.map((item) => {
    const children = rootArr.filter(
      (root) =>
        getParentHierarchicalPath(root.hierarchicalPath) ===
        item.hierarchicalPath,
    );
    if (children.length > 0) {
      convertObjectToTableTree(children, rootArr);
      item.children = children.map((child) => ({
        ...child,
      }));
    }
    return {
      ...item,
    };
  });
}

export function convertObjectToTableTreeWithoutRootArray<
  T extends TableTreeItem,
>(arr: Array<TableTreeItem>): T[] {
  const rootArray = arr.filter((item) =>
    arr
      .filter(({ id }) => item.id !== id)
      .every(
        ({ hierarchicalPath }) =>
          !item.hierarchicalPath.startsWith(hierarchicalPath + '-') &&
          item.hierarchicalPath !== hierarchicalPath,
      ),
  );

  return convertObjectToTableTree<T>(rootArray, arr);
}

export const getFieldsFromFormula = (formula: string) => {
  const formulaArr = formula.split(SPLIT_FORMULA_FIELDS_REGEX).filter((s) => s);
  return formulaArr.filter((item, index) => formulaArr.indexOf(item) === index);
};

export const convertStringToObjectFieldName = (
  formula: string,
  objectName: string,
  keys: string[],
) => {
  let newFormula = formula;
  const uniqFormularArr = getFieldsFromFormula(formula);
  uniqFormularArr.forEach((item) => {
    const pattern = '/(?:^|W)0(?:$|W)/g';
    const regex: RegExp = new RegExp(pattern.replace('0', item));
    if (formula.match(regex) && keys.includes(item)) {
      newFormula = newFormula.replaceAll(item, `${objectName}.${item}`);
    }
  });
  return newFormula;
};

export const getItemFromOptions = <T>(
  value: any,
  options: T[] = [],
  field: keyof T,
) => {
  const result = options.find((item: T) => item[field] === value);
  if (result) {
    return result as T;
  }
  return null;
};
