import { getEscottToken, REACT_APP_E_SCOTT_URL } from "@constants/consts";
import React from "react";
declare var SpsvApi: any;
type SpsvCreditResponse = { token: string, cardNo: string, spsvOrigin: string }

export const useSpsv = (open: boolean, onUpdate: (token: string, cardNo: string) => void, onClose: () => void) => {
    React.useEffect(() => {
        if (open) {
            SpsvApi?.spsvCardInfoScreen();
        } else {
            const sonyFrame = document.getElementById('SPSVviewIframe');
            const htmlEl = document.querySelector('html');
            if (htmlEl?.style) {
                htmlEl.style.overflow = 'unset';
            }
            sonyFrame?.remove();
        }
        (async () => {
            const script: HTMLScriptElement = document.createElement('script');
            const src = new URL(REACT_APP_E_SCOTT_URL);
            const token = await getEscottToken();
            src.searchParams.set('k_TokenNinsyoCode', token);
            script.src = src.href;
            script.className = 'spsvToken';
            script.type = 'text/javascript';
            script.setAttribute('callBackFunc', 'setToken');
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        })();

        const spsvMessageEventName = 'message'
        const spsvMessageEventHandler = (e: MessageEvent) => {
            if (typeof e.data === 'string' && !!e.data) {
                // Filter data từ spsvCreditApi
                const dataJson = JSON.parse(e.data);
                const response: SpsvCreditResponse = {
                    token: dataJson?.token ?? '',
                    cardNo: dataJson?.cardNo ?? '',
                    spsvOrigin: dataJson?.spsvOrigin ?? ''
                }

                if (dataJson.token === 'close') {
                    onClose();
                    return;
                }
                if (
                    response.spsvOrigin.includes('e-scott.jp') && response.cardNo !== ''
                ) {
                    const { cardNo, token } = response
                    onUpdate(cardNo, token)
                }
            }
        };

        window.addEventListener(spsvMessageEventName, spsvMessageEventHandler);
        return () => {
            window.removeEventListener(spsvMessageEventName, spsvMessageEventHandler);
        };
    }, [open])
}