import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { LanguageContext } from '@contexts/LanguageContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WithKey } from 'types/common';
import { GreenEnergyOptions } from '../C07/C07';
import { JSON_TO_KEY } from '@utils/text';
import { hgbAxiosGetSingle } from '@utils/axios';
import { API_URLS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { PortalContext } from '@contexts/PortalContext';

export type CertificateDetail = {
  id: number;
  greenEnergyDeductions: GreenEnergyOptions;
  greenEnergyCertificateKind: GreenEnergyOptions;
  greenEnergyUseVolume: number;
  remainingAmount: number;
  issueDate: string;
  startDateEndDate: string;
  certificateNumber: string;
  activities: Activity[];
  unit: string;
  cerName: string;
};

interface Activity {
  id: number;
  scopeCode: GreenEnergyOptions;
  targetYearMonth: string;
  baseId: GreenEnergyOptions;
  calculationTargetCode: GreenEnergyOptions;
  calculationMethodCode: string[];
  activityVolume: number;
  greenEnergyCertificateVolume: number;
}

export type CertificateModalMode = 'ADD' | 'EDIT';

const C09 = () => {
  const {
    text: { C07, common },
    language,
  } = useContext(LanguageContext)!;
  const { addMessage } = useContext(PortalContext)!;

  const [certificateDetail, setCertificateDetail] =
    useState<CertificateDetail>();

  const location = useLocation();
  const navigate = useNavigate();

  const handleCertificateActivities = async () => {
    const data = await hgbAxiosGetSingle<CertificateDetail>(
      API_URLS.CERTIFICATE_ACTIVITIES,
      location.state,
    );

    if (data?.response) {
      if (data?.response?.data?.statusCode === STATUS_CODE.notFound) {
        addMessage('error', data?.response?.data?.message);
        return;
      }
    }

    if (data?.id) {
      setCertificateDetail(data);
    }
  };

  useEffect(() => {
    handleCertificateActivities();
  }, [language]);

  const columns: ColumnsType<Activity> = [
    {
      title: C07.column.scope,
      dataIndex: 'scopeCode',
      width: 100,
      className: 'tw-max-w-200',
      align: 'center',
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: C07.column.yearMonth,
      dataIndex: 'targetYearMonth',
      width: 200,
      className: 'tw-max-w-200',
      align: 'center',
    },
    {
      title: C07.column.baseOrganization,
      dataIndex: 'baseId',
      width: 200,
      className: 'tw-max-w-200',
      align: 'center',
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: C07.column.calculationTarget,
      dataIndex: 'calculationTargetCode',
      align: 'center',
      width: 210,
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: C07.column.type,
      dataIndex: 'calculationMethodCode',
      width: 210,
      render: (value: string[]) => {
        return (
          <div className="tw-flex tw-flex-col">
            {(value ?? []).map((item) => (
              <p key={JSON_TO_KEY(item) + 'table-cell_'}>{item}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: C07.column.activityVolume,
      dataIndex: 'activityVolume',
      width: 210,
      render(value) {
        return (
          <div>
            {value} {certificateDetail?.unit}
          </div>
        );
      },
    },
    {
      title: C07.column.deductedAmount,
      dataIndex: 'greenEnergyCertificateVolume',
      width: 200,
      render(value) {
        return (
          <div>
            {value} {certificateDetail?.unit}
          </div>
        );
      },
    },
  ];

  const rows: WithKey<Activity>[] = useMemo(() => {
    return (certificateDetail?.activities ?? []).map((item: Activity) => ({
      ...item,
      key: item.id,
    }));
  }, [certificateDetail?.activities]);

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={C07.titleDetail}
        inputsComponent={
          <div className="tw-flex tw-flex-wrap tw-gap-x-40 tw-gap-y-24 tw-border tw-border-[#D9D9D9] tw-p-16 tw-text-[18px]">
            <div className="tw-flex tw-flex-col tw-gap-24">
              <div>
                {C07.deductionTarget}:{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.greenEnergyDeductions?.name}
                </span>
              </div>
              <div>
                {C07.certificateType}:{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.greenEnergyCertificateKind?.name}
                </span>
              </div>
              <div>
                {certificateDetail?.greenEnergyDeductions?.value === 'M00005'
                  ? C07.generatedPowerAmount
                  : C07.generatedHeatAmount}
                :{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.greenEnergyUseVolume}{' '}
                  {certificateDetail?.unit}
                </span>
              </div>
              <div>
                {C07.column.remainingAmount}:{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.remainingAmount} {certificateDetail?.unit}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-24">
              <div>
                {C07.issueDate}:{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.issueDate}
                </span>
              </div>
              <div>
                {C07.certificateValidityPeriod}:{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.startDateEndDate}
                </span>
              </div>
              <div>
                {certificateDetail?.greenEnergyCertificateKind?.value ===
                'EC001'
                  ? C07.certificateNo
                  : C07.serialNo}
                :{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.certificateNumber}
                </span>
              </div>

              <div>
                {C07?.certificateName}:{' '}
                <span className="tw-font-bold">
                  {certificateDetail?.cerName}
                </span>
              </div>
            </div>
          </div>
        }
      >
        {rows.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            className="tw-overflow-hidden"
            scroll={{ x: 'max-content' }}
          />
        ) : null}

        {rows.length === 0 ? (
          <HgbAntdEmpty description={common.message.noResult} />
        ) : null}

        <div className="tw-mt-24 tw-flex tw-items-end tw-justify-center">
          <HgbAntdButton type="primary" ghost onClick={() => navigate(-1)}>
            {C07.buttonBack}
          </HgbAntdButton>
        </div>
      </DataTableTemplate>
    </ManagementTemplate>
  );
};

export default C09;
