import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { hgbAxios } from '@utils/axios';
import { useContext, useEffect, useState } from 'react';

export type AxiosResponseType<T> = {
  response: any;
  statusCode: number;
  message: string;
  result: T;
};

export type MessageType = {
  type: 'error' | 'success';
  text: string;
  code: number;
};

type StateType<T = any> = {
  isLoading: boolean;
  isError: boolean;
  message: MessageType | null;
  data: T | null;
};

const getMessageType = (code: number): 'error' | 'success' => {
  const codeStr = code.toString().split('')[2];
  switch (codeStr) {
    case '0':
      return 'success';
    default:
      return 'error';
  }
};

const DEFAULT_STATE: StateType = {
  isLoading: false,
  isError: false,
  message: null,
  data: null,
};

// post method
export const usePost = <ResponseType, RequestType>(
  onSuccess?: (data: ResponseType, message: MessageType) => void,
  onFail?: (message: MessageType) => void,
  options?: {
    keepPreviousData?: boolean;
    isAlert?: boolean;
  },
) => {
  const { addMessage } = useContext(PortalContext)!;
  const { language } = useContext(LanguageContext)!;
  const [state, setState] = useState<StateType<ResponseType>>(DEFAULT_STATE);
  const execute = async (url: string, request?: RequestType) => {
    if (options?.keepPreviousData) {
      setState((prev) => ({ ...prev, isLoading: true, isError: false }));
    } else {
      setState({ ...DEFAULT_STATE, isLoading: true });
    }

    try {
      const response = await hgbAxios().post<AxiosResponseType<ResponseType>>(
        `${url}?lang=${language}`,
        request,
      );

      const { result, message, statusCode } = response.data;
      let messageType = getMessageType(statusCode);
      const messageResponse = {
        text: message,
        type: messageType,
        code: statusCode,
      };

      setState((prev) => ({
        ...prev,
        isLoading: false,
        isError: messageType === 'error',
        data: result,
        message: messageResponse,
      }));

      messageType === 'error'
        ? onFail?.(messageResponse)
        : onSuccess?.(result, messageResponse);
      if (
        statusCode === STATUS_CODE.shouldChangePassword ||
        statusCode === STATUS_CODE.mustChangePassword
      ) {
        messageType = 'error';
      }
      const alert = options?.isAlert ?? true;
      alert && addMessage(messageType, message);
    } catch (error) {
      const e = error as AxiosResponseType<ResponseType>;
      const messageResponse: MessageType = {
        text: e.response.data.message,
        type: 'error',
        code: e.response.data.statusCode,
      };
      onFail?.(messageResponse);
      // const alert = options?.isAlert ?? true;
      // alert && addMessage('error', e.message);
      return;
    }
  };
  return [state, execute] as const;
};

// get method
export const useGet = <ResponseType, RequestType>(
  onSuccess?: (data: ResponseType, message: MessageType) => void,
  onFail?: (message: MessageType) => void,
  options?: {
    keepPreviousData?: boolean;
    url?: string;
    params?: RequestType;
  },
) => {
  const { language } = useContext(LanguageContext)!;
  const [state, setState] = useState<StateType<ResponseType>>(DEFAULT_STATE);

  useEffect(() => {
    if (options?.url) {
      execute(options.url, options.params);
    }
  }, [options?.url, options?.params]);

  const execute = async (url: string, request?: RequestType) => {
    if (options?.keepPreviousData) {
      setState({ ...state, isLoading: true });
    } else {
      setState({ ...DEFAULT_STATE, isLoading: true });
    }
    const query = Object.entries(request ?? {})
      .filter(([_, value]) => (value?.toString().trim() ?? '') !== '')
      .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
      .join('&');
    try {
      const response = await hgbAxios().get<AxiosResponseType<ResponseType>>(
        `${url}?lang=${language}&${query}`,
      );

      const { result, message, statusCode } = response.data;
      const messageType = getMessageType(statusCode);

      const messageResponse = {
        text: message,
        type: messageType,
        code: statusCode,
      };

      setState((prev) => ({
        ...prev,
        data: messageType === 'error' ? null : result,
        isError: messageType === 'error',
        isLoading: false,
        message: messageResponse,
      }));

      messageType === 'error'
        ? onFail?.(messageResponse)
        : onSuccess?.(result, messageResponse);
    } catch (error) {
      const e = error as AxiosResponseType<ResponseType>;
      const messageResponse: MessageType = {
        text: e.message,
        type: 'error',
        code: e.statusCode,
      };
      onFail?.(messageResponse);
      setState((prev) => ({ ...prev, data: null, isLoading: false }));
    }
  };
  return [state, execute] as const;
};

// put method
export const usePut = <ResponseType, RequestType>(
  onSuccess?: (data: ResponseType, message: MessageType) => void,
  onFail?: (message: MessageType) => void,
) => {
  const { language } = useContext(LanguageContext)!;
  const [state, setState] = useState<StateType<ResponseType>>(DEFAULT_STATE);
  const { addMessage } = useContext(PortalContext)!;

  const execute = async (
    url: string,
    request: RequestType & { id: number | string },
  ) => {
    setState({ ...DEFAULT_STATE, isLoading: true });
    try {
      const { id, ...rest } = request;
      const response = await hgbAxios().put<AxiosResponseType<ResponseType>>(
        `${url}/${id}?lang=${language}`,
        rest,
      );

      const { result, message, statusCode } = response.data;
      const messageType = getMessageType(statusCode);

      const messageResponse = {
        text: message,
        type: messageType,
        code: statusCode,
      };

      setState((prev) => ({
        ...prev,
        data: result,
        isError: messageType === 'error',
        isLoading: false,
        message: messageResponse,
      }));
      addMessage(messageType, message);

      messageType === 'error'
        ? onFail?.(messageResponse)
        : onSuccess?.(result, messageResponse);
    } catch (error) {
      const e = error as AxiosResponseType<ResponseType>;
      const messageResponse: MessageType = {
        text: e.message,
        type: 'error',
        code: e.statusCode,
      };
      addMessage('error', e.message);
      onFail?.(messageResponse);
    }
  };
  return [state, execute] as const;
};

// delete method
export const useDelete = <ResponseType, RequestType>(
  onSuccess?: (data: ResponseType, message: MessageType) => void,
  onFail?: (message: MessageType) => void,
) => {
  const { language } = useContext(LanguageContext)!;
  const [state, setState] = useState<StateType<ResponseType>>(DEFAULT_STATE);
  const [stateExtra, setStateExtra] =
    useState<StateType<ResponseType>>(DEFAULT_STATE);
  const { addMessage } = useContext(PortalContext)!;

  const execute = async (url: string, request: RequestType) => {
    setState({ ...DEFAULT_STATE, isLoading: true });
    setStateExtra({ ...DEFAULT_STATE, isLoading: true });
    try {
      const response = await hgbAxios().delete<AxiosResponseType<ResponseType>>(
        url,
      );

      const { result, message, statusCode } = response.data;
      const messageType = getMessageType(statusCode);

      const messageResponse = {
        text: message,
        type: messageType,
        code: statusCode,
      };

      setState((prev) => ({
        ...prev,
        data: result,
        isError: messageType === 'error',
        isLoading: false,
        message: messageResponse,
      }));
      setStateExtra((prev) => ({
        ...prev,
        data: result,
        isError: messageType === 'error',
        isLoading: false,
        message: messageResponse,
      }));
      addMessage(messageType, message);
      messageType === 'error'
        ? onFail?.(messageResponse)
        : onSuccess?.(result, messageResponse);
    } catch (error: any) {
      const e = error as AxiosResponseType<ResponseType>;
      const messageResponse: MessageType = {
        text: e.message,
        type: 'error',
        code: e.statusCode,
      };
      addMessage('error', error.response.data.message);
      onFail?.(messageResponse);
    }
  };
  return [state, execute, stateExtra] as const;
};
