import { createLinkButtonClass } from '@common/atoms/button';
import { SquareButton } from '@common/forms';
import {
  HgbCloseOutlinedIcon,
  HgbExpandMoreOutlinedIcon,
  HgbModeOutlinedIcon,
} from '@common/icons/outlined';
import { API_URLS } from '@constants/API_URLS';
import { cn } from '@utils/cn';
import { ReactNode, useMemo } from 'react';
import { useHgbQuery } from 'services/common/query';
import {
  GetExternalLinkRequest,
  GetExternalLinkResponse,
} from 'services/types/externalLink';

export const useExternalLink = () => {
  const externalLinkQuery = useHgbQuery<
    GetExternalLinkResponse,
    GetExternalLinkRequest
  >(API_URLS.EXTERNAL_LINK_API_URL, undefined, {
    queryKey: [API_URLS.EXTERNAL_LINK_API_URL],
  });

  const normalLinks = useMemo(() => {
    return (
      externalLinkQuery.data?.result?.filter((item) => !item.isManual) || []
    );
  }, [externalLinkQuery.data?.result]);

  const manualLinks = useMemo(() => {
    return (
      externalLinkQuery.data?.result?.filter((item) => item.isManual) || []
    );
  }, [externalLinkQuery.data?.result]);

  return { normalLinks, manualLinks };
};

interface HgbExternalLinkProps {
  url?: string;
  linkName?: string;
  onEdit?: () => void;
  onDelete?: () => void;
}
export const HgbExternalLink = ({
  url,
  linkName,
  onEdit,
  onDelete,
}: HgbExternalLinkProps) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      <a
        href={url}
        target="_blank"
        className={cn(
          'tw-flex-auto tw-text-paragraph tw-underline hover:tw-underline',
          createLinkButtonClass(),
        )}
      >
        {linkName}
      </a>
      {onEdit && (
        <SquareButton onClick={onEdit} className={createLinkButtonClass()}>
          <HgbModeOutlinedIcon
            size="16"
            className="tw-shrink-0 tw-text-inherit"
          />
        </SquareButton>
      )}
      {onDelete && (
        <SquareButton onClick={onDelete} className={createLinkButtonClass()}>
          <HgbCloseOutlinedIcon 
            size="16"
            className="tw-shrink-0 tw-text-inherit"
          />
        </SquareButton>
      )}
    </div>
  );
};

export const SubMenuTitle = ({
  children,
  showIcon,
}: {
  children: ReactNode;
  showIcon?: boolean;
}) => {
  const handleClick = ()=>{
    const event = new Event('removeAll');
    window.dispatchEvent(event);
  }
  return (
    <div className="tw-inline-flex tw-items-center tw-gap-8" onClick={handleClick}>
      <span>{children}</span>
      {showIcon ? (
        <HgbExpandMoreOutlinedIcon
          size="16"
          className="icon-menu-custom tw-block tw-duration-300"
        />
      ) : null}
    </div>
  );
};
