import { PlusOutlined } from '@ant-design/icons';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbResponsive } from '@common/components';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import {
  HgbAntdInput,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { useDebounce } from '@hooks/useDebound';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { MANUAL_NAMEDDESTS } from '@pages/Management/Support';
import {
  TableTreeItem,
  convertObjectToTableTreeWithoutRootArray,
} from '@utils/object.utils';
import { Radio, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _, { isEmpty } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQuery } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  GetBaseClassificationsRequest,
  GetBaseClassificationsResponse,
  GetBaseListBoxRequest,
  GetBaseListBoxResponse,
  GetBaseOrganizationRequest,
  GetBaseOrganizationResponse,
  UpdateBaseRequest,
} from 'services/types/base';
import { getBaseOrganizationRequestDefault } from 'services/types/base_default_value';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';
import { v4 } from 'uuid';
import {
  OrganizationAddModal,
  OrganizationAddModalProps,
} from './OrganizationAddModal';
import {
  OrganizationDeleteModal,
  OrganizationDeleteModalProps,
} from './OrganizationDeleteModal';
import {
  OrganizationUpdateModal,
  OrganizationUpdateModalProps,
} from './OrganizationUpdateModal';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const getClassificationByValue = (
  value: number | string,
  classifications: HgbSelectOption[],
): HgbSelectOption | undefined =>
  classifications.find((item) => item.value === (value ?? '').toString());

interface DataType extends TableTreeItem {
  originalItem: UpdateBaseRequest;
  key: React.Key;
  baseName: string;
  classification: string;
  classificationName: string;
  action: JSX.Element;
}

export const D02: React.FC = () => {
  const { user } = useContext(AuthContext)!;
  const {
    text: { D02, common },
    language,
  } = React.useContext(LanguageContext)!;

  const [organizationAddModalProps, setOrganizationAddModalProps] =
    useState<OrganizationAddModalProps>({
      open: false,
      classificationOptions: [],
    });

  const [organizationUpdateModalProps, setOrganizationUpdateModalProps] =
    useState<OrganizationUpdateModalProps>({
      open: false,
      classificationOptions: [],
      parentBaseIdOptions: [],
    });

  const [organizationDeleteModalProps, setOrganizationDeleteModalProps] =
    useState<OrganizationDeleteModalProps>({
      open: false,
      id: -1,
      name: '',
    });

  const searchMethods = useForm<GetBaseOrganizationRequest>({
    values: getBaseOrganizationRequestDefault,
  });
  const { register, watch, setValue } = searchMethods;
  const requestSearch = watch();
  const baseNameDebounce = useDebounce(requestSearch.baseName, 300);

  const {
    data: BOsQuery,
    isLoading: isBOsLoading,
    isFetched: isBOsFetched,
    refetch: BOsRefetch,
  } = useHgbQuery<GetBaseOrganizationResponse, GetBaseOrganizationRequest>(
    API_URLS.BASE_ORGANIZATION_API_URL,
    {
      ...requestSearch,
      enterpriseId: user.isEnterpriseAdmin
        ? undefined
        : requestSearch.enterpriseId,
    },
    {
      enabled: user.isEnterpriseAdmin || requestSearch.enterpriseId !== '',
      queryKey: [
        API_URLS.BASE_ORGANIZATION_API_URL,
        requestSearch.bankId,
        requestSearch.classification,
        requestSearch.enterpriseId,
        baseNameDebounce,
      ],
    },
  );

  const { data: banksQuery } = useHgbQuery<
    GetBankListBoxResponse,
    GetBankListBoxRequest
  >(API_URLS.BANK_LIST_BOX_API_URL, undefined, {
    enabled: user.isPresidingBank,
    queryKey: [API_URLS.BANK_LIST_BOX_API_URL, user.isPresidingBank],
  });

  const { data: enterprisesQuery } = useHgbQuery<
    GetEnterpriseListBoxResponse,
    GetEnterpriseListBoxRequest
  >(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    user.isManagementBank
      ? undefined
      : {
          bankId: requestSearch.bankId,
        },
    {
      enabled:
        user.isManagementBank ||
        (user.isPresidingBank && requestSearch.bankId !== ''),
      queryKey: [API_URLS.ENTERPRISE_LIST_BOX_API_URL, requestSearch.bankId],
    },
  );

  const { data: classificationQuery } = useHgbQuery<
    GetBaseClassificationsResponse,
    GetBaseClassificationsRequest
  >(API_URLS.BASE_CLASSIFICATION);

  const bankIdOptions = useMemo(
    () => parseToHgbSelectOptions(banksQuery?.result),
    [banksQuery?.result],
  );

  const { data: baseLBQuery, refetch: baseLBRefetch } = useHgbQuery<
    GetBaseListBoxResponse,
    GetBaseListBoxRequest
  >(
    API_URLS.BASE_LISTBOX_API_URL,
    user.isEnterpriseAdmin
      ? undefined
      : { enterpriseId: requestSearch?.enterpriseId },
    {
      queryKey: [API_URLS.BASE_LISTBOX_API_URL, requestSearch?.enterpriseId],
      enabled: user.isEnterpriseAdmin || requestSearch?.enterpriseId !== '',
    },
  );

  const parentBaseIdOptions = useMemo(() => {
    return parseToHgbSelectOptions(baseLBQuery?.result);
  }, [baseLBQuery?.result]);

  const classificationOptions = useMemo(
    () => parseToHgbSelectOptions(classificationQuery?.result),
    [classificationQuery?.result],
  );

  const rows = useMemo(() => {
    const d = BOsQuery?.result ?? [];
    let stampData = d.map((item: any) => {
      const classification = getClassificationByValue(
        item.classification,
        classificationOptions,
      );
      return {
        key: item.hierarchicalPath,
        id: item.id,
        hierarchicalPath: item.hierarchicalPath,
        baseName: item.baseName,
        classification: classification?.value,
        classificationName: classification?.label,
        originalItem: { ...item, classification: classification?.value },
      };
    });
    return convertObjectToTableTreeWithoutRootArray<DataType>(stampData);
  }, [BOsQuery?.result, classificationOptions]);

  const handleEdit = (item: DataType) => {
    setOrganizationUpdateModalProps({
      open: true,
      item: item.originalItem,
      classificationOptions,
      parentBaseIdOptions,
    });
  };

  const handleDelete = (item: DataType) => {
    const name =
      item.baseName +
        '_' +
        getClassificationByValue(item.classification, classificationOptions)
          ?.label ?? '';

    setOrganizationDeleteModalProps({
      open: true,
      id: item?.id ?? -1,
      name,
    });
  };

  const TableData = useCallback(() => {
    const baseColumns: ColumnsType<DataType> = [
      {
        title: <div className="tw-font-bold">{D02.label.baseId}</div>,
        dataIndex: 'id',
        width: 80,
        render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
      },
      {
        title: D02.label.baseName,
        width: 240,
        render: (item: DataType) => (
          <HgbCellLink
            onClick={() => {
              setOrganizationUpdateModalProps({
                open: true,
                item: item.originalItem,
                readOnly: true,
                classificationOptions,
                parentBaseIdOptions,
              });
            }}
          >
            <HgbCellTooltip>{item.baseName}</HgbCellTooltip>
          </HgbCellLink>
        ),
      },
      {
        title: D02.label.classification,
        dataIndex: 'classificationName',
        width: 180,
        render: (text) => <HgbCellTooltip>{text} </HgbCellTooltip>,
      },
    ];
    const actionCol: any = {
      title: common.label.action,
      align: 'center',
      width: 160,
      render: (item: DataType) => (
        <div className="tw-flex tw-justify-center tw-gap-24">
          <HgbActionButton
            onClick={() => handleEdit(item)}
            icon={<EditOutlined />}
          >
            {common.button.update}
          </HgbActionButton>
          <HgbActionButton
            onClick={() => handleDelete(item)}
            disabled={!_.isEmpty(item.children)}
            icon={<DeleteOutlined />}
          >
            {common.button.delete}
          </HgbActionButton>
        </div>
      ),
    };
    const columns = user.isEnterpriseAdmin
      ? [...baseColumns, actionCol]
      : baseColumns;

    if (user.isPresidingBank) {
      if (isEmpty(banksQuery?.result))
        return <HgbAntdEmpty description={common.message.partnerBanksEmpty} />;
      if (isEmpty(requestSearch.bankId))
        return (
          <HgbAntdEmpty
            description={common.message.choosePartnerBankAndCompany}
          />
        );
      if (isEmpty(enterprisesQuery?.result))
        return <HgbAntdEmpty description={common.message.companyEmpty} />;
      if (isEmpty(requestSearch.enterpriseId))
        return <HgbAntdEmpty description={common.message.chooseCompany} />;
    }

    if (user.isManagementBank) {
      if (isEmpty(enterprisesQuery?.result))
        return <HgbAntdEmpty description={common.message.companyEmpty} />;
      if (isEmpty(requestSearch.enterpriseId))
        return <HgbAntdEmpty description={common.message.chooseCompany} />;
    }

    if (isEmpty(rows) && isBOsFetched)
      return <HgbAntdEmpty description={common.message.noResult} />;

    return (
      <Table
        columns={columns}
        dataSource={rows}
        pagination={false}
        scroll={{
          x: columns.reduce((prev, cur) => prev + Number(cur.width), 0),
        }}
      />
    );
  }, [
    rows,
    user,
    language,
    classificationOptions,
    bankIdOptions,
    enterprisesQuery?.result,
    isBOsFetched,
    parentBaseIdOptions,
  ]);

  const enterpriseIdOptions = parseToHgbSelectOptions(enterprisesQuery?.result);

  const disableSearch =
    (user.isPresidingBank &&
      (!requestSearch.bankId || !requestSearch.enterpriseId)) ||
    (user.isManagementBank && !requestSearch.enterpriseId);

  return (
    <>
      <ManagementTemplate>
        <FormProvider {...searchMethods}>
          <DataTableTemplate
            title={D02.pageTitle}
            inputsComponent={
              <HgbResponsive
                gap
                className="tw-flex tw-flex-wrap tw-items-end"
              >
                {user.isPresidingBank && (
                  <HgbSelect
                    {...register('bankId')}
                    options={bankIdOptions}
                    placeholder={D02.placeholder.bankId}
                    label={common.placeholder.bankId}
                    showSearch
                  />
                )}
                {(user.isPresidingBank || user.isManagementBank) && (
                  <HgbSelect
                    {...register('enterpriseId')}
                    disabled={isEmpty(enterprisesQuery?.result)}
                    label={D02.label.enterpriseId}
                    options={enterpriseIdOptions}
                    placeholder={D02.placeholder.enterpriseId}
                    showSearch
                  />
                )}
                {!isEmpty(classificationOptions) && (
                  <div className="tw-flex tw-min-h-[40px] !tw-w-fit tw-items-center">
                    <Radio.Group
                      disabled={disableSearch}
                      onChange={(e) => {
                        setValue('classification', e.target.value);
                      }}
                      value={requestSearch.classification}
                      className="tw-flex tw-items-center tw-gap-16 [&_label]:!tw-mr-0"
                    >
                      <Radio value={''} key={v4()}>
                        <div className="-tw-mx-8 -tw-ml-4 tw-flex tw-gap-8 tw-break-keep">
                          {common.label.all}
                        </div>
                      </Radio>
                      {classificationOptions.map((item) => (
                        <Radio value={item.value.toString()} key={v4()}>
                          <div className="-tw-mx-8 -tw-ml-4 tw-flex tw-gap-8 tw-break-keep">
                            {item.label}
                            <HgbInfoLink
                              to={
                                '/support/manual/' +
                                (item.value === '1'
                                  ? MANUAL_NAMEDDESTS.about_input_base
                                  : MANUAL_NAMEDDESTS.about_input_organization)
                              }
                              target="_blank"
                            />
                          </div>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                )}

                <HgbAntdInput
                  {...register('baseName')}
                  disabled={disableSearch}
                  maxLength={50}
                  type={'K'}
                  search
                  placeholder={D02.placeholder.baseName}
                />
              </HgbResponsive>
            }
            buttonComponent={
              user.isEnterpriseAdmin && (
                <HgbAntdButton
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setOrganizationAddModalProps((prev) => ({
                      ...prev,
                      open: true,
                      classificationOptions,
                    }));
                  }}
                  className="tw-ml-auto tw-shrink-0"
                >
                  <>{common.button.register}</>
                </HgbAntdButton>
              )
            }
          >
            {isBOsLoading ? (
              <Spin className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
            ) : (
              <TableData />
            )}
          </DataTableTemplate>
        </FormProvider>
      </ManagementTemplate>

      {organizationUpdateModalProps.open ? (
        <OrganizationUpdateModal
          {...organizationUpdateModalProps}
          onSubmitOk={() => {
            setOrganizationUpdateModalProps((prev) => ({
              ...prev,
              open: false,
              item: undefined,
            }));
            BOsRefetch();
            baseLBRefetch();
          }}
          onCancel={() =>
            setOrganizationUpdateModalProps((prev) => ({
              ...prev,
              open: false,
              item: undefined,
            }))
          }
        />
      ) : null}

      <OrganizationDeleteModal
        {...organizationDeleteModalProps}
        onSubmitOk={() => {
          setOrganizationDeleteModalProps((prev) => ({
            ...prev,
            open: false,
          }));
          BOsRefetch();
          baseLBRefetch();
        }}
        onCancel={() =>
          setOrganizationDeleteModalProps((prev) => ({
            ...prev,
            open: false,
          }))
        }
      />

      {organizationAddModalProps.open ? (
        <OrganizationAddModal
          {...organizationAddModalProps}
          onSubmitOk={() => {
            setOrganizationAddModalProps((prev) => ({
              ...prev,
              open: false,
              item: undefined,
            }));
            BOsRefetch();
            baseLBRefetch();
          }}
          onCancel={() =>
            setOrganizationAddModalProps((prev) => ({
              ...prev,
              open: false,
              item: undefined,
            }))
          }
        />
      ) : null}
    </>
  );
};
