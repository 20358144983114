import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { Modal } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import * as yup from 'yup';
import { EnergyCertificate, GreenEnergyOptions } from './C07';
import { CertificateModalMode } from '../C09/C09';

interface CetificateModalProps {
  modalState: BooleanState;
  CertificateDetail?: EnergyCertificate;
  mode: CertificateModalMode;
}

interface DeductionTarget {
  name: string;
  type: string;
  data: GreenEnergyOptions[];
  fieldTarget: string;
}

interface CertificateType {
  name: string;
  fieldTarget: string;
  maxLength: null;
  unit: string;
  disabled: boolean;
  constraints: null;
  value: string;
}

export type EnergyCertificatePayload = {
  id?: string;
  calculationMethodCode: string;
  certificateKind: string;
  useVolume: string;
  issueDate: string;
  startDate: string;
  endDate: string;
  certificateNumber: string;
  unit: string;
  certificateName: string;
  rangeDate?: string;
};

const INIT_BLANK: EnergyCertificatePayload = {
  id: '',
  calculationMethodCode: '',
  certificateKind: '',
  useVolume: '',
  issueDate: '',
  startDate: '',
  endDate: '',
  certificateNumber: '',
  certificateName: '',
  unit: 'kWh',
};

export const CertificateModal: React.FunctionComponent<
  CetificateModalProps
> = ({ modalState, CertificateDetail, mode }) => {
  const queryClient = useQueryClient();
  const {
    text: { common, E0000, C07, E0117 },
  } = React.useContext(LanguageContext)!;

  const CertificateSchema = yup.object({
    calculationMethodCode: yup.string().required(E0000(C07.deductionTarget)),
    certificateKind: yup.string().required(E0000(C07.certificateType)),
    useVolume: yup.string().test('', '', (value, context) => {
      if (!value) {
        const errorMessage = getLabelCalculationMethodCode(
          context.parent?.calculationMethodCode,
        );
        return context.createError({
          path: 'useVolume',
          message: E0000(errorMessage),
        });
      }
      return true;
    }),

    issueDate: yup.string().required(E0000(C07.issueDate)),
    certificateNumber: yup.string().test('', '', (value, context) => {
      if (!value) {
        const errorMessage = getLabelCertificateNumber(
          context.parent?.certificateKind,
        );
        return context.createError({
          path: 'certificateNumber',
          message: E0000(errorMessage),
        });
      }
      return true;
    }),
    rangeDate: yup.string().test('', '', (_, context) => {
      const { startDate, endDate } = context.parent;

      if (startDate && endDate) {
        const start = moment(startDate, 'YYYY/MM/DD');
        const end = moment(endDate, 'YYYY/MM/DD');

        if (start.isAfter(end)) {
          return context.createError({ message: E0117, path: 'rangeDate' });
        }
      }

      return true;
    }),
    certificateName: yup.string().required(E0000(C07.certificateName)),
  });

  const certificateMethods = useForm<EnergyCertificatePayload>({
    defaultValues: INIT_BLANK,
    resolver: yupResolver(CertificateSchema),
  });

  const { data: deductionTargetQuery } = useHgbQuery<DeductionTarget>(
    API_URLS.DEDUCTION_TARGET,
  );

  const { data: certificateTypeQuery } = useHgbQuery<CertificateType[]>(
    API_URLS.CERTIFICATE_TYPE,
    {
      methodCode: certificateMethods.watch('calculationMethodCode'),
    },
    {
      enabled: certificateMethods.watch('calculationMethodCode')?.length > 0,
      queryKey: certificateMethods.watch('calculationMethodCode'),
    },
  );

  const addCertificateMutate = useHgbMutationPost<
    unknown,
    EnergyCertificatePayload
  >(API_URLS.ENERGY_CERTIFICATE, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ENERGY_CERTIFICATE],
      });
      modalState.turnOff();
    },
    onError: () => {},
  });

  const editCertificateMutate = useHgbMutationPut<
    unknown,
    EnergyCertificatePayload
  >(API_URLS.ENERGY_CERTIFICATE, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ENERGY_CERTIFICATE],
      });
      modalState.turnOff();
    },
    onError: () => {},
  });

  const deductionTargetOptions = React.useMemo(
    () => parseToHgbSelectOptions(deductionTargetQuery?.result?.data),
    [JSON.stringify(deductionTargetQuery?.result?.data)],
  );

  const certificateOptions = React.useMemo(
    () => parseToHgbSelectOptions(certificateTypeQuery?.result),
    [JSON.stringify(certificateTypeQuery?.result)],
  );

  const handleSubmit = certificateMethods.handleSubmit(
    (data, e) => {
      const { id, ...dataWithoutId } = data;

      if (mode === 'ADD') {
        addCertificateMutate.mutate(dataWithoutId);
      } else {
        editCertificateMutate.mutate(data);
      }
    },
    (error) => {
      debug.error(error);
    },
  );

  const handleSubmitDebounce = debounce(handleSubmit, 200);

  const onCancel = () => {
    modalState.turnOff();
    certificateMethods.reset();
  };

  const getLabelCalculationMethodCode = (calculationMethodCode: string) => {
    switch (calculationMethodCode) {
      case 'M00005':
        return C07.generatedPowerAmount;
      case 'M00006':
        return C07.generatedHeatAmount;
      default:
        return C07.generatedElectricityAmount;
    }
  };

  const getLabelCertificateNumber = (certificateKind: string) => {
    if (certificateKind === 'EC001' || !certificateKind) {
      return C07.certificateNo;
    }
    return C07.serialNo;
  };

  React.useEffect(() => {
    certificateMethods.setValue(
      'unit',
      certificateMethods.watch('calculationMethodCode') === 'M00005'
        ? 'kWh'
        : 'GJ',
    );
  }, [certificateMethods.watch('calculationMethodCode')]);

  React.useEffect(() => {
    certificateMethods.reset({
      id: CertificateDetail?.id?.toString() || '',
      certificateKind: CertificateDetail?.certificateKind?.value || '',
      useVolume: CertificateDetail?.useVolume?.toString() || '',
      calculationMethodCode:
        CertificateDetail?.calculationMethodCode?.value || '',
      issueDate: CertificateDetail?.issueDate || '',
      startDate: CertificateDetail?.startDate || '',
      endDate: CertificateDetail?.endDate || '',
      certificateNumber: CertificateDetail?.certificateNumber || '',
      certificateName: CertificateDetail?.certificateName || '',
      unit: CertificateDetail?.unit || 'kWh',
    });
  }, [CertificateDetail]);

  return (
    <>
      <Modal
        centered
        title={
          <HgbAntdModalTitle className="tw-px-24">
            {mode === 'ADD'
              ? C07.modal.titleModalRegister
              : C07.modal.titleModalUpdate}
          </HgbAntdModalTitle>
        }
        open={modalState.value}
        onCancel={onCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        destroyOnClose={true}
        className="ant-modal-hgb-custom"
      >
        <FormProvider {...certificateMethods}>
          <HgbAntdModalForm onSubmit={handleSubmitDebounce}>
            <HgbAntdModalFormBody>
              <HgbSelect
                label={C07.deductionTarget}
                {...certificateMethods.register('calculationMethodCode')}
                options={deductionTargetOptions}
                onChangeValue={(_, value) =>
                  certificateMethods.reset({
                    ...INIT_BLANK,
                    id: certificateMethods.getValues('id'),
                    calculationMethodCode: value,
                  })
                }
                required
                showSearch
              />

              <HgbSelect
                label={C07.certificateType}
                {...certificateMethods.register('certificateKind')}
                options={certificateOptions}
                onChangeValue={(_, value) =>
                  certificateMethods.reset({
                    ...INIT_BLANK,
                    certificateKind: value,
                    id: certificateMethods.getValues('id'),
                    calculationMethodCode: certificateMethods.getValues(
                      'calculationMethodCode',
                    ),
                    unit: certificateMethods.getValues('unit'),
                  })
                }
                required
              />

              <HgbAntdInput
                label={getLabelCalculationMethodCode(
                  certificateMethods.getValues('calculationMethodCode'),
                )}
                {...certificateMethods.register('useVolume')}
                suffix={certificateMethods.watch('unit')}
                maxLength={13}
                type="positiveDecimal"
                decimalLength={3}
                required
              />

              <HgbDatePicker
                {...certificateMethods.register('issueDate')}
                className="tw-col-span-2"
                required
                format="YYYY/MM/DD"
                picker="date"
                label={C07.issueDate}
                allowClear
              />

              <div className="tw-gap-16">
                <div className="tw-mb-4 tw-flex tw-select-none tw-gap-4 tw-text-paragraph tw-font-bold">
                  {C07.certificateValidityPeriod}
                </div>
                <div className="tw-flex tw-items-center tw-justify-between">
                  <HgbDatePicker
                    picker="date"
                    {...certificateMethods.register('startDate')}
                    placeholder="2023/04/01"
                    format="YYYY/MM/DD"
                    className="tw-cursor-pointer"
                    allowClear
                  />
                  <div>～</div>
                  <HgbDatePicker
                    picker="date"
                    {...certificateMethods.register('endDate')}
                    placeholder="2024/03/31"
                    format="YYYY/MM/DD"
                    className="tw-cursor-pointer"
                    allowClear
                  />
                </div>
              </div>
              {certificateMethods.formState.errors.rangeDate?.message ? (
                <HgbFieldError>
                  {certificateMethods.formState.errors.rangeDate.message}
                </HgbFieldError>
              ) : null}

              <HgbAntdInput
                label={getLabelCertificateNumber(
                  certificateMethods.getValues('certificateKind'),
                )}
                {...certificateMethods.register('certificateNumber')}
                maxLength={50}
                required
              />

              <HgbAntdInput
                label={C07.certificateName}
                maxLength={200}
                type={'area'}
                {...certificateMethods.register('certificateName')}
                required
              />
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton className="tw-min-w-100" onClick={onCancel}>
                {common.button.cancel}
              </HgbAntdButton>

              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
                loading={addCertificateMutate.isLoading}
              >
                {mode === 'ADD' ? common.button.add : common.button.change}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </FormProvider>
      </Modal>
    </>
  );
};
