import { HgbAntdModal, HgbAntdModalProps } from '@common/antd/HgbAntdModal';
import {
  HgbAntdInput,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHgbMutationPut } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  GetDepartmentsByRoleRequest,
  GetDepartmentsByRoleResponse,
} from 'services/types/department';
import { GetAllRoleRequest, GetAllRoleResponse } from 'services/types/role';
import { UpdateUserRequest } from 'services/types/user';
import * as yup from 'yup';

type UpdateUserFormData = {
  id: string;
  userId?: string;
  name?: string;
  nameKana?: string;
  email?: string;
  departmentId?: string;
  roleId?: string;
  roleName?: string;
  deletable?: boolean;
};

export const parseUpdateUserRequestToFormData = (
  v?: UpdateUserRequest,
): UpdateUserFormData | undefined => {
  if (!v) return undefined;
  return {
    ...v,
    id: v?.id?.toString(),
    userId: v?.userId?.toString(),
    roleId: v?.roleId?.toString(),
    departmentId: v?.departmentId?.toString(),
  };
};

export const parseFormDataToUpdateUserRequest = (
  v?: UpdateUserFormData,
): UpdateUserRequest | undefined => {
  if (!v) return undefined;
  return {
    ...v,
    id: Number(v.id),
    userId: Number(v?.userId),
    roleId: Number(v?.roleId),
    departmentId: Number(v?.departmentId),
  };
};

export interface UpdateUserModalProps extends HgbAntdModalProps {
  onSubmitOk?: () => void;
  item?: UpdateUserFormData;
  readOnly?: boolean;
}

export const UpdateUserModal: React.FunctionComponent<UpdateUserModalProps> = ({
  onSubmitOk,
  item,
  readOnly,
  ...props
}) => {
  const {
    text: { D04, common },
    language,
  } = React.useContext(LanguageContext)!;

  const { user } = React.useContext(AuthContext)!;

  const disableSelectRole = React.useMemo(() => {
    return user.accountId?.toString() === item?.id?.toString();
  }, [item, user]);

  const userManagementSchema = yup.object({
    email: yup
      .string()
      .required(D04.message.email)
      .matches(EMAIL_REGEX, common.message.emailFormat),
    name: yup.string().required(D04.message.name),
    nameKana: yup.string().required(D04.message.nameKana),
  });

  const methods = useForm<UpdateUserFormData>({
    resolver: yupResolver(userManagementSchema),
    values: item,
  });

  const { handleSubmit, setValue, watch, register } = methods;
  const updateFormData = watch();

  const { data: departmentsQuery } = useHgbQuery<
    GetDepartmentsByRoleResponse,
    GetDepartmentsByRoleRequest
  >(
    API_URLS.DEPARTMENT_API_URL,
    {
      selectedRoleId: updateFormData?.roleId!,
    },
    {
      enabled: (updateFormData?.roleId ?? '') !== '',
      queryKey: [API_URLS.DEPARTMENT_API_URL, updateFormData.roleId],
    },
  );

  const { mutate: updateMutate } = useHgbMutationPut(API_URLS.USER_API_URL, {
    onSuccess: onSubmitOk,
  });

  const { data: rolesQuery } = useHgbQuery<
    GetAllRoleResponse,
    GetAllRoleRequest
  >(API_URLS.ROLE_API_URL);

  const roleIdOptions = React.useMemo(() => {
    const rs = parseToHgbSelectOptions(rolesQuery?.result);
    setValue('roleId', item?.roleId);
    return rs;
  }, [rolesQuery?.result]);

  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  const departmentOptions = React.useMemo(() => {
    const departments = departmentsQuery?.result ?? [];
    const rs = parseToHgbSelectOptions(departments);
    const rsTree = convertObjectToTree(
      departments.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      departments as any,
    );
    if (updateFormData.roleId === '4') {
      if (isFirstLoad) {
        setValue('departmentId', item?.departmentId);
      } else {
        setValue('departmentId', rsTree?.[0]?.value);
      }
      return rsTree;
    }
    if (isFirstLoad) {
      setValue('departmentId', item?.departmentId);
    } else {
      setValue('departmentId', rs?.[0]?.value);
    }
    return rs;
  }, [
    departmentsQuery?.result,
    updateFormData.roleId,
    item?.departmentId,
    isFirstLoad,
  ]);

  const DepartmentSelect = React.useCallback(() => {
    const departments = departmentsQuery?.result ?? [];
    const rs = parseToHgbSelectOptions(departments);
    const rsTree = convertObjectToTree(
      departments.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      departments as any,
    );
    if (user.isManagementBank && updateFormData.roleId === '2') return null;
    if (user.isEnterpriseAdmin && updateFormData.roleId === '3') return null;
    if (updateFormData.roleId === '4') {
      return (
        <HgbTreeSelect
          {...register('departmentId')}
          required
          treeData={rsTree}
          label={D04.label.baseOrganization}
          placeholder={D04.placeholder.departmentId}
          disabled={readOnly || !user.isEnterpriseAdmin}
          showSearch
        />
      );
    }
    return (
      <HgbSelect
        required
        {...register('departmentId')}
        options={rs}
        label={`${user.isPresidingBank ? D04.label.partnerBank : ''}
          ${user.isManagementBank ? D04.label.enterprise : ''}`}
        placeholder={D04.placeholder.departmentId}
        autoInitValue
        disabled={readOnly || !user.isEnterpriseAdmin}
        showSearch
      />
    );
  }, [updateFormData.roleId, departmentsQuery?.result, user, language]);

  const changeRoleId = () => {
    setIsFirstLoad(false);
  };

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = (data) => {
    updateMutate(parseFormDataToUpdateUserRequest(data)!);
  };

  return (
    <FormProvider {...methods}>
      <HgbAntdModal
        {...props}
        cancelText={common.button.cancel}
        okText={readOnly ? '' : common.button.change}
        title={readOnly ? D04.modal.userViewTitle : D04.modal.userUpdateTitle}
        formProps={{
          onSubmit: handleSubmit(handleUpdateUser),
        }}
      >
        <div className="tw-grid tw-gap-24">
          <HgbAntdInput
            {...register('userId')}
            required
            disabled
            label={common.label.loginID}
            // placeholder={D04.placeholder.id}
          />
          <HgbAntdInput
            {...register('name')}
            maxLength={50}
            type="K"
            required
            label={D04.label.name}
            placeholder={D04.placeholder.name}
            disabled={readOnly}
          />
          <HgbAntdInput
            {...register('nameKana')}
            maxLength={50}
            type="K"
            required
            label={D04.label.nameKana}
            placeholder={D04.placeholder.nameKana}
            disabled={readOnly}
          />
          <HgbAntdInput
            {...register('email')}
            maxLength={100}
            type="hgbEmail"
            required
            label={D04.label.email}
            placeholder={D04.placeholder.email}
            disabled={readOnly}
          />
          <HgbSelect
            {...register('roleId')}
            required
            options={roleIdOptions}
            label={D04.label.roleId}
            placeholder={D04.placeholder.roleId}
            disabled={readOnly || !user.isEnterpriseAdmin || disableSelectRole}
            onChangeValue={changeRoleId}
          />
          {updateFormData?.roleId && departmentOptions.length > 0 && (
            <DepartmentSelect />
          )}
        </div>
      </HgbAntdModal>
    </FormProvider>
  );
};
