import { HgbAntdModal, HgbAntdModalProps } from '@common/antd/HgbAntdModal';
import {
  HgbAntdInput,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  GetDepartmentsByRoleRequest,
  GetDepartmentsByRoleResponse,
} from 'services/types/department';
import { GetAllRoleRequest, GetAllRoleResponse } from 'services/types/role';
import { CreateUserRequest, CreateUserResponse } from 'services/types/user';
import * as yup from 'yup';

const createUserRequestDefault: CreateUserRequest = {
  name: '',
  nameKana: '',
  email: '',
  departmentId: '',
  roleId: '',
};

export interface AddUserModalProps extends HgbAntdModalProps {
  onSubmitOk?: () => void;
}
export const AddUserModal: React.FunctionComponent<AddUserModalProps> = ({
  onSubmitOk,
  ...props
}) => {
  const {
    text: { D04, common },
    language,
  } = React.useContext(LanguageContext)!;

  const { user } = React.useContext(AuthContext)!;

  const userManagementSchema = yup.object({
    email: yup
      .string()
      .required(D04.message.email)
      .matches(EMAIL_REGEX, common.message.emailFormat),
    name: yup.string().required(D04.message.name),
    nameKana: yup.string().required(D04.message.nameKana),
    departmentId: yup.string().required(`
    ${user.isPresidingBank ? D04.message.partnerBank : ''}
    ${user.isManagementBank ? D04.message.enterprise : ''}
    ${user.isEnterpriseAdmin ? D04.message.baseOrganization : ''}`),
  });

  const methods = useForm<CreateUserRequest>({
    resolver: yupResolver(userManagementSchema),
    values: createUserRequestDefault,
  });
  const { handleSubmit, setValue, watch, register } = methods;
  const createUserValues = watch();

  const { data: departmentsQuery } = useHgbQuery<
    GetDepartmentsByRoleResponse,
    GetDepartmentsByRoleRequest
  >(
    API_URLS.DEPARTMENT_API_URL,
    {
      selectedRoleId: createUserValues.roleId!,
    },
    {
      enabled: (createUserValues.roleId ?? '') !== '',
      queryKey: [API_URLS.DEPARTMENT_API_URL, createUserValues.roleId],
    },
  );

  const { data: rolesQuery } = useHgbQuery<
    GetAllRoleResponse,
    GetAllRoleRequest
  >(API_URLS.ROLE_API_URL);

  const roleOptions = React.useMemo(() => {
    const rs = parseToHgbSelectOptions(
      (rolesQuery?.result ?? []).filter((item) => !item.isHidden),
    );
    setValue('roleId', rs?.[0]?.value);
    return rs;
  }, [rolesQuery?.result]);

  const departmentOptions = React.useMemo(() => {
    const rs = parseToHgbSelectOptions(departmentsQuery?.result);
    if (
      (user.isManagementBank && createUserValues.roleId === '2') ||
      (user.isEnterpriseAdmin && createUserValues.roleId === '3')
    ) {
      setValue('departmentId', rs?.[0]?.value?.toString());
    } else {
      setValue('departmentId', '');
    }
    return rs;
  }, [departmentsQuery?.result]);

  const departmentTreeOptions = React.useMemo(() => {
    const rs = convertObjectToTree(
      (departmentsQuery?.result ?? []).filter(
        (item) => !getParentKey(item.hierarchicalPath ?? ''),
      ) as any,
      (departmentsQuery?.result ?? []) as any,
    );
    if (
      (user.isManagementBank && createUserValues.roleId === '2') ||
      (user.isEnterpriseAdmin && createUserValues.roleId === '3')
    ) {
      setValue('departmentId', rs?.[0]?.value?.toString());
    } else {
      setValue('departmentId', '');
    }
    return rs;
  }, [departmentsQuery?.result]);

  const { mutate, isLoading } = useHgbMutationPost<
    CreateUserResponse,
    CreateUserRequest
  >(API_URLS.USER_API_URL, { onSuccess: onSubmitOk });

  const handleAddUser: SubmitHandler<CreateUserRequest> = (data) => {
    !isLoading && mutate(data);
  };

  const DepartmentSelect = React.useCallback(() => {
    if (user.isManagementBank && createUserValues.roleId === '2') return null;
    if (user.isEnterpriseAdmin && createUserValues.roleId === '3') return null;
    if (createUserValues.roleId === '4') {
      return (
        <HgbTreeSelect
          {...register('departmentId')}
          required
          treeData={departmentTreeOptions}
          label={D04.label.baseOrganization}
          placeholder={D04.placeholder.departmentId}
          showSearch
        />
      );
    }
    return (
      <HgbSelect
        required
        {...register('departmentId')}
        options={departmentOptions}
        label={`${user.isPresidingBank ? D04.label.partnerBank : ''}
          ${user.isManagementBank ? D04.label.enterprise : ''}`}
        placeholder={D04.placeholder.departmentId}
        showSearch
      />
    );
  }, [user, departmentOptions, language, createUserValues.roleId]);

  return (
    <FormProvider {...methods}>
      <HgbAntdModal
        {...props}
        cancelText={common.button.cancel}
        okText={common.button.add}
        title={D04.modal.userAddTitle}
        formProps={{
          onSubmit: handleSubmit(handleAddUser),
        }}
      >
        <div className="tw-grid tw-gap-24">
          <HgbAntdInput
            {...register('name')}
            maxLength={50}
            type="K"
            required
            label={D04.label.name}
            placeholder={D04.placeholder.name}
          />
          <HgbAntdInput
            {...register('nameKana')}
            maxLength={50}
            type="K"
            required
            label={D04.label.nameKana}
            placeholder={D04.placeholder.nameKana}
          />
          <HgbAntdInput
            {...register('email')}
            maxLength={100}
            type="hgbEmail"
            required
            label={D04.label.email}
            placeholder={D04.placeholder.email}
          />
          <HgbSelect
            {...register('roleId')}
            required
            options={roleOptions}
            label={D04.label.roleId}
            placeholder={D04.placeholder.roleId}
            autoInitValue
          />
          {createUserValues.roleId && <DepartmentSelect />}
        </div>
      </HgbAntdModal>
    </FormProvider>
  );
};
