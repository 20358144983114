import { SaveOutlined } from '@ant-design/icons';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbResponsive } from '@common/components';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseScopeCategoryToHgbSelectOptions,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { MONTH_OF_YEAR_OPTIONS } from '@constants/options';
import { DATE_REGEX, EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '@hooks/useAddress';
import { useBooleanState } from '@hooks/useBooleanState';
import { DataTableTemplate } from '@layouts/templates';
import { ManagementTemplate } from '@layouts/templates/ManagementTemplate';
import { cn } from '@utils/cn';
import {
  createMajorOptions,
  createMediumOptions,
} from '@utils/mapFixedDataD01';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { isEmpty, isEqual } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useHgbMutationPut
} from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  PaymentTypeRequest,
  PaymentTypeResponse,
  PaymentTypeResponseA07
} from 'services/types/A07';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  ENTERPRISE_FORM_DEFAULT_VALUE,
  EmailByPicIDRequest,
  EmailByPicIDResponse,
  Enterprise,
  GetEnterpriseByIdRequest,
  GetEnterpriseByIdResponse,
  PicIDListboxResponse,
  UpdateEnterpriseRequest,
  UpdateEnterpriseResponse
} from 'services/types/enterprise';
import * as yup from 'yup';
import { SpsvCredit } from './SpsvCredit';
import { codeMajors, codeMediums } from './data';
// declare var SpsvApi: any;

type CardType = {
  card?: string;
  token?: string;
};

export const EnterpriseAdmin: FC = () => {
  const {
    text: { D01, common, E0000 },
    language,
  } = useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const enterpriseSchema = yup.object({
    enterpriseName: yup.string().required(E0000(common.label.enterpriseName)),
    enterpriseNameKana: yup
      .string()
      .required(E0000(common.label.enterpriseNameKana)),
    representative: yup.string().required(E0000(common.label.representative)),
    representativeKana: yup
      .string()
      .required(E0000(common.label.representativeKana)),
    postalCode: yup
      .string()
      .required(E0000(common.label.postalCode))
      .length(7, D01.message.postalCodeFormat),
    address1: yup.string().required(E0000(common.label.fullAddress)),
    startMonthOfYear: yup
      .string()
      .required(E0000(common.label.startMonthOfYear)),
    billingStartDate: yup
      .string()
      .required(E0000(common.label.billingDate))
      .when({
        is: () => user.subscriptionType !== 'COMPLIMENTARY',
        then: (schema) =>
          schema.matches(DATE_REGEX, E0000(common.label.billingDate)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    availableScope3CategoryArray: yup
      .array()
      .min(1, E0000(D01.label.scope3DirectInput))
      .required(E0000(D01.label.scope3DirectInput))
      .typeError(E0000(D01.label.scope3DirectInput)),
    paymentType: yup.string().when({
      is: () => user.subscriptionType !== 'COMPLIMENTARY',
      then: (schema) => schema.required(E0000(common.label.paymentMethod)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    hasAccount: yup.string().required(E0000(common.label.isCorporateAccount)),
    branchNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.branchNumber))
          .length(3, D01.message.branchNumberFormat);
      },
    }),
    corporateAccountNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.corporateAccountNumber))
          .length(7, D01.message.corporateAccountNumberFormat);
      },
    }),
    picName: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picName));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),

    picNameKana: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picNameKana));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    // picName: yup.string().required(D01.message.picName),
    // picNameKana: yup.string().required(D01.message.picNameKana),
    picEmail: yup
      .string()
      .required(E0000(common.label.picEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    industryCodeMajorClassification: yup
      .string()
      .required(E0000(common.label.industryMajorCodeClassification)),
    industryCodeMediumClassification: yup
      .string()
      .required(E0000(common.label.industryMediumCodeClassification)),
  });

  const updateMethods = useForm<Enterprise>({
    values: ENTERPRISE_FORM_DEFAULT_VALUE,
    resolver: yupResolver(enterpriseSchema),
  });

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    watch: watchUpdate,
    reset: resetUpdate,
    setValue: setValueUpdate,
    setError,
    clearErrors,
    getValues: getValueUpdate,
  } = updateMethods;
  const enterprisePayload = watchUpdate();

  const [loadedEnterprise, setLoadedEnterprise] =
    useState<GetEnterpriseByIdResponse>();

  const { mutate: updateEnterpriseMutate } = useHgbMutationPut<
    UpdateEnterpriseResponse,
    UpdateEnterpriseRequest
  >(API_URLS.ENTERPRISE_API_URL, {
    onSuccess: () => {
      setUpdateModal(false);
    },
  });

  useHgbQuery<GetEnterpriseByIdResponse, GetEnterpriseByIdRequest>(
    API_URLS.ENTERPRISE_API_URL + `/${user.enterpriseId}`,
    undefined,
    {
      queryKey: [user.enterpriseId],
      onSuccess: (data) => {
        LocalDataClass.user = {
          ...user,
          departmentName: data.result?.enterpriseName ?? '',
        };
        // setUser({ ...user, departmentName: data.result?.enterpriseName ?? '' });
        resetUpdate({
          ...data?.result,
          availableScope3CategoryArray:
            enterprisePayload?.availableScope3Category?.length > 0
              ? enterprisePayload?.availableScope3Category?.split(',')
              : [],
        });
        setLoadedEnterprise({
          ...data?.result,
          availableScope3CategoryArray:
            enterprisePayload?.availableScope3Category?.length > 0
              ? enterprisePayload?.availableScope3Category?.split(',')
              : [],
        });
      },
    },
  );

  const { data: categoryLBQuery } = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    { scopeCode: '3' },
    {
      enabled: true,
      queryKey: [language],
    },
  );

  const categoryCodeOptions = useMemo(() => {
    const rs = parseScopeCategoryToHgbSelectOptions(categoryLBQuery?.result);
    return rs;
  }, [categoryLBQuery?.result]);

  useEffect(() => {
    setValueUpdate(
      'availableScope3CategoryArray',
      enterprisePayload?.availableScope3Category?.length > 0
        ? enterprisePayload?.availableScope3Category?.split(',')
        : [],
    );
  }, [categoryCodeOptions, enterprisePayload?.availableScope3Category]);

  const [edited, setEdited] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  // const [updateCreditCardSuccessedModal, setUpdateCreditCardSuccessedModal] =
  //   useState(false);
  const [addressFromPostalCode] = useAddress(enterprisePayload.postalCode);

  const onError: SubmitErrorHandler<Enterprise> = (err) => {
    if (!getValueUpdate('branchNumber')) {
      setValueUpdate('branchNumber', '');
      clearErrors('branchNumber');
    }

    if (!getValueUpdate('corporateAccountNumber')) {
      setValueUpdate('corporateAccountNumber', '');
      clearErrors('corporateAccountNumber');
    }

    if (!getValueUpdate('picName')) {
      setValueUpdate('picName', '');
      clearErrors('picName');
    }

    if (!getValueUpdate('picNameKana')) {
      setValueUpdate('picNameKana', '');
      clearErrors('picNameKana');
    }

    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }
  };

  const submitEnterprise = (payload: Enterprise) => {
    let passedPayload: Enterprise = { ...payload };
    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }

    if (payload.hasAccount === '2') {
      passedPayload.branchNumber = '';
      passedPayload.corporateAccountNumber = '';
    }
    passedPayload = {
      ...passedPayload,
      availableScope3Category:
        passedPayload.availableScope3CategoryArray?.length > 0
          ? passedPayload.availableScope3CategoryArray.join(',')
          : '',
    };
    updateEnterpriseMutate(passedPayload);
  };

  const onSubmit: SubmitHandler<Enterprise> = (payload) => {
    if (showUpdateConfirm()) {
      setUpdateModal(true);
      return;
    }
    submitEnterprise(payload);
  };

  useEffect(() => {
    if (edited) {
      setValueUpdate('address1', addressFromPostalCode.arch.full);
      if (addressFromPostalCode.error) {
        setError('postalCode', { message: D01.message.postalCodeFormat });
      } else {
        clearErrors('postalCode');
        clearErrors('address1');
      }
    }
  }, [addressFromPostalCode]);

  const codeMajorOptions = createMajorOptions(codeMajors);

  const [isEditedMajor, setIsEditedMajor] = useState(false);
  const codeMediumOptions = useMemo(() => {
    const rs = createMediumOptions(
      codeMediums,
      enterprisePayload.industryCodeMajorClassification,
    );
    if (isEditedMajor) {
      setValueUpdate('industryCodeMediumClassification', rs?.[0]?.value);
    } else {
      setValueUpdate(
        'industryCodeMediumClassification',
        enterprisePayload.industryCodeMediumClassification,
      );
    }
    return rs;
  }, [enterprisePayload.industryCodeMajorClassification, isEditedMajor]);

  const { data: paymentQuery } = useHgbQuery<
    PaymentTypeResponseA07,
    PaymentTypeRequest
  >(
    API_URLS.PAYMENT_TYPE_LISTBOX,
    {
      enterpriseId: enterprisePayload?.id,
    },
    {
      queryKey: [API_URLS.PAYMENT_TYPE_LISTBOX, enterprisePayload?.id],
    },
  );

  const paymentOption = useMemo(
    () => parseToHgbSelectOptions(paymentQuery?.result?.methodType),
    [paymentQuery?.result],
  );

  useEffect(() => {
    setValueUpdate(
      'paymentType',
      (enterprisePayload?.paymentType ?? '').toString(),
    );
  }, [enterprisePayload.paymentType, enterprisePayload.id, paymentOption]);

  const { data: haveNoneQuery } = useHgbQuery<PaymentTypeResponse, undefined>(
    API_URLS.HAVE_NONE_LISTBOX,
    undefined,
    {
      queryKey: [API_URLS.HAVE_NONE_LISTBOX, language],
    },
  );

  const haveNoneOption = useMemo(
    () => parseToHgbSelectOptions(haveNoneQuery?.result),
    [haveNoneQuery?.result],
  );

  useEffect(() => {
    setValueUpdate('hasAccount', enterprisePayload?.hasAccount);
  }, [enterprisePayload?.hasAccount]);

  const { data: picIdQuery } = useHgbQuery<PicIDListboxResponse, undefined>(
    API_URLS.PICID_LISTBOX_API_URL,
    undefined,
    {
      enabled: !!enterprisePayload.id,
      queryKey: [
        API_URLS.PICID_LISTBOX_API_URL,
        enterprisePayload.groupStatus,
        enterprisePayload.id,
      ],
    },
  );

  const picIdOption = useMemo(
    () => parseToHgbSelectOptions(picIdQuery?.result),
    [picIdQuery?.result],
  );

  const { data: emailQuery, refetch: emailRefetch } = useHgbQuery<
    EmailByPicIDResponse,
    EmailByPicIDRequest
  >(
    API_URLS.PICEMAIL_API_URL,
    {
      picId: enterprisePayload.picId,
      enterpriseId: undefined,
    },
    {
      enabled: !!enterprisePayload.picId,
      queryKey: [API_URLS.PICEMAIL_API_URL],
    },
  );

  useEffect(() => {
    setValueUpdate('picId', (enterprisePayload?.picId ?? '').toString());
  }, [enterprisePayload.picId]);

  useEffect(() => {
    if (!enterprisePayload.picId) return;
    emailRefetch();
  }, [enterprisePayload.picId]);

  useEffect(() => {
    setValueUpdate('picEmail', emailQuery?.result ?? '');
  }, [emailQuery]);

  const showUpdateConfirm = () => {
    for (let key in loadedEnterprise) {
      if (
        !isEqual(
          loadedEnterprise[key as keyof GetEnterpriseByIdResponse],
          enterprisePayload[key as keyof GetEnterpriseByIdResponse],
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const spsvCreditState = useBooleanState(false)

  return (
    <>
      <SpsvCredit modalState={spsvCreditState} />
      <ManagementTemplate
        pageName={D01.pageTitleEnterprise}
        screenId="EnterpriseAdmin"
      >
        <FormProvider {...updateMethods}>
          <DataTableTemplate
            title={D01.pageTitleEnterprise}
            className="tw-flex-auto"
          >
            <form
              className="tw-flex tw-flex-col tw-gap-16 tw-bg-white pc:tw-flex-row pc:tw-gap-24"
              onSubmit={handleSubmitUpdate(onSubmit, onError)}
            >
              <HgbResponsive
                gap
                className="tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start pc:tw-grid-cols-2"
              >
                <HgbAntdInput
                  disabled
                  {...register('enterpriseCode')}
                  maxLength={100}
                  type={'K'}
                  label={common.label.enterpriseCode}
                  placeholder={D01.placeholder.enterpriseCode}
                />
                <HgbAntdInput
                  {...register('enterpriseName')}
                  maxLength={100}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.enterpriseName}
                  placeholder={common.placeholder.enterpriseName}
                />
                <HgbAntdInput
                  {...register('enterpriseNameKana')}
                  maxLength={100}
                  type="K"
                  required
                  label={common.label.enterpriseNameKana}
                  placeholder={common.placeholder.enterpriseNameKana}
                />
                <HgbAntdInput
                  {...register('representative')}
                  maxLength={50}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.representative}
                  placeholder={D01.placeholder.representative}
                />
                <HgbAntdInput
                  {...register('representativeKana')}
                  maxLength={50}
                  type="K"
                  required
                  label={common.label.representativeKana}
                  placeholder={D01.placeholder.representativeKana}
                />
                <HgbAntdInput
                  {...register('postalCode')}
                  maxLength={7}
                  type={'integer'}
                  required
                  label={common.label.postalCode}
                  placeholder={common.placeholder.postalCode}
                  onChangeValue={() => setEdited(true)}
                />
                <HgbAntdInput
                  {...register('phoneNumber')}
                  maxLength={11}
                  type={'integer'}
                  label={common.label.phoneNumber}
                  placeholder={common.placeholder.phoneNumber}
                />
                <HgbAntdInput
                  {...register('address1')}
                  maxLength={100}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.fullAddress}
                  placeholder={D01.placeholder.address1}
                />
                <HgbAntdInput
                  {...register('address2')}
                  maxLength={100}
                  type="K"
                  // required
                  className="tw-col-start-1"
                  label={common.label.address2}
                  placeholder={D01.placeholder.address2}
                />
                <HgbSelect
                  label={common.label.industryMajorCodeClassification}
                  className="tw-col-start-1"
                  {...register('industryCodeMajorClassification')}
                  placeholder={''}
                  options={codeMajorOptions}
                  onChangeValue={() => setIsEditedMajor(true)}
                  required
                />
                <HgbSelect
                  label={common.label.industryMediumCodeClassification}
                  className="tw-self-start"
                  {...register('industryCodeMediumClassification')}
                  placeholder={''}
                  options={codeMediumOptions}
                  required
                />
                <HgbSelect
                  {...register('startMonthOfYear')}
                  options={MONTH_OF_YEAR_OPTIONS}
                  label={common.label.startMonthOfYear}
                  placeholder={D01.placeholder.startMonthOfYear}
                  required
                />
                <HgbDatePicker
                  format="YYYY/MM"
                  required={user.subscriptionType !== 'COMPLIMENTARY'}
                  {...register('billingStartDate')}
                  label={common.label.billingDate}
                  placeholder={D01.placeholder.billingDate}
                  disabled
                />
                <HgbAntdRadioGroup
                  {...register('hasAccount')}
                  options={haveNoneOption}
                  label={common.label.isCorporateAccount}
                  required
                />
                {user.subscriptionType !== 'COMPLIMENTARY' && (
                  <>
                    <HgbSelect
                      {...register('paymentType')}
                      options={paymentOption}
                      label={common.label.paymentMethod}
                      required
                      placeholder={''}
                    />
                    <HgbAntdButton
                      type="link"
                      className={cn("tw-flex tw-whitespace-normal !tw-p-0 tw-text-left tw-no-underline pc:tw-col-start-2")}
                      onClick={spsvCreditState.turnOn}
                    >
                      {common.button.enterpriseAdminCard}
                    </HgbAntdButton>
                  </>
                )}
                {enterprisePayload.hasAccount === '1' && (
                  <HgbAntdInput
                    {...register('branchNumber')}
                    maxLength={3}
                    type={'integer'}
                    required
                    label={common.label.branchNumber}
                  />
                )}
                {enterprisePayload.hasAccount === '1' && (
                  <HgbAntdInput
                    {...register('corporateAccountNumber')}
                    maxLength={7}
                    type={'integer'}
                    required
                    label={common.label.corporateAccountNumber}
                  />
                )}
                {user.isManagementBank && (
                  <HgbAntdRadioGroup
                    {...register('isInvested')}
                    options={haveNoneOption}
                    label={D01.label.isInvested}
                  // required
                  />
                )}

                {enterprisePayload.groupStatus === 'ACTIVE' &&
                  !isEmpty(enterprisePayload.id?.toString()) && (
                    <HgbSelect
                      label={common.label.picName}
                      {...register('picId')}
                      placeholder={''}
                      options={picIdOption}
                    />
                  )}
                <HgbAntdInput
                  {...register('picEmail')}
                  label={common.label.picEmail}
                  type="H"
                  maxLength={100}
                  disabled={
                    enterprisePayload.groupStatus === 'ACTIVE' &&
                    !isEmpty(enterprisePayload.id?.toString())
                  }
                />
                {isEmpty(enterprisePayload.id?.toString()) && (
                  <div className="tw-grid tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start pc:tw-grid-cols-2">
                    <HgbAntdInput
                      {...register('picName')}
                      maxLength={50}
                      type={'K'}
                      required={isEmpty(enterprisePayload.id?.toString())}
                      label={common.label.picName}
                      className="tw-col-start-1"
                    />
                    <HgbAntdInput
                      {...register('picNameKana')}
                      maxLength={50}
                      type={'K'}
                      required={isEmpty(enterprisePayload.id?.toString())}
                      label={common.label.picNameKana}
                    />
                  </div>
                )}

                <HgbSelect
                  label={D01.label.scope3DirectInput}
                  {...register('availableScope3CategoryArray')}
                  placeholder={''}
                  options={categoryCodeOptions}
                  mode="multiple"
                  required
                />
              </HgbResponsive>
              <HgbResponsive className="tw-flex tw-items-center tw-justify-center tw-gap-24">
                <HgbAntdButton
                  className="tw-shrink-0 tw-self-start"
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  <>{common.button.save}</>
                </HgbAntdButton>
              </HgbResponsive>
            </form>
          </DataTableTemplate>
        </FormProvider>
        <HgbAntdModal
          open={updateModal}
          okText={common.button.ok}
          cancelText={common.button.cancel}
          title={D01.modal.enterpriseUpdateConfirmModalTitle}
          onCancel={() => setUpdateModal(false)}
          onOk={() => submitEnterprise(enterprisePayload)}
        >
          {D01.label.updateConfirmContent}
        </HgbAntdModal>
      </ManagementTemplate>
    </>

  )
};
