import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import {
  HgbAntdRadioGroup,
  HgbAntdRadioOption,
} from '@common/antd/HgbAntdRadioGroup';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import {
  HgbDatePicker,
  HgbSelect,
  parseScopeToHgbSelectOptions,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { STATUS_CODE } from '@constants/consts';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGet } from '@hooks/useApi';
import { useBooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxios, hgbAxiosGetSingle } from '@utils/axios';
import { cn } from '@utils/cn';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { emptyToUndefined } from '@utils/text';
import { Divider, Table, Tabs } from 'antd';
import { LocalDataClass } from 'data-class/LocalDataClass';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery, useHgbQueryWithPaging } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';
import { GetSupplyChainListBoxResponse } from 'services/types/supply_chain';
import {
  EmissionOutput,
  EmissionOutputRequest,
  EmissionVolume,
  GetAllOffsetRequest,
  OffsetDetails,
} from 'types/api/emission-output/emission-output.type';
import * as yup from 'yup';
import { SupplierResponse } from '../H01/H01';
import {
  SupplierAnswerModalMode,
  SupplierEmissionInputModal,
  SupplierPattern,
} from '../H04/SupplierEmissionInputModal';
import { MANUAL_NAMEDDESTS } from '../Support';
import { EmissionCreateModal } from './EmissionCreateModal';
import {
  EmissionDeleteAllData,
  EmissionDeleteAllModal,
  EmissionDeleteAllModalProps,
} from './EmissionDeleteAllModal';
import {
  EmissionDeleteModal,
  EmissionDeleteModalProps,
} from './EmissionDeleteModal';
import { EmissionUpdateModal } from './EmissionUpdateModal';
import { useC01Columns } from './hooks/useC01Columns';
import { EmissionInputScope1Response } from './type';
import { useLastTenYearsListBox } from './utils';
import { HgbAntdRadioGroup2 } from '@common/antd/HgbAntdRadioGroup2';

const validateMonthRange = (startMonth: string, endMonth: string) => {
  if (startMonth === '' || endMonth === '') return true;
  const start = dayjs(startMonth);
  const end = dayjs(endMonth);
  return end.diff(start) >= 0;
};
export interface EmissionOutputDataType {
  key: React.Key;
  targetYearMonth: string;
  enterpriseName: string;
  organizationBaseName: string;
  calculationMethodName: string;
  types: string[];
  amount: EmissionVolume[];
  intensity: EmissionOutput;
  emissionVolume: EmissionOutput;
  createdBy: string;
  createdDate: string;
  createdMethod: string;
  action?: React.ReactNode | null;
  insertMethodCode: string;
  selects?: string[];
}

export interface OffsetDataType {
  key: React.Key;
  targetYearMonth: string;
  enterpriseName: string;
  organizationBaseName: string;
  offsetAmount: string;
  offsetCertificateDate: string;
  offsetTarget: string;
  projectName: string;
  creditType: string;
  createdBy: string;
  createdDate: string;
  insertMethodCode: string;
  insertMethodName: string;
  action?: React.ReactNode | null;
  scopeCode: string;
  targetYear: string;
  categoryCode: string;
  baseId: string;
  targetLciDbYear: string;
  id: number;
}

type ScopeCode = '1' | '2' | '3' | '4';

type DateType = 'FISCAL_YEAR' | 'MONTH';
export type EmissionDataSearchForm = {
  bankId: string;
  enterpriseId: string;
  baseId: string;
  dateType: DateType;
  scopeCode: ScopeCode;
  categoryCode: string;
  startMonth: string;
  endMonth: string;
  targetYear: string;
  supplierId: string;
  typeOfGas: string;
};

export const C01: React.FunctionComponent = (props) => {
  const queryClient = useQueryClient();
  React.useEffect(() => {
    queryClient.clear();
  }, []);

  const {
    text: { C01, common, E0000, E0049 },
  } = React.useContext(LanguageContext)!;

  const { user } = React.useContext(AuthContext)!;
  const navigate = useNavigate();

  const { PaginationComponent, paginationProps, resetPaginations } =
    usePagin(25);

  const tabItems: { label: string; key: ScopeCode; note: string }[] = [
    { label: common.label.scope1, key: '1', note: common.label.scopeNote1 },
    { label: common.label.scope2, key: '2', note: common.label.scopeNote2 },
    { label: common.label.scope3, key: '3', note: common.label.scopeNote3 },
    { label: common.label.offset, key: '4', note: '' },
  ];

  const FISCAL_YEAR_OPTIONS: HgbAntdRadioOption<DateType>[] = [
    {
      label: C01.label.fiscalYear,
      value: 'FISCAL_YEAR',
    },
    {
      label: C01.label.targetYearMonth,
      value: 'MONTH',
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: OffsetDetails | EmissionOutput) => {
      return {
        disabled: record.isCanDelete === false,
      };
    },
  };

  const { language } = React.useContext(LanguageContext)!;
  const [itemEmission, setItemEmission] = React.useState<
    EmissionOutput | OffsetDetails
  >();
  const registerModalState = useBooleanState(false);
  const [enableAutoSearch, setEnableAutoSearch] = React.useState(false);
  const [selectedSupplier, setSelectedOwner] =
    React.useState<SupplierResponse>();
  const [pattern, setPattern] = React.useState<SupplierPattern>('PATTERN_1');
  const [mode, setMode] = React.useState<SupplierAnswerModalMode>('ADD');
  const [modeEmission, setModeEmission] = React.useState<
    'UPDATE' | 'DUPLICATE'
  >('UPDATE');

  const [emissionDeleteModalProps, setEmissionDeleteModalProps] =
    React.useState<EmissionDeleteModalProps>({
      open: false,
    });

  const [emissionDeleteAllModalProps, setEmissionDeleteAllModalProps] =
    React.useState<EmissionDeleteAllModalProps>({
      open: false,
      quantities: '',
      hasSupplierEmission: false,
    });

  const getSchema = yup.object().shape({
    startMonth: yup
      .string()
      .when('dateType', (dateType: DateType[], schemaBuilder) => {
        if (dateType.includes('MONTH') === false) {
          return schemaBuilder.notRequired();
        }
        return schemaBuilder.required(E0000(C01.label.startMonth));
      }),
    endMonth: yup
      .string()
      .when('dateType', (dateType: DateType[], schemaBuilder) => {
        if (dateType.includes('MONTH') === false) {
          return schemaBuilder.notRequired();
        }
        return schemaBuilder.required(E0000(C01.label.endMonth));
      }),
  });

  const searchMethods = useForm<EmissionDataSearchForm>({
    resolver: yupResolver(getSchema),
    defaultValues: {
      dateType: 'FISCAL_YEAR',
      bankId: '',
      enterpriseId: '',
      baseId: '',
      scopeCode: '1',
      categoryCode: '',
      startMonth: '',
      endMonth: '',
      targetYear: '',
      supplierId: '',
    },
  });

  const searchValues = searchMethods.watch();

  const { mutate: recalculation, isLoading: recalculatetionLoading } =
    useHgbMutationPost<any, undefined>(
      API_URLS.RECALCULATION_EMISSION_DATA_API,
      {
        onSuccess: (data) => {
          if (data.statusCode === STATUS_CODE.success) {
            refetchRelatedData();
          }
        },
      },
    );

  const { ConfirmModal, hgbConfirm } = useConfirm();
  const recalculationHandler = async () => {
    const result = await hgbConfirm();
    if (result) {
      recalculation(undefined);
    }
  };

  const emissionParams: EmissionOutputRequest | undefined =
    React.useMemo(() => {
      const rs: EmissionOutputRequest = {
        baseId: searchValues.baseId,
        enterpriseId: searchValues.enterpriseId,
        scopeCode:
          searchValues.typeOfGas !== 'CO2' &&
          !(searchValues.scopeCode === '1' || searchValues.scopeCode === '3') // xử lí case khi từ offset sang 7 loại khí thải
            ? ''
            : searchValues.scopeCode,
        typeOfGas: searchValues.typeOfGas,
        supplierId:
          user.isBuyer && user.isEnterpriseAdmin ? searchValues.supplierId : '',
        categoryCode:
          searchValues.scopeCode === '3'
            ? searchValues.categoryCode || '00'
            : '00',
        fromYearMonth:
          searchValues.dateType === 'MONTH'
            ? searchValues.startMonth.split('/').join('')
            : undefined,
        toYearMonth:
          searchValues.dateType === 'MONTH'
            ? searchValues.endMonth.split('/').join('')
            : undefined,
        targetYear:
          searchValues.dateType === 'FISCAL_YEAR'
            ? searchValues.targetYear
            : undefined,

        ...paginationProps,
      };
      return rs;
    }, [searchValues, paginationProps]);

  const { data: bankLBQuery, isFetchedAfterMount: bankLBFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      undefined,
      {
        enabled: user.isPresidingBank,
      },
    );

  const { data: enterpriseLBQuery, refetch: enterpriseLBRefetch } = useHgbQuery<
    GetEnterpriseListBoxResponse,
    GetEnterpriseListBoxRequest
  >(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    {
      bankId: emptyToUndefined(searchValues.bankId),
    },
    {
      enabled: false,
    },
  );

  React.useEffect(() => {
    if (
      (user.isPresidingBank && !isEmpty(searchValues.bankId)) ||
      user.isManagementBank
    ) {
      enterpriseLBRefetch();
    }
  }, [user.isPresidingBank, user.isManagementBank, searchValues.bankId]);

  //  Query supply chain list

  const supplyChainListboxQuery = useHgbQuery<
    GetSupplyChainListBoxResponse,
    undefined
  >(API_URLS.LISTBOX_COMPANY_SUPPLIER, undefined, {
    enabled: user.isEnterpriseAdmin && user.isBuyer,
    queryKey: [API_URLS.LISTBOX_COMPANY_SUPPLIER],
  });

  const BOsQuery = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      enterpriseId: emptyToUndefined(searchValues.enterpriseId),
      supplierId:
        user.isBuyer && user.isEnterpriseAdmin ? searchValues.supplierId : '',
      isLoadAllOption: true,
    },
    {
      enabled: false,
      queryKey: [searchValues.supplierId, API_URLS.BASE_LISTBOX_API_URL],
    },
  );

  React.useEffect(() => {
    if (
      user.isEnterpriseAdmin ||
      user.isEnterpriseUser ||
      !isEmpty(searchValues.enterpriseId) ||
      !isEmpty(searchValues.supplierId)
    ) {
      BOsQuery.refetch();
      // BOsRefetch();
      // yearLBRefetch();
    }
  }, [
    searchValues.enterpriseId,
    searchValues.supplierId,
    user.isEnterpriseAdmin,
    user.isEnterpriseUser,
  ]);

  const categoryLBQuery = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    {
      scopeCode: searchValues.scopeCode,
      enterpriseId: isEmpty(searchValues.enterpriseId)
        ? user.enterpriseId === 0
          ? null
          : user.enterpriseId
        : Number(searchValues.enterpriseId),
    },
    {
      enabled: false,
      queryKey: [
        searchValues.enterpriseId,
        searchValues.scopeCode,
        API_URLS.CATEGORY_LIST_BOX_API_URL,
      ],
    },
  );

  const { data: greenGasQuery, refetch: greenGasRefetch } = useHgbQuery<any>(
    `${API_URLS.GREEN_GAS_HOUSE_CODE}`,
    null,
    {
      enabled: false,
    },
  );

  React.useEffect(() => {
    greenGasRefetch();
  }, [language]);

  React.useEffect(() => {
    if (searchValues.scopeCode === '3') {
      categoryLBQuery.refetch();
    }
  }, [
    searchValues.scopeCode,
    searchValues.enterpriseId,
    searchValues?.typeOfGas,
    user.enterpriseId,
    language,
  ]);

  const [scopeEditFlow, getScopeEditFlow] = useGet<
    EmissionInputScope1Response,
    undefined
  >();

  const clickEditScopeHandler = async (item: EmissionOutput) => {
    if (item?.insertMethodCode === '3') {
      const response = (await hgbAxiosGetSingle<SupplierResponse>(
        API_URLS.BUYER_SUPPLIER_RESPONSE,
        item.supplierResponseId,
      )) as SupplierResponse;
      // có supplyChainBuyerId => pattern 1
      if (response?.supplyChainBuyerId) {
        setPattern('PATTERN_1');
      } else {
        setPattern('PATTERN_2');
      }
      setSelectedOwner(response);
      setMode('EDIT');
      return registerModalState.turnOn();
    }

    let url = '';
    if (searchValues?.typeOfGas === 'CO2' || !searchValues?.typeOfGas) {
      url = `/api/v1/emission-input/scope-${item.scopeCode}/flow${
        item.categoryCode !== '00'
          ? `/category-${Number(item.categoryCode)}`
          : ''
      }/${item.id}`;
    } else {
      url = `/api/v1/emission-input/green-house-gas/flow/${item.id}`;
    }

    getScopeEditFlow(url);
    modalStateUpdate.turnOn();
    setItemEmission(item);
  };
  const { addMessage } = React.useContext(PortalContext)!;
  const clickDuplicateScopeHandler = async (item: EmissionOutput) => {
    if (item.scopeCode === '2') {
      try {
        const rs = await hgbAxios().post(
          `/api/v1/emission-input/scope-2/validate/${item.id}?lang=${LocalDataClass.language}`,
        );
      } catch (error: any) {
        const message = error?.response?.data?.message;
        const statusCode = error?.response?.data?.statusCode;
        if (message && statusCode === 10259) {
          addMessage('error', message);
          return;
        }
      }
    }
    if (item?.insertMethodCode === '3') {
      const response = (await hgbAxiosGetSingle<SupplierResponse>(
        API_URLS.BUYER_SUPPLIER_RESPONSE,
        item.supplierResponseId,
      )) as SupplierResponse;

      setPattern('PATTERN_2');
      setSelectedOwner(response);
      setMode('DUPLICATE');
      return registerModalState.turnOn();
    }

    let url = '';
    if (searchValues?.typeOfGas === 'CO2' || !searchValues?.typeOfGas) {
      url = `/api/v1/emission-input/scope-${item.scopeCode}/flow${
        item.categoryCode !== '00'
          ? `/category-${Number(item.categoryCode)}`
          : ''
      }/${item.id}`;
    } else {
      url = `/api/v1/emission-input/green-house-gas/flow/${item.id}`;
    }
    getScopeEditFlow(url);
    setModeEmission('DUPLICATE');
    setItemEmission(item);
    modalStateUpdate.turnOn();
  };

  const clickEditOffsetHandler = (item: OffsetDetails) => {
    setItemEmission(item);
    modalStateUpdate.turnOn();
  };

  const clickDuplicateOffsetHandler = (item: OffsetDetails) => {
    setItemEmission(item);
    setModeEmission('DUPLICATE');
    modalStateUpdate.turnOn();
  };

  const clickDeleteEmissionHandler = (item: OffsetDetails | EmissionOutput) => {
    setEmissionDeleteModalProps({
      data: item,
      open: true,
    });
  };

  const clickDeleteAllEmissionHandler = (item: EmissionDeleteAllData) => {
    setEmissionDeleteAllModalProps({
      data: item,
      open: true,
      quantities: selectedRowKeys.length.toString(),
      hasSupplierEmission: hasSupplierEmissionHandle,
    });
  };

  const supplyChainOptions = React.useMemo(() => {
    const rs = parseToHgbSelectOptions(supplyChainListboxQuery.data?.result);
    searchMethods.setValue('supplierId', rs?.[0]?.value);
    return rs;
  }, [supplyChainListboxQuery.data?.result]);

  const bankIdOptions = React.useMemo(
    () => parseToHgbSelectOptions(bankLBQuery?.result),
    [bankLBQuery?.result],
  );

  const enterpriseIdOptions = React.useMemo(
    () => parseToHgbSelectOptions(enterpriseLBQuery?.result),
    [enterpriseLBQuery?.result],
  );

  const targetYearOptions = useLastTenYearsListBox(
    emptyToUndefined(searchValues.enterpriseId),
  ).options;

  const baseIdOptions = React.useMemo(() => {
    if (
      searchValues.supplierId !== '0' &&
      searchValues.supplierId !== '-1' &&
      searchValues.supplierId !== undefined &&
      searchValues.supplierId !== ''
    ) {
      searchMethods.setValue('baseId', '');
      return [];
    }
    const BOs = BOsQuery?.data?.result ?? [];
    return convertObjectToTree(
      BOs.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      BOs,
    );
  }, [JSON.stringify(BOsQuery?.data?.result)]);

  const categoryCodeOptions = React.useMemo(
    () => parseScopeToHgbSelectOptions(categoryLBQuery?.data?.result),
    [JSON.stringify(categoryLBQuery?.data?.result)],
  );

  const dateErrorMessage = React.useMemo(() => {
    if (!enableAutoSearch) return false;
    if (searchValues.dateType === 'MONTH') {
      if (isEmpty(searchValues.startMonth)) {
        return common.message.startMonthEmpty;
      }
      if (isEmpty(searchValues.endMonth)) {
        return common.message.endMonthEmpty;
      }
      if (!validateMonthRange(searchValues.startMonth, searchValues.endMonth)) {
        return E0049;
      }
    }
    return undefined;
  }, [searchValues, language, enableAutoSearch]);

  const errorMessage = React.useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(bankIdOptions) && bankLBFetchedAfterMount) {
        return common.message.partnerBanksEmpty;
      }
      if (searchValues.bankId === '') {
        return common.message.choosePartnerBankAndCompany;
      }
      if (isEmpty(enterpriseIdOptions)) {
        return common.message.companyEmpty;
      }
      if (isEmpty(searchValues.enterpriseId)) {
        return common.message.chooseCompany;
      }
    }

    if (user.isManagementBank) {
      if (isEmpty(enterpriseIdOptions)) {
        return common.message.companyEmpty;
      }
      if (isEmpty(searchValues.enterpriseId)) {
        return common.message.chooseCompany;
      }
    }
    return undefined;
  }, [
    user,
    searchValues,
    enterpriseIdOptions,
    bankIdOptions,
    bankLBFetchedAfterMount,
    language,
  ]);

  const offsetQuery = useHgbQueryWithPaging<
    OffsetDetails[],
    GetAllOffsetRequest
  >(API_URLS.OFFSET_API_URL, emissionParams, {
    enabled: enableAutoSearch && emissionParams.scopeCode === '4',
    queryKey: [
      QUERY_KEYS.EMISSION_LIST,
      '4',
      API_URLS.OFFSET_API_URL,
      emissionParams,
      user.accountId,
    ],
  });

  const emissionQuery = useHgbQueryWithPaging<
    EmissionOutput[],
    GetAllOffsetRequest
  >(API_URLS.EMISSION_OUTPUT_DATA_LIST, emissionParams, {
    enabled:
      enableAutoSearch &&
      emissionParams.scopeCode !== '' &&
      emissionParams.scopeCode !== '4',
    queryKey: [
      QUERY_KEYS.EMISSION_LIST,
      emissionParams.scopeCode,
      API_URLS.EMISSION_OUTPUT_DATA_LIST,
      emissionParams,
      user.accountId,
    ],
  });

  React.useEffect(() => {
    resetPaginations();
  }, [
    emissionParams.bankId,
    emissionParams.baseId,
    emissionParams.categoryCode,
    emissionParams.enterpriseId,
    emissionParams.fromYearMonth,
    emissionParams.toYearMonth,
    emissionParams.targetYear,
    emissionParams.scopeCode,
    emissionParams.supplierId,
  ]);

  React.useEffect(() => {
    if (!registerModalState.value) {
      setSelectedOwner(undefined);
    }
  }, [registerModalState.value]);

  const emissionData = React.useMemo(() => {
    return (emissionQuery?.data?.result?.content ?? []).map((item, index) => {
      return {
        key: item.id,
        targetYearMonth: item.targetYearMonth,
        enterpriseName: item.enterpriseName,
        organizationBaseName: item.organizationBaseName,
        calculationMethodName: item.calculationMethodName,
        calculationTargetName: item.calculationTargetName,
        types: item.selects,
        amount: item.inputs,
        intensity: item,
        emissionVolume: item,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        createdMethod: item.createdMethod || '',
        targetLciDbYear: item,
        isCanDelete: item.isCanDelete,
        insertMethodCode: item.insertMethodCode,
        activityName: item.activityName,
        action: (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => clickDuplicateScopeHandler(item)}
              disabled={!item.isCanUpdate || !!item.supplyChainBuyerId}
              icon={<CopyOutlined />}
            >
              {common.button.duplicate}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickEditScopeHandler(item)}
              disabled={!item.isCanUpdate}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickDeleteEmissionHandler(item)}
              disabled={!item.isCanDelete}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ),
      };
    });
  }, [
    JSON.stringify(emissionQuery?.data?.result?.content),
    selectedRowKeys,
    language,
  ]);

  const offsetData: OffsetDataType[] = React.useMemo(() => {
    return (offsetQuery?.data?.result?.content ?? []).map((item, index) => {
      return {
        key: item.id,
        targetYearMonth: item.targetYearMonth,
        enterpriseName: item.enterpriseName,
        organizationBaseName: item.organizationBaseName,
        offsetAmount: `${(item.offsetAmount ?? 0).toFixed(3)} ${
          item.offsetAmountUnit
        }`,
        offsetCertificateDate: item.offsetCertificateDate,
        offsetTarget: item.offsetTarget,
        projectName: item.projectName,
        creditType: item.creditType,
        createdBy: item.createBy,
        createdDate: item.createDate,
        insertMethodCode: item.insertMethodCode,
        insertMethodName: item.insertMethodName,
        baseId: item.baseId,
        scopeCode: item.scopeCode,
        targetYear: item.targetYear,
        categoryCode: item.categoryCode,
        targetLciDbYear: item.targetLciDbYear,
        id: item.id,
        isCanDelete: item.isCanDelete,
        action: (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => {
                clickDuplicateOffsetHandler({
                  ...item,
                  offsetAmount: undefined,
                  offsetCertificateDate: '',
                });
              }}
              disabled={!item.isCanUpdate}
              icon={<CopyOutlined />}
            >
              {common.button.duplicate}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickEditOffsetHandler(item)}
              disabled={!item.isCanUpdate}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickDeleteEmissionHandler(item)}
              disabled={!item.isCanDelete}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ),
      };
    });
  }, [
    JSON.stringify(offsetQuery?.data?.result?.content),
    selectedRowKeys,
    language,
  ]);

  const closeEmissionDeleteModal = () => {
    setEmissionDeleteModalProps((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const closeEmissionDeleteAllModal = () => {
    setEmissionDeleteAllModalProps((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const deleteSuccessHandler = () => {
    closeEmissionDeleteModal();
    resetPaginations();
    offsetQuery.refetch();
    emissionQuery.refetch();
  };

  const deleteFailHandler = () => {
    closeEmissionDeleteModal();
    resetPaginations();
    offsetQuery.refetch();
    emissionQuery.refetch();
  };

  const deleteAllSuccessHandler = () => {
    closeEmissionDeleteAllModal();
    setSelectedRowKeys([]);
    resetPaginations();
    offsetQuery.refetch();
    emissionQuery.refetch();
  };

  const { emissionColumns, offsetColumns } = useC01Columns(searchValues);

  const dataTable = React.useMemo(() => {
    const dataSource =
      searchValues.scopeCode === '4' ? offsetData : emissionData;
    const columns =
      searchValues.scopeCode === '4' ? offsetColumns : emissionColumns;

    if (isEmpty(dataSource)) {
      return offsetQuery.isFetchedAfterMount ||
        emissionQuery.isFetchedAfterMount ? (
        <HgbAntdEmpty
          className={'tw-h-full tw-items-center tw-justify-center'}
          description={common.message.noResult}
        />
      ) : null;
    } else {
      return (
        <Table
          rowSelection={
            user.isEnterpriseAdmin || user.isEnterpriseUser
              ? { ...(rowSelection as any) }
              : ''
          }
          columns={columns as any}
          dataSource={dataSource as any}
          pagination={false}
          scroll={{ x: 'max-content', y: 500 }}
          rowKey={(record) => record.key}
        />
      );
    }
  }, [
    emissionData,
    offsetData,
    language,
    offsetQuery.isFetchedAfterMount,
    emissionQuery.isFetchedAfterMount,
    selectedRowKeys,
  ]);

  const Pagination = React.useCallback(() => {
    if (emissionParams.scopeCode === '4') {
      return (offsetQuery?.data?.result?.totalRecord ?? 0) > 0 ? (
        <PaginationComponent
          current={offsetQuery?.data?.result?.currentPage}
          total={offsetQuery?.data?.result?.totalRecord}
          pageSize={offsetQuery?.data?.result?.pageSize}
        />
      ) : null;
    }
    return (emissionQuery?.data?.result?.totalRecord ?? 0) > 0 ? (
      <PaginationComponent
        current={emissionQuery?.data?.result?.currentPage}
        total={emissionQuery?.data?.result?.totalRecord}
        pageSize={emissionQuery?.data?.result?.pageSize}
      />
    ) : null;
  }, [
    emissionParams.scopeCode,
    offsetQuery?.data?.result,
    emissionQuery?.data?.result,
    PaginationComponent,
  ]);

  const hasSupplierEmissionHandle = React.useMemo(() => {
    const supplierEmissionArr = emissionQuery?.data?.result?.content?.filter(
      (item) => item.insertMethodCode === '3',
    );
    if (isEmpty(supplierEmissionArr)) return false;
    const array = supplierEmissionArr
      ?.map((item) => item.id)
      ?.filter((i) => selectedRowKeys.includes(i));
    if (isEmpty(array)) return false;
    return true;
  }, [selectedRowKeys, emissionQuery?.data?.result?.content]);

  const refetchRelatedData = React.useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.EMISSION_LIST, emissionParams?.scopeCode],
    });
  }, [emissionParams?.scopeCode]);

  const handleCreateSupplierInput = () => {
    setMode('ADD');
    setPattern('PATTERN_2');
    registerModalState.turnOn();
  };

  const modalState = useBooleanState(false);
  const modalStateUpdate = useBooleanState(false);

  const filteredTabs = tabItems.filter(
    (item) =>
      (searchValues?.typeOfGas !== 'CO2' && ['1', '3'].includes(item.key)) ||
      searchValues?.typeOfGas === 'CO2',
  );

  const isActiveKeyValid = filteredTabs.some(
    (tab) => tab.key === searchValues.scopeCode,
  );
  const activeTabKey = isActiveKeyValid
    ? searchValues.scopeCode
    : filteredTabs[0]?.key;

  React.useEffect(() => {
    searchMethods.setValue('scopeCode', activeTabKey.toString() as ScopeCode);
    setSelectedRowKeys([]);
  }, [activeTabKey]);

  return (
    <ManagementTemplate pageName={C01.pageTitle}>
      <DataTableTemplate
        title={C01.pageTitle}
        paginationComponents={<Pagination />}
      >
        <FormProvider {...searchMethods}>
          <div className="tw-flex tw-flex-col tw-gap-16 pc:tw-gap-24">
            {(user.isEnterpriseAdmin || user.isEnterpriseUser) && (
              <>
                <div className="tw-flex tw-flex-col tw-gap-24">
                  <h3 className="tw-text-[22px] tw-font-bold">
                    {C01.label.newRegistrationEmissionData}
                  </h3>
                  <div className="tw-flex tw-flex-wrap tw-gap-16">
                    <HgbAntdButton
                      type="primary"
                      ghost
                      endIcon={
                        <HgbInfoLink
                          to={`/support/manual/${MANUAL_NAMEDDESTS.about_upload_file}`}
                          target="_blank"
                        />
                      }
                      onClick={() => navigate(routs_en['/data-entry/ocr'].link)}
                      className="tw-w-full pc:tw-w-220"
                    >
                      {C01.label.uploadFile}
                    </HgbAntdButton>

                    <HgbAntdButton
                      type="primary"
                      ghost
                      endIcon={
                        <HgbInfoLink
                          to={`/support/manual/${MANUAL_NAMEDDESTS.about_import_csv}`}
                          target="_blank"
                        />
                      }
                      onClick={() => navigate(routs_en['/data-entry/csv'].link)}
                      className="tw-w-full pc:tw-w-220"
                    >
                      {C01.label.uploadCSV}
                    </HgbAntdButton>

                    {modalState.value ? (
                      <EmissionCreateModal modalState={modalState} />
                    ) : null}

                    <HgbAntdButton
                      type="primary"
                      ghost
                      className="tw-w-full pc:tw-w-220"
                      onClick={modalState.turnOn}
                      endIcon={
                        <HgbInfoLink
                          to={`/support/manual/${MANUAL_NAMEDDESTS.about_input_direct}`}
                          target="_blank"
                        />
                      }
                    >
                      {C01.modal.emissionCreateTitle}
                    </HgbAntdButton>

                    {!user.isEnterpriseUser && (
                      <HgbAntdButton
                        type="primary"
                        ghost
                        onClick={handleCreateSupplierInput}
                        className="tw-w-full pc:tw-w-220"
                      >
                        {C01.label.supplierEmissionsInput}
                      </HgbAntdButton>
                    )}
                  </div>
                </div>
                <Divider dashed className="tw-m-0 tw-border-neutral-1" />
              </>
            )}

            <h3 className="tw-text-[22px] tw-font-bold">
              {C01.label.emissionDataSearch}
            </h3>
            <div className="tw-flex tw-flex-col tw-gap-24">
              <div className="tw-flex tw-flex-wrap tw-gap-24">
                {user.isPresidingBank ? (
                  <HgbSelect
                    className="tw-flex tw-w-220 tw-flex-col"
                    label={common.placeholder.bankId}
                    placeholder={C01.placeholder.bankId}
                    options={bankIdOptions}
                    disabled={isEmpty(bankIdOptions)}
                    {...searchMethods.register('bankId')}
                    onChangeValue={() =>
                      searchMethods.setValue(
                        'enterpriseId',
                        enterpriseIdOptions?.[0]?.value,
                      )
                    }
                    showSearch
                  />
                ) : null}
                {user.isPresidingBank || user.isManagementBank ? (
                  <HgbSelect
                    className="tw-flex tw-w-240 tw-flex-col"
                    label={C01.label.enterpriseId}
                    placeholder={C01.placeholder.enterpriseId}
                    disabled={isEmpty(enterpriseIdOptions)}
                    options={enterpriseIdOptions}
                    {...searchMethods.register('enterpriseId')}
                    showSearch
                  />
                ) : null}

                {user.isEnterpriseAdmin && user.isBuyer ? (
                  <HgbSelect
                    className="tw-flex tw-w-220 tw-flex-col"
                    label={C01.label.enterpriseId}
                    options={supplyChainOptions}
                    disabled={isEmpty(supplyChainOptions)}
                    {...searchMethods.register('supplierId')}
                    showSearch
                  />
                ) : null}

                <HgbTreeSelect
                  className="tw-flex tw-w-220 tw-flex-col tw-justify-end"
                  label={C01.label.baseId}
                  placeholder={C01.placeholder.baseId}
                  disabled={isEmpty(baseIdOptions)}
                  treeData={baseIdOptions}
                  {...searchMethods.register('baseId')}
                  showSearch
                  onChangeValue={() => setSelectedRowKeys([])}
                  autoInitValue
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-24">
                <div className="tw-flex tw-flex-col tw-gap-8">
                  <h4 className="tw-font-bold">
                    {C01.label.displayRangeSpecification}
                  </h4>
                  <div className="tw-flex tw-flex-wrap tw-gap-x-24 tw-gap-y-8">
                    <div className="tw-flex tw-h-fit tw-gap-x-8">
                      <HgbAntdRadioGroup2
                        options={[FISCAL_YEAR_OPTIONS[0]]}
                        name={'dateType'}
                        disabled={isEmpty(targetYearOptions)}
                        onChangeValue={() => setSelectedRowKeys([])}
                      />
                      <HgbSelect
                        disabled={
                          isEmpty(targetYearOptions) ||
                          searchValues.dateType === 'MONTH'
                        }
                        options={targetYearOptions}
                        {...searchMethods.register('targetYear')}
                        placeholder={C01.placeholder.targetYear}
                        className="tw-w-120"
                        onChangeValue={() => setSelectedRowKeys([])}
                        autoInitValue
                      />
                    </div>

                    <div className="tw-flex tw-flex-wrap tw-items-start tw-gap-x-8">
                      <HgbAntdRadioGroup2
                        options={[FISCAL_YEAR_OPTIONS[1]]}
                        name={'dateType'}
                        disabled={isEmpty(targetYearOptions)}
                        onChangeValue={() => setSelectedRowKeys([])}
                        className="tw-h-40"
                      />
                      <div className="tw-flex tw-flex-col">
                        <div className="tw-flex tw-items-center tw-gap-16">
                          <HgbDatePicker
                            disabled={
                              isEmpty(targetYearOptions) ||
                              searchValues.dateType === 'FISCAL_YEAR'
                            }
                            picker="month"
                            {...searchMethods.register('startMonth')}
                            placeholder="2022/03"
                            format="YYYY/MM"
                            className="tw-w-120"
                            onChangeValue={() => setSelectedRowKeys([])}
                          />
                          <div className="tw-h-1 tw-w-8 tw-bg-neutral-8"></div>
                          <HgbDatePicker
                            disabled={
                              isEmpty(targetYearOptions) ||
                              searchValues.dateType === 'FISCAL_YEAR'
                            }
                            picker="month"
                            {...searchMethods.register('endMonth')}
                            placeholder="2022/04"
                            format="YYYY/MM"
                            className="tw-w-120"
                            onChangeValue={() => setSelectedRowKeys([])}
                          />
                        </div>
                        {dateErrorMessage && (
                          <HgbFieldError>{dateErrorMessage}</HgbFieldError>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-flex tw-flex-wrap tw-items-end tw-gap-24">
                  <HgbSelect
                    {...searchMethods.register('typeOfGas')}
                    label={C01.label.greenHouseGases}
                    className="tw-w-220"
                    options={parseToHgbSelectOptions(greenGasQuery?.result)}
                    placeholder={C01.placeholder.categoryCode}
                    autoInitValue
                  />

                  <HgbAntdButton
                    className="tw-w-120"
                    type="primary"
                    onClick={() => {
                      setEnableAutoSearch(true);
                      setSelectedRowKeys([]);
                    }}
                  >
                    {C01.label.search}
                  </HgbAntdButton>
                </div>
              </div>

              <div className="tw-flex tw-flex-wrap tw-items-end tw-gap-16 large:tw-flex-row">
                <div className="tw-flex tw-flex-auto tw-flex-wrap tw-gap-16 tw-overflow-hidden large:tw-gap-24">
                  <Tabs
                    className={cn(
                      '!tw-border-0 [&_.ant-tabs-tab-active_.ant-tabs-tab-btn]:tw-font-bold',
                      { 'tw-min-w-320': searchValues?.typeOfGas === 'CO2' },
                    )}
                    activeKey={activeTabKey}
                    onChange={(index) => {
                      searchMethods.setValue(
                        'scopeCode',
                        index.toString() as ScopeCode,
                      );
                      searchMethods.setValue('categoryCode', '');
                      setSelectedRowKeys([]);
                    }}
                    items={filteredTabs.map((item) => ({
                      label: <div title={item.note}>{item.label}</div>,
                      key: item.key,
                    }))}
                  />
                  {searchValues.scopeCode === '3' && (
                    <HgbSelect
                      className="tw-w-full tw-justify-center pc:tw-w-400"
                      {...searchMethods.register('categoryCode')}
                      options={categoryCodeOptions}
                      disabled={isEmpty(categoryCodeOptions)}
                      onChangeValue={() => setSelectedRowKeys([])}
                      autoInitValue
                    />
                  )}
                </div>

                {(user.isEnterpriseAdmin || user.isEnterpriseUser) && (
                  <HgbAntdButton
                    className={cn('pc:tw-w-160', {
                      'tw-w-[calc(50%_-_8px)]': !user.isEnterpriseUser,
                      'tw-w-full': user.isEnterpriseUser,
                    })}
                    type="primary"
                    onClick={() =>
                      clickDeleteAllEmissionHandler({
                        id: selectedRowKeys.join(),
                        scopeCode: searchMethods.watch('scopeCode'),
                        insertMethodCode: '3',
                        categoryCode:
                          searchMethods.watch('scopeCode') !== '3'
                            ? '00'
                            : searchMethods.watch('categoryCode'),
                        hasSupplierEmission: hasSupplierEmissionHandle,
                        typeOfGas: searchMethods.watch('typeOfGas'),
                      })
                    }
                    disabled={isEmpty(selectedRowKeys)}
                  >
                    {C01.label.deleteAll}
                  </HgbAntdButton>
                )}

                {user.isEnterpriseAdmin && (
                  <HgbAntdButton
                    className="tw-w-[calc(50%_-_8px)] pc:tw-w-160"
                    type="primary"
                    loading={recalculatetionLoading}
                    onClick={recalculationHandler}
                    endIcon={
                      <HgbInfoLink
                        to={`/support/manual/${MANUAL_NAMEDDESTS.about_reload_emission}`}
                        target="_blank"
                      />
                    }
                  >
                    {C01.label.lcidb}
                  </HgbAntdButton>
                )}
              </div>

              <div className="tw-relative tw-flex-auto">
                {!!errorMessage || !!dateErrorMessage ? (
                  errorMessage ? (
                    <HgbAntdEmpty
                      description={errorMessage}
                      className="tw-flex tw-h-full tw-items-center tw-justify-center"
                    />
                  ) : null
                ) : offsetQuery.isFetching || emissionQuery.isFetching ? (
                  <HgbAntdSpin className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
                ) : (
                  dataTable
                )}
              </div>

              {modalStateUpdate.value &&
              (scopeEditFlow.data || itemEmission?.scopeCode === '4') ? (
                <EmissionUpdateModal
                  modalState={modalStateUpdate}
                  dataEmission={scopeEditFlow.data ?? undefined}
                  itemEmission={itemEmission}
                  mode={modeEmission}
                  setModeEmission={setModeEmission}
                />
              ) : null}

              <EmissionDeleteModal
                {...emissionDeleteModalProps}
                onCancel={closeEmissionDeleteModal}
                onSuccess={deleteSuccessHandler}
                onFail={deleteFailHandler}
              />
              <EmissionDeleteAllModal
                {...emissionDeleteAllModalProps}
                onCancel={closeEmissionDeleteAllModal}
                onSuccess={deleteAllSuccessHandler}
              />
            </div>
          </div>
        </FormProvider>
      </DataTableTemplate>
      <ConfirmModal
        title={C01.modal.recalculationConfirmModalTitle}
        cancelText={common.button.cancel}
        okText={C01.modal.recalculationConfirmModalOk}
      >
        <p>{C01.modal.recalculationConfirmModalContent}</p>
      </ConfirmModal>

      {registerModalState?.value && (
        <SupplierEmissionInputModal
          modalState={registerModalState}
          supplier={selectedSupplier}
          pattern={pattern}
          mode={mode}
        />
      )}
    </ManagementTemplate>
  );
};
