import { LanguageContext } from '@contexts/LanguageContext';
import { DataTableTemplate } from '@layouts/templates';
import * as React from 'react';
import { Bar, ComposedChart, Line, XAxis, YAxis } from 'recharts';
import { LineLegend, RecLegend } from './components/Legend';
import { predictColor, predictColorCurrent, predictLineColor } from './const';
import { PredictChartDetail } from './types';

type PredictChartRecord = {
  month: string;
  cumulativeVolume: {
    current: number;
    predict: number;
  };
  cumulativeTarget: number;
};

const convertToChartRecord = (
  record: PredictChartDetail,
): PredictChartRecord => {
  const result: PredictChartRecord = {
    month: record.month || '',
    cumulativeTarget: record.cumulativeTarget || 0,
    cumulativeVolume: {
      current: 0,
      predict: 0,
    },
  };
  if (record.prediction) {
    result.cumulativeVolume.predict = record.cumulativeVolume || 0;
  } else {
    result.cumulativeVolume.current = record.cumulativeVolume || 0;
  }
  return result;
};

interface IPredictChartProps {
  data?: PredictChartDetail[];
}

export const PredictChart: React.FunctionComponent<IPredictChartProps> =
  React.memo(({ data }) => {
    const {
      text: { B01, common },
    } = React.useContext(LanguageContext)!;
    // if ((data || []).length === 0) return null;
    return (
      <DataTableTemplate
        className="tw-my-24 tw-scroll-mt-64"
        title={B01.label.thisYearsSimulation}
      >
        <div className="tw-space-y-16">
          <div className="tw-mx-auto tw-w-fit tw-max-w-[100%] tw-overflow-hidden tw-overflow-x-scroll">
            <ComposedChart
              width={900}
              height={408}
              data={(data || []).map(convertToChartRecord).map((item) => ({
                ...item,
                month: common.month[item.month as '01'],
              }))}
              margin={{
                left: 35,
                right: 35,
                bottom: 0,
                top: 40,
              }}
            >
              <XAxis dataKey="month" fontSize={14} />
              <YAxis
                fontSize={14}
                label={{ value: 'tCO2', position: 'top', offset: 20 }}
              />
              <Bar
                dataKey="cumulativeVolume.current"
                barSize={25}
                stackId="a"
                style={{ fill: predictColorCurrent }}
              />
              <Bar
                dataKey="cumulativeVolume.predict"
                barSize={20}
                stackId="a"
                style={{ fill: predictColor }}
              />
              <Line
                type="monotone"
                dataKey="cumulativeTarget"
                stroke="#f59e0b"
                strokeWidth={2}
                dot={false}
              />
            </ComposedChart>
          </div>
          <div className="tw-mx-auto tw-flex tw-w-fit tw-flex-wrap tw-gap-16 ">
            <RecLegend
              color={predictColorCurrent}
              label={B01.label.cumulativeCurrentYear}
            />
            <RecLegend color={predictColor} label={B01.label.predict} />
            <LineLegend
              color={predictLineColor}
              label={B01.label.targetCurrentYear}
            />
          </div>
        </div>
      </DataTableTemplate>
    );
  });
