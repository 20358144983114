import { HgbAntdModal, HgbAntdModalProps } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import {
  HgbTreeSelect,
  HgbTreeSelectOption,
} from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { debug } from '@utils/debug';
import { floorNumber, toBigDecimal } from '@utils/input.utils';
import { getFieldsFromFormula } from '@utils/object.utils';
import parse from 'html-react-parser';
import { isEmpty, isUndefined } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  Emission,
  EmissionInputScope1Request,
  EmissionInputScope1Response,
  FlowInputDetail,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from '../C01/type';

import * as yup from 'yup';
import { getResultFromFormula } from '../C01/utils';
import { MANUAL_NAMEDDESTS } from '../Support';

type offsetInitProps = {
  offsetAmount?: number;
  offsetAmountUnit?: string;
  offsetCertificateDate?: string;
};

type scopeInitProps = {
  activityVolume?: number;
  activityVolumeUnit?: string;
};

export interface UploadModalProps extends HgbAntdModalProps {
  step1?: any;
  offset?: offsetInitProps;
  scope?: scopeInitProps;
  step1Options?: {
    targetFiscalYearOptions: HgbSelectOption[];
    targetYearMonthOptions: HgbSelectOption[];
    baseOptions: HgbTreeSelectOption[];
    scopeOptions: HgbSelectOption[];
  };
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

type ModalType = 'STEP_1' | 'STEP_2';
type ModalStatus = {
  type: ModalType;
};

const STEP2_DEFAULT_VALUE: Step2Fields = {
  emissionUnit: '',
  calculationTargetCode: '',
  calculationMethodCode: '',
  scope2MethodSelected: '',
  fuelCode: '',
  activityVolume: undefined,
  activityVolumeUnit: '',
  emissionIntensity: undefined,
  emissionIntensityUnit: '',
  emissionVolume: '',
  emissionVolumeUnit: '',
  modelCode: '',
  refrigerantNo: '',
  fillingVolume: undefined,
  fillingVolumeUnit: '',
  recoveryVolume: undefined,
  recoveryVolumeUnit: '',
  useEmissionCoefficient: undefined,
  useEmissionCoefficientUnit: '',
  gwp: undefined,
  gwpUnit: '',
  activityName: '',
  supplyMenuCode: '',
  retailElectricCalculationMethodCode: '',
  operatorGraspRate: undefined,
  operatorGraspRateUnit: '',
  heatUsageCode: '',
  departmentCode: '',
  rawActivityVolume: undefined,
  rawEmissionIntensity: undefined,
  rawTransportActivityVolume: undefined,
  rawTransportEmissionIntensity: undefined,
  capitalWasteActivityVolume: undefined,
  capitalWasteEmissionIntensity: undefined,
  energyTypeCode: '',
  electricCompanyNo: '',
  transportTypeCode: '',
  transportDistance: undefined,
  transportDistanceUnit: '',
  transportCargoWeight: undefined,
  transportCargoWeightUnit: '',
  loadPercentageCode: '',
  transportLoadRate: undefined,
  transportLoadRateUnit: '',
  consumptionTransportTypeCode: '',
  consumption: undefined,
  consumptionUnit: '',
  radioButton1: '',
  radioButton2: '',

  numberOfPeople: undefined,
  numberOfPeopleUnit: '',
  numberOfNight: undefined,
  numberOfNightUnit: '',
  numberOfDay: undefined,
  numberOfDayUnit: '',
  businessTravelTypeCode: '',

  transportProduct: '',
  employeeNumberBasedTypeCode: '',
  nightStayTypeCode: '',

  fuelEconomyBasic: undefined,
  fuelEconomyBasicUnit: '',

  officeCityClassificationCode: '',

  buildingUseCode: '',
  distribution: undefined,
  distributionUnit: '',

  expectedLifetimeUsagePer: undefined,
  expectedLifetimeUsagePerUnit: '',
  saleCount: undefined,
  saleCountUnit: '',
  perLessonUsage: undefined,
  perLessonUsageUnit: '',
  capitalGoodsTypeCode: '',
  emissionLifetimeUse: undefined,
  greenHouseGasCode: '',
  businessDaysYear: undefined,
  expectedLifetimeUsage: undefined,
  expectedLifetimeUsagePercentage: undefined,
  unclearActivityVolume1: undefined,
  unclearActivityVolume2: undefined,
  unclearActivityVolume3: undefined,
  unclearActivityVolume4: undefined,
  unclearActivityVolume5: undefined,
  unclearActivityVolume6: undefined,
  unclearActivityVolume7: undefined,
  unclearActivityVolume8: undefined,
  unclearActivityVolume9: undefined,
  unclearActivityVolume10: undefined,
  unclearActivityVolume11: undefined,
  unclearActivityVolume12: undefined,
  unclearEmissionIntensity1: undefined,
  unclearEmissionIntensity2: undefined,
  unclearEmissionIntensity3: undefined,
  unclearEmissionIntensity4: undefined,
  unclearEmissionIntensity5: undefined,
  unclearEmissionIntensity6: undefined,
  emissionIntensityLocation: undefined,
  emissionVolumeLocation: '',
  emissionIntensityLocationUnit: '',
  emissionVolumeLocationUnit: '',
  fuelEfficiencyCategory: '',
  companyGasSelected: undefined,
  cityGasOptionSelected: undefined,
  heatSelected: undefined,
  useOfCertificate: undefined,
  certificateSelected: undefined,
  supplyCompanyNo: undefined,
  greenEnergyCertificateVolume: undefined,
  greenEnergyCertificateVolumeUnit: '',
};
const OFFSET_DEFAULT: OffsetFields = {
  offsetAmount: undefined,
  offsetAmountUnit: '',
  offsetCertificateDate: '',
  offsetTarget: '',
  projectName: '',
  creditType: '',
};

const UploadModal: React.FunctionComponent<UploadModalProps> = ({
  step1,
  offset,
  scope,
  open,
  setOpen,
  step1Options,
}) => {
  const {
    text: { C01, common, E0000, E0113, E0116, B01 },
  } = useContext(LanguageContext)!;

  const [modalStatus, setModalStatus] = useState<ModalStatus>({
    type: 'STEP_1',
  });

  const [selectionInfos, setSelectionInfos] = useState<SelectionInfo[]>([]);
  const [params, setParams] = useState<EmissionInputScope1Request>({});
  const [index, setIndex] = useState<number>(1);
  const [modalConfirmStatus, setModalConfirmStatus] = useState(false);

  const step2Schema = yup.object({
    activityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('activityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.activityVolume))
          .min(0, E0000(C01.label.activityVolume))
          .typeError(E0000(C01.label.activityVolume)),

      otherwise: (schema) => schema,
    }),
    greenEnergyCertificateVolume: yup.number().when({
      is: () => !isEmpty(validateByRange('greenEnergyCertificateVolume')),
      then: () =>
        validateByRange('greenEnergyCertificateVolume') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fillingVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('fillingVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.fillingVolume))
          .min(0, E0000(C01.label.fillingVolume))
          .typeError(E0000(C01.label.fillingVolume)),
      otherwise: (schema) => schema,
    }),
    recoveryVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('recoveryVolume'),
      then: (schema) =>
        schema
          .min(0, C01.message.recoveryVolumeFormat)
          .required(C01.message.recoveryVolume)
          .typeError(C01.message.recoveryVolumeFormat),
      otherwise: (schema) => schema,
    }),
    emissionVolume: yup.string().when({
      is: () => step2Methods.watch('emissionIntensityLocation') === undefined,
      then: (schema) =>
        schema
          .min(0, C01.message.emissionVolumeNegativeCreate)
          .required(E0000(common.label.emissions))
          .typeError(E0000(common.label.emissions)),
      otherwise: (schema) => schema,
    }),
    emissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('emissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.emissionIntensity))
          .min(0, E0000(C01.label.emissionIntensity))
          .typeError(E0000(C01.label.emissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('rawActivityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawActivityVolume))
          .min(0, E0000(C01.label.rawActivityVolume))
          .typeError(E0000(C01.label.rawActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('rawEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawEmissionIntensity))
          .min(0, E0000(C01.label.rawEmissionIntensity))
          .typeError(E0000(C01.label.rawEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawTransportActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('rawTransportActivityVolume'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.rawTransportActivityVolume))
          .required(E0000(C01.label.rawTransportActivityVolume))
          .typeError(E0000(C01.label.rawTransportActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawTransportEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('rawTransportEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawTransportEmissionIntensity))
          .min(0, E0000(C01.label.rawTransportEmissionIntensity))
          .typeError(E0000(C01.label.rawTransportEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    capitalWasteActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('capitalWasteActivityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteActivityVolume))
          .min(0, C01.message.capitalWasteActivityVolumeFormat)
          .typeError(C01.message.capitalWasteActivityVolumeFormat),
      otherwise: (schema) => schema,
    }),
    capitalWasteEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('capitalWasteEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteEmissionIntensity))
          .min(0, E0000(C01.label.capitalWasteEmissionIntensity))
          .typeError(E0000(C01.label.capitalWasteEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    transportCargoWeight: yup.number().when({
      is: () => preValidateInputInfoCondition('transportCargoWeight'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportCargoWeight))
          .min(0, E0000(C01.label.transportCargoWeight))
          .typeError(E0000(C01.label.transportCargoWeight)),
      otherwise: (schema) => schema,
    }),
    transportDistance: yup.number().when({
      is: () => preValidateInputInfoCondition('transportDistance'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportDistance))
          .min(0, E0000(C01.label.transportDistance))
          .typeError(E0000(C01.label.transportDistance)),
      otherwise: (schema) => schema,
    }),
    numberOfPeople: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfPeople'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.numberOfPeople))
          .required(E0000(C01.label.numberOfPeople))
          .typeError(E0000(C01.label.numberOfPeople)),
      otherwise: (schema) => schema,
    }),
    numberOfNight: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfNight'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfNight))
          .min(0, E0000(C01.label.numberOfNight))
          .typeError(E0000(C01.label.numberOfNight)),
      otherwise: (schema) => schema,
    }),
    numberOfDay: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfDay'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfDay))
          .min(0, E0000(C01.label.numberOfDay))
          .typeError(E0000(C01.label.numberOfDay)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePer: yup.number().when({
      is: () => preValidateInputInfoCondition('expectedLifetimeUsagePer'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePer))
          .required(E0000(C01.label.expectedLifetimeUsagePer))
          .typeError(E0000(C01.label.expectedLifetimeUsagePer)),
      otherwise: (schema) => schema,
    }),
    saleCount: yup.number().when({
      is: () => preValidateInputInfoCondition('saleCount'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.saleCount))
          .min(0, E0000(C01.label.saleCount))
          .typeError(E0000(C01.label.saleCount)),
      otherwise: (schema) => schema,
    }),
    perLessonUsage: yup.number().when({
      is: () => preValidateInputInfoCondition('perLessonUsage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.perLessonUsage))
          .required(E0000(C01.label.perLessonUsage))
          .typeError(E0000(C01.label.perLessonUsage)),
      otherwise: (schema) => schema,
    }),
    emissionLifetimeUse: yup.number().when({
      is: () => preValidateInputInfoCondition('emissionLifetimeUse'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.emissionLifetimeUse))
          .required(E0000(C01.label.emissionLifetimeUse))
          .typeError(E0000(C01.label.emissionLifetimeUse)),
      otherwise: (schema) => schema,
    }),
    distribution: yup.number().when({
      is: () => !isEmpty(validateByRange('distribution')),
      then: () =>
        validateByRange('distribution') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fuelEconomyBasic: yup.number().when({
      is: () => preValidateInputInfoCondition('fuelEconomyBasic'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.fuelEconomy))
          .required(E0000(C01.label.fuelEconomy))
          .typeError(E0000(C01.label.fuelEconomy)),
      otherwise: (schema) => schema,
    }),
    transportLoadRate: yup.number().when({
      is: () => preValidateInputInfoCondition('transportLoadRate'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.transportLoadRate))
          .required(E0000(C01.label.transportLoadRate))
          .typeError(E0000(C01.label.transportLoadRate)),
      otherwise: (schema) => schema,
    }),
    businessDaysYear: yup.number().when({
      is: () => preValidateInputInfoCondition('businessDaysYear'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.businessDaysYear))
          .required(E0000(C01.label.businessDaysYear))
          .typeError(E0000(C01.label.businessDaysYear)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsage: yup.number().when({
      is: () => preValidateInputInfoCondition('expectedLifetimeUsage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsage))
          .required(E0000(C01.label.expectedLifetimeUsage))
          .typeError(E0000(C01.label.expectedLifetimeUsage)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePercentage: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('expectedLifetimeUsagePercentage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePercentage))
          .required(E0000(C01.label.expectedLifetimeUsagePercentage))
          .typeError(E0000(C01.label.expectedLifetimeUsagePercentage)),
      otherwise: (schema) => schema,
    }),
    useEmissionCoefficient: yup.number().when({
      is: () => !isEmpty(validateByRange('useEmissionCoefficient')),
      then: () =>
        validateByRange('useEmissionCoefficient') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume1: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume1'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume1))
          .required(E0000(C01.label.unclearActivityVolume1))
          .typeError(E0000(C01.label.unclearActivityVolume1)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume2: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume2'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume2))
          .required(E0000(C01.label.unclearActivityVolume2))
          .typeError(E0000(C01.label.unclearActivityVolume2)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume3: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume3'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume3))
          .required(E0000(C01.label.unclearActivityVolume3))
          .typeError(E0000(C01.label.unclearActivityVolume3)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume4: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume4'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume4))
          .required(E0000(C01.label.unclearActivityVolume4))
          .typeError(E0000(C01.label.unclearActivityVolume4)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume5: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume5'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume5))
          .required(E0000(C01.label.unclearActivityVolume5))
          .typeError(E0000(C01.label.unclearActivityVolume5)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume6: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume6'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume6))
          .required(E0000(C01.label.unclearActivityVolume6))
          .typeError(E0000(C01.label.unclearActivityVolume6)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume7: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume7'),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume7Format)
          .required(C01.message.unclearActivityVolume7)
          .typeError(C01.message.unclearActivityVolume7Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume8: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume8'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume8))
          .required(E0000(C01.label.unclearActivityVolume8))
          .typeError(E0000(C01.label.unclearActivityVolume8)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume9: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume9'),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume9Format)
          .required(C01.message.unclearActivityVolume9)
          .typeError(C01.message.unclearActivityVolume9Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume10: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume10'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume10))
          .required(E0000(C01.label.unclearActivityVolume10))
          .typeError(E0000(C01.label.unclearActivityVolume10)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume11: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume11'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume11))
          .required(E0000(C01.label.unclearActivityVolume11))
          .typeError(E0000(C01.label.unclearActivityVolume11)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume12: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume12'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume12))
          .required(E0000(C01.label.unclearActivityVolume12))
          .typeError(E0000(C01.label.unclearActivityVolume12)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity1: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity1'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity1))
          .required(E0000(C01.label.unclearEmissionIntensity1))
          .typeError(E0000(C01.label.unclearEmissionIntensity1)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity2: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity2'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity2))
          .required(E0000(C01.label.unclearEmissionIntensity2))
          .typeError(E0000(C01.label.unclearEmissionIntensity2)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity3: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity3'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity3))
          .required(E0000(C01.label.unclearEmissionIntensity3))
          .typeError(E0000(C01.label.unclearEmissionIntensity3)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity4: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity4'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity4))
          .required(E0000(C01.label.unclearEmissionIntensity4))
          .typeError(E0000(C01.label.unclearEmissionIntensity4)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity5: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity5'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity5))
          .required(E0000(C01.label.unclearEmissionIntensity5))
          .typeError(E0000(C01.label.unclearEmissionIntensity5)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity6: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity6'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity6))
          .required(E0000(C01.label.unclearEmissionIntensity6))
          .typeError(E0000(C01.label.unclearEmissionIntensity6)),
      otherwise: (schema) => schema,
    }),
  });

  const offsetSchema = yup.object({
    offsetCertificateDate: yup
      .string()
      .required(E0000(C01.label.offsetCertificateDate))
      .typeError(E0000(C01.label.offsetCertificateDate)),
    offsetAmount: yup
      .number()
      .min(0, E0000(C01.label.offsetAmount))
      .required(E0000(C01.label.offsetAmount))
      .typeError(E0000(C01.label.offsetAmount)),
  });

  const disableOkButton = () => {
    if (modalStatus.type === 'STEP_1') return false;
    if (step1?.scopeCode === '4') return false;
    let disabled = false;
    const scopeTmp = mutateStep1.data?.result;
    const keyFormula = getFieldsFromFormula(scopeTmp?.formula ?? '');
    const keyEmission = Object.keys(emissions);
    keyEmission.forEach((key) => {
      if (
        key === 'emissionVolume' &&
        isEmpty(emissions?.emissionIntensityLocation?.toString()) &&
        isEmpty(emissions[key]?.toString())
      ) {
        disabled = true;
      }
      if (
        key === 'emissionVolumeLocation' &&
        emissions.scope2MethodSelected === 'ML0002' &&
        step1?.scopeCode === '2' &&
        isEmpty(emissions[key]?.toString())
      ) {
        disabled = true;
      }
      if (
        keyFormula.includes(key) &&
        isEmpty(emissions[key as keyof Step2Fields]?.toString())
      ) {
        disabled = true;
      }
    });
    if (!scopeTmp?.flowFinished) {
      disabled = true;
    }
    return disabled;
  };

  const navigate = useNavigate();

  const resetModalCancel = () => {
    setSelectionInfos([...[selectionInfos[0]]]);
    offsetMethods.reset(OFFSET_DEFAULT);
    step2Methods.reset(STEP2_DEFAULT_VALUE);
    setModalStatus({ type: 'STEP_1' });
    setParams({});
    setOpen?.(false);
  };

  const step1Methods = useForm<Step1Fields>({
    values: {
      baseId: step1?.baseId ?? '',
      scopeCode: step1?.scopeCode ?? '',
      targetFiscalYear: step1?.targetFiscalYear ?? '',
      targetYearMonth: step1?.targetYearMonth ?? '',
      categoryCode: '',
      greenHouseCode: 'CO2',
    },
  });

  const step2Methods = useForm<Step2Fields>({
    values: STEP2_DEFAULT_VALUE,
    resolver: yupResolver(step2Schema),
  });

  const offsetMethods = useForm<OffsetFields>({
    values: OFFSET_DEFAULT,
    resolver: yupResolver(offsetSchema),
  });

  const emissions: any = { ...step1Methods.watch(), ...step2Methods.watch() };
  const offsets = { ...step1Methods.watch(), ...offsetMethods.watch() };
  const mutateStep1 = useHgbMutationPost<
    EmissionInputScope1Response,
    EmissionInputScope1Request
  >(`/api/v1/emission-input/ocr/scope${step1?.scopeCode}`, {
    onSuccess: (data) => {
      setSelectionInfos((prev) => [
        ...prev,
        ...(data?.result?.nextStepSelectionInfos?.map((item) => ({
          ...item,
          index: index + 1,
        })) ?? []),
      ]);
      setIndex(index + 1);

      setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
    },
    isAlert: false,
  });

  const mutateScope = useHgbMutationPost(
    `/api/v1/emission-input/scope-${step1?.scopeCode}`,
    {
      onSuccess: () => {
        resetModalCancel();
        return navigate(routs_en['/data-entry'].link);
      },
    },
  );
  const mutateOffset = useHgbMutationPost(`/api/v1/offsets`, {
    onSuccess: () => {
      resetModalCancel();
      return navigate(routs_en['/data-entry'].link);
    },
  });

  const step1SubmitHandler = () => {
    if (!lciDBYearQuery.isLoading && !mutateStep1.isLoading) {
      lciDBYearQuery.refetch();
    }
  };

  const lciDBYearQuery = useHgbQuery<string, { lciDBYear: string }>(
    API_URLS.CHECK_LCIDB_YEAR_API_URL,
    {
      lciDBYear: step1Methods.watch('targetYearMonth'),
    },
    {
      enabled: false,
      isAlert: false,
      onSuccess: (data) => {
        if (isEmpty(data?.result)) {
          return;
        }
        const targetLciDbYear = data.result ?? '';
        const targetYearMonth = step1Methods.watch('targetYearMonth');
        if (step1?.scopeCode !== '4') {
          mutateStep1.mutate({ targetLciDbYear, targetYearMonth });
        } else {
          setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
        }
      },
    },
  );

  const flowInputDetails: FlowInputDetail[] = useMemo(() => {
    const flowInputs: FlowInputDetail[] =
      mutateStep1.data?.result?.flowInputDetails ?? [];
    const { activityVolume, activityVolumeUnit } = step2Methods.watch();
    if (flowInputs) {
      flowInputs
        .map((s) => s.inputGroup)
        .forEach((item1) =>
          item1.forEach((item) => {
            step2Methods.setValue(
              `${item.fieldTarget}Unit` as keyof Step2Fields,
              item.unit,
            );
            if (item.fieldTarget === 'activityVolume') {
              step2Methods.setValue('activityVolume', activityVolume);
            } else {
              step2Methods.setValue(
                item.fieldTarget as keyof Step2Fields,
                !isEmpty(item.value?.toString())
                  ? Number(item.value)
                  : undefined,
              );
            }
          }),
        );
      flowInputs
        .map((s) => s.intensity)
        .filter((k) => k)
        .forEach((item) => {
          step2Methods.setValue(
            `${item.fieldTarget}Unit` as keyof Step2Fields,
            item.unit,
          );
          step2Methods.setValue(
            item.fieldTarget as keyof Step2Fields,
            !isEmpty(item.value?.toString()) ? Number(item.value) : '',
          );
        });
    }
    return flowInputs;
  }, [mutateStep1.data?.result?.flowInputDetails, selectionInfos]);

  const flowResultItem: InputInfo | undefined = useMemo(() => {
    const flowItem: InputInfo | undefined =
      mutateStep1.data?.result?.flowResultItem ?? undefined;
    if (flowItem) {
      step2Methods.setValue(
        `${flowItem.fieldTarget}Unit` as keyof Step2Fields,
        flowItem.unit,
      );
      step2Methods.setValue(
        `${flowItem.fieldTarget}` as keyof Step2Fields,
        Number(flowItem.value) ?? null,
      );
    }
    return flowItem;
  }, [
    selectionInfos,
    flowInputDetails,
    mutateStep1.data?.result?.flowResultItem,
  ]);

  const preValidateInputInfoCondition = (name: keyof Step2Fields) => {
    const checkers: InputInfo[] = [];
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .map((input) =>
        input.forEach((rs) => {
          if (rs.fieldTarget === name) {
            checkers.push(rs);
          }
        }),
      );
    return checkers.length > 0;
  };

  const preValidateIntensityCondition = (name: keyof Step2Fields) => {
    const checkers: InputInfo[] = [];
    flowInputDetails
      .map((flow) => flow.intensity)
      .filter((s) => s)
      .forEach((intensity) => {
        if (intensity.disabled === false && intensity.fieldTarget === name) {
          checkers.push(intensity);
        }
      });
    return checkers.length > 0;
  };

  const validateByRange: any = (name: string) => {
    let validate: Partial<InputInfo> = {};
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .map((input) =>
        input.forEach((rs) => {
          if (rs.fieldTarget === name) {
            validate = { ...rs };
          }
        }),
      );
    if (validate && validate.disabled === false) {
      if (validate.fieldTarget === 'greenEnergyCertificateVolume') {
        return yup
          .number()
          .required(E0000(C01.label.greenEnergyCertificateVolume))
          .min(0, E0000(C01.label.greenEnergyCertificateVolume))
          .typeError(E0000(C01.label.greenEnergyCertificateVolume))
          .lessThan(
            Number(step2Methods.getValues('activityVolume')) + 0.000000000001,
            E0113,
          )
          .test(
            'len',
            E0116,
            (val: any) => Number(val) <= (validate.constraints?.MAX_VALUE ?? 0),
          );
      }
      return yup
        .number()
        .min(
          validate.constraints?.MIN_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .max(
          validate.constraints?.MAX_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .required(E0000(C01.label.fuelUsagePerUse))
        .typeError(E0000(C01.label.fuelUsagePerUse));
    }
    return {};
  };

  const step2SubmitErrorHandler: SubmitErrorHandler<Step2Fields> = (error) => {
    debug.error('error', error);
  };

  useEffect(() => {
    const {
      emissionIntensityUnit,
      activityVolume,
      emissionIntensityLocationUnit,
    } = step2Methods.watch();
    if (
      !isEmpty(emissionIntensityUnit) ||
      !isEmpty(emissionIntensityLocationUnit)
    ) {
      if (isUndefined(activityVolume)) {
        step2Methods.setValue('activityVolume', scope?.activityVolume);
        step2Methods.setValue('activityVolumeUnit', scope?.activityVolumeUnit);
      }
    }
  }, [emissions, scope]);

  useEffect(() => {
    if (!isEmpty(offset)) {
      offsetMethods.setValue('offsetAmount', offset.offsetAmount);
      offsetMethods.setValue('offsetAmountUnit', offset.offsetAmountUnit ?? '');
      offsetMethods.setValue(
        'offsetCertificateDate',
        offset.offsetCertificateDate ?? '',
      );
    }
  }, [offset]);

  const resetObjectBeforeSubmit = (formula?: string) => {
    const keys = Object.keys(emissions);
    let emission = { ...emissions };
    keys.forEach((item) => {
      if (
        !(formula ?? '').includes(item) &&
        item !==
          (emission?.emissionIntensityLocation
            ? 'emissionVolumeLocation'
            : 'emissionVolume') &&
        typeof emission[item as keyof Emission] === 'number'
      ) {
        emission = { ...emission, [item]: null };
        emission = {
          ...emission,
          [`${item}Unit` as keyof Emission]: null,
        };
      }
      if (
        item ===
        (emission?.emissionIntensityLocation
          ? 'emissionVolumeLocation'
          : 'emissionVolume')
      ) {
        const volume = floorNumber(
          Number(
            emission?.emissionIntensityLocation
              ? emission?.emissionVolumeLocation
              : emission?.emissionVolume,
          ) ?? '0',
          3,
        );
        if (emission?.emissionIntensityLocation) {
          emission = {
            ...emission,
            emissionVolumeLocation: volume.toString(),
          };
        }
        if (!emission?.emissionIntensityLocation) {
          emission = {
            ...emission,
            emissionVolume: volume.toString(),
          };
        }
      }
    });
    step2Methods.reset({ ...emission });
    return emission as Emission;
  };

  const step2SubmitHandler = () => {
    const categoryCode =
      emissions.scopeCode !== '3' ? '00' : emissions.categoryCode;
    if (isEmpty(step2Methods.formState.errors) && !mutateScope.isLoading) {
      const emission = resetObjectBeforeSubmit(
        mutateStep1.data?.result?.formula ?? '',
      );
      mutateScope.mutate({
        ...emission,
        categoryCode: categoryCode,
        insertMethodCode: 2,
        greenHouseCode: 'CO2',
      });
    }
  };
  const offsetSubmitHandler = () => {
    mutateOffset.mutate({
      ...offsets,
      insertMethodCode: 2,
      greenHouseCode: 'CO2',
    });
  };

  useEffect(() => {
    selectionInfos.forEach((item) => {
      if (!isEmpty(item?.selectedValue)) {
        step2Methods.setValue(
          item.fieldTarget as keyof Step2Fields,
          item.selectedValue ?? '',
        );
        handleChangeScope1Params(
          item.fieldTarget ?? '',
          item?.selectedValue ?? '',
        );
      }
    });
  }, [selectionInfos]);

  useEffect(() => {
    if (
      (flowInputDetails?.[0]?.inputGroup || []).some(
        (item) => item.fieldTarget === 'greenEnergyCertificateVolume',
      ) &&
      [null, undefined, ''].includes(emissions.greenEnergyCertificateVolume)
    ) {
      step2Methods.setValue('emissionVolume', '');
    }
  }, [
    emissions.greenEnergyCertificateVolume,
    JSON.stringify(flowInputDetails),
  ]);

  const handleChangeScope1Params = (name: string, value: string) => {
    let newParams = Object.assign({});
    newParams = {
      ...newParams,
      targetLciDbYear: lciDBYearQuery.data?.result,
      targetYearMonth: step1Methods.watch('targetYearMonth'),
    };
    // prepare new array for selects
    let tmpSelects: SelectionInfo[] = [];
    step2Methods.setValue(name as keyof Step2Fields, value);

    // find index of current changed select
    const indexOfChangeField = selectionInfos.find(
      (item: SelectionInfo) => item.fieldTarget === name,
    )?.index;
    let resetObject = { ...STEP2_DEFAULT_VALUE };
    tmpSelects = selectionInfos.map((item) => {
      if (!isEmpty(item?.selectedValue)) {
        step2Methods.setValue(
          item.fieldTarget as keyof Step2Fields,
          item?.selectedValue ?? '',
        );
        resetObject = {
          ...resetObject,
          [item.fieldTarget]: item?.selectedValue,
        };
        newParams = {
          ...newParams,
          [item.fieldTarget]: item?.selectedValue,
        };
        item.selectedValue = null;
      }
      return item;
    });
    // get select has index <= indexOfChangeField
    tmpSelects = tmpSelects.filter((item) => {
      if (Number(item?.index) <= Number(indexOfChangeField)) {
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget ? params[item.fieldTarget] : value,
        };
        return item;
      }
    });

    // render new array select
    setSelectionInfos(tmpSelects);

    // because just cleared item in select array, need to reset index equal current maximum index
    setIndex(Number(indexOfChangeField ?? 0 + 1) ?? 0);

    resetObject = {
      ...resetObject,
      ...newParams,
      activityName: emissions.activityName,
      activityVolume: emissions.activityVolume,
    };

    step2Methods.reset({ ...emissions, ...resetObject });

    mutateStep1.mutate(newParams);

    setParams(newParams);
  };

  const typeOfInput = (unit: string) => {
    const listUnitInteger = ['人', '日', '泊'];
    return listUnitInteger.includes(unit)
      ? 'positiveInteger'
      : 'positiveDecimal';
  };

  const unitByRange = (intensity: InputInfo) => {
    if (!isEmpty(intensity.constraints)) {
      return `${intensity.constraints.MIN_VALUE}-${intensity.constraints.MAX_VALUE}`;
    }
    return '';
  };

  useEffect(() => {
    if (
      mutateStep1.data?.result?.flowFinished &&
      !isEmpty(mutateStep1.data.result.formula ?? '')
    ) {
      let emissionsCalculation = { ...emissions };
      const emissionKeys = Object.keys(emissions);
      const keysFromFormula = getFieldsFromFormula(
        mutateStep1.data.result?.formula ?? '',
      );
      emissionKeys.forEach((item) => {
        if (
          keysFromFormula.includes(item) &&
          !isEmpty(emissions[item as keyof Step2Fields])
        ) {
          emissionsCalculation = {
            ...emissionsCalculation,
            [item]: Number(emissions[item as keyof Step2Fields]),
          };
        }
      });

      try {
        if (!isEmpty(mutateStep1.data?.result?.formula)) {
          let value = getResultFromFormula(
            { ...step1Methods.getValues(), ...step2Methods.getValues() },
            mutateStep1.data?.result?.formula,
          );
          if (value.toString() === 'Infinity') {
            value = '';
          }
          step2Methods.setValue(
            emissions.emissionIntensityLocation
              ? 'emissionVolumeLocation'
              : 'emissionVolume',
            isNaN(Number(value)) ? '' : toBigDecimal(value, 20),
          );
        }
      } catch (error) {
        debug.log(error);
      }
    }
  }, [
    mutateStep1.data?.result?.flowFinished,
    mutateStep1.data?.result?.formula,
    emissions.activityVolume,
    emissions.emissionIntensity,
    emissions.gwp,
    emissions.fillingVolume,
    emissions.recoveryVolume,
    emissions.useEmissionCoefficient,
    emissions.operatorGraspRate,
    emissions.rawActivityVolume,
    emissions.rawEmissionIntensity,
    emissions.rawTransportActivityVolume,
    emissions.rawTransportEmissionIntensity,
    emissions.capitalWasteActivityVolume,
    emissions.capitalWasteEmissionIntensity,
    emissions.transportCargoWeight,
    emissions.transportDistance,
    emissions.numberOfPeople,
    emissions.numberOfNight,
    emissions.numberOfDay,
    emissions.expectedLifetimeUsagePer,
    emissions.saleCount,
    emissions.perLessonUsage,
    emissions.emissionLifetimeUse,
    emissions.distribution,
    emissions.fuelEconomyBasic,
    emissions.transportLoadRate,
    emissions.businessDaysYear,
    emissions.expectedLifetimeUsage,
    emissions.expectedLifetimeUsagePercentage,
    emissions.consumption,
    emissions.emissionIntensityLocation,
    emissions.activityVolumeUnit,
    emissions.greenEnergyCertificateVolume,
  ]);

  return (
    <>
      {!modalConfirmStatus && (
        <HgbAntdModal
          maskClosable={false}
          enterForSubmit={false}
          title={C01.modal.emissionCreateTitle}
          onBack={
            modalStatus.type === 'STEP_1'
              ? undefined
              : () => {
                  setParams({});
                  step2Methods.reset(STEP2_DEFAULT_VALUE);
                  setSelectionInfos([]);
                  setModalStatus((prev) => ({ ...prev, type: 'STEP_1' }));
                }
          }
          formProps={{
            onSubmit:
              modalStatus.type === 'STEP_1'
                ? step1Methods.handleSubmit(step1SubmitHandler)
                : emissions.scopeCode === '4'
                ? offsetMethods.handleSubmit(() => {
                    setModalConfirmStatus(true);
                  }, step2SubmitErrorHandler)
                : step2Methods.handleSubmit(() => {
                    setModalConfirmStatus(true);
                  }, step2SubmitErrorHandler),
          }}
          cancelText={common.button.cancel}
          okText={
            modalStatus.type === 'STEP_1'
              ? common.button.next
              : common.button.add
          }
          open={open}
          disableOk={disableOkButton()}
          onCancel={resetModalCancel}
        >
          <div className="tw-grid tw-grid-cols-2 tw-gap-24">
            {/* step 1 */}
            <FormProvider {...step1Methods}>
              {modalStatus.type === 'STEP_1' && (
                <>
                  <HgbSelect
                    {...step1Methods.register('targetFiscalYear')}
                    label={C01.label.fiscalYear}
                    required
                    disabled
                    options={(
                      step1Options?.targetFiscalYearOptions ?? []
                    ).filter((word) => Number(word.value) > 2018)}
                  />
                  <HgbSelect
                    {...step1Methods.register('targetYearMonth')}
                    label={C01.label.targetYearMonth}
                    required
                    disabled
                    options={step1Options?.targetYearMonthOptions ?? []}
                  />
                  <HgbTreeSelect
                    {...step1Methods.register('baseId')}
                    placeholder={C01.placeholder.baseId}
                    label={C01.label.baseId}
                    className="tw-col-span-2"
                    required
                    treeData={step1Options?.baseOptions ?? []}
                    disabled
                  />
                  <HgbSelect
                    {...step1Methods.register('greenHouseCode')}
                    className="tw-col-span-2"
                    options={[
                      { label: B01.label.energyOriginCO2, value: 'CO2' },
                    ]}
                    label={C01.label.greenHouseGases}
                    placeholder={C01.placeholder.categoryCode}
                    required
                    disabled
                  />
                  <HgbSelect
                    {...step1Methods.register('scopeCode')}
                    required
                    className="tw-col-span-2"
                    label={C01.label.scopeCode}
                    disabled
                    options={step1Options?.scopeOptions ?? []}
                  />
                </>
              )}
            </FormProvider>
            {/* step 2 */}
            {step1?.scopeCode !== '4' && modalStatus.type === 'STEP_2' && (
              <FormProvider {...step2Methods}>
                <>
                  <div className="tw-col-span-2">
                    <h4 className="tw-mb-8 tw-font-bold">
                      {C01.label.dataEntry}
                    </h4>
                    <small className="tw-text-error-7">
                      {parse(C01.message.c02NoticeScope)}
                    </small>
                  </div>
                  {selectionInfos &&
                    selectionInfos
                      ?.filter((item) => item.data)
                      .map((selectionInfo) =>
                        selectionInfo.type === 'LIST_BOX' ? (
                          <HgbSelect
                            key={selectionInfo.fieldTarget}
                            className="tw-col-span-2"
                            options={parseToHgbSelectOptions(
                              selectionInfo.data,
                            )}
                            required
                            label={selectionInfo.name}
                            value={step2Methods.watch([
                              selectionInfo.fieldTarget as keyof Step2Fields,
                            ])}
                            name={
                              selectionInfo.fieldTarget as keyof Step2Fields
                            }
                            onChangeValue={handleChangeScope1Params}
                            disabled={selectionInfo.disabled}
                          />
                        ) : (
                          <HgbAntdRadioGroup
                            // key={selectionInfo.fieldTarget}
                            className="tw-col-span-2"
                            options={parseToHgbSelectOptions(
                              selectionInfo.data,
                            )}
                            label={selectionInfo.name}
                            name={selectionInfo.fieldTarget}
                            onChangeValue={(e) =>
                              handleChangeScope1Params(
                                e.target.name ?? '',
                                e.target.value,
                              )
                            }
                            info={
                              selectionInfo.fieldTarget ===
                              'scope2MethodSelected'
                                ? '/support/manual/' +
                                  MANUAL_NAMEDDESTS.about_market_location_base
                                : ''
                            }
                            direction={
                              selectionInfo.fieldTarget ===
                                'cityGasOptionSelected' ||
                              selectionInfo.fieldTarget === 'heatSelected'
                                ? 'vertical'
                                : 'horizontal'
                            }
                          />
                        ),
                      )}
                  {flowInputDetails.map(({ inputGroup, intensity }) => {
                    return (
                      <>
                        {inputGroup.map((input) => {
                          return (
                            <HgbAntdInput
                              // key={input.fieldTarget}
                              required
                              className="tw-col-span-2"
                              label={input.name}
                              suffix={input.unit}
                              subLabel={unitByRange(input).replaceAll(
                                'null',
                                '',
                              )}
                              disabled={input.disabled}
                              type={typeOfInput(input.unit)}
                              minValue={
                                input.constraints?.MIN_VALUE ?? undefined
                              }
                              maxValue={
                                input.fieldTarget !==
                                'greenEnergyCertificateVolume'
                                  ? input.constraints?.MAX_VALUE
                                  : undefined
                              }
                              maxLength={input.maxLength}
                              value={step2Methods.watch(
                                input.fieldTarget as keyof Step2Fields,
                              )}
                              {...step2Methods.register(
                                input.fieldTarget as keyof Step2Fields,
                              )}
                              decimalLength={
                                input.constraints?.DECIMAL_LENGTH ?? undefined
                              }
                            />
                          );
                        })}
                        {intensity && (
                          <HgbAntdInput
                            required
                            // key={intensity.fieldTarget}
                            className="tw-col-span-2"
                            label={intensity.name}
                            suffix={(intensity.unit ?? '').replaceAll(
                              'null',
                              '',
                            )}
                            subLabel={unitByRange(intensity).replaceAll(
                              'null',
                              '',
                            )}
                            disabled={intensity.disabled}
                            maxLength={intensity.maxLength}
                            name={intensity.fieldTarget}
                            type={typeOfInput(intensity.unit)}
                            minValue={
                              intensity.constraints?.MIN_VALUE ?? undefined
                            }
                            maxValue={
                              intensity.constraints?.MAX_VALUE ?? undefined
                            }
                            value={step2Methods.watch(
                              intensity.fieldTarget as keyof Step2Fields,
                            )}
                            decimalLength={
                              intensity.constraints?.DECIMAL_LENGTH ?? undefined
                            }
                          />
                        )}
                      </>
                    );
                  })}
                  {flowResultItem && (
                    <HgbAntdInput
                      required
                      // key={flowResultItem.fieldTarget}
                      className="tw-col-span-2"
                      name={flowResultItem.fieldTarget}
                      label={flowResultItem.name}
                      suffix={(flowResultItem.unit ?? '').replaceAll(
                        'null',
                        '',
                      )}
                      subLabel={unitByRange(flowResultItem).replaceAll(
                        'null',
                        '',
                      )}
                      disabled={flowResultItem.disabled}
                      maxLength={flowResultItem.maxLength}
                      type={'positiveDecimal'}
                      value={
                        isEmpty(emissions.emissionVolume)
                          ? emissions.emissionVolumeLocation
                          : emissions.emissionVolume
                      }
                      minValue={
                        flowResultItem.constraints?.MIN_VALUE ?? undefined
                      }
                      maxValue={
                        flowResultItem.constraints?.MAX_VALUE ?? undefined
                      }
                      decimalLength={
                        flowResultItem.constraints?.DECIMAL_LENGTH ?? undefined
                      }
                    />
                  )}
                  <HgbAntdInput
                    {...step2Methods.register('activityName')}
                    label={C01.label.note}
                    placeholder={C01.placeholder.activityName}
                    className="tw-col-span-2"
                    maxLength={200}
                  />
                </>
              </FormProvider>
            )}
            {step1?.scopeCode === '4' && modalStatus.type === 'STEP_2' && (
              <FormProvider {...offsetMethods}>
                <>
                  <div className="tw-col-span-2">
                    <h4 className="tw-mb-8 tw-font-bold">
                      {C01.label.dataEntry}
                    </h4>
                    <small className="tw-text-error-7">
                      {parse(C01.message.c02NoticeOffset)}
                    </small>
                  </div>
                  <HgbAntdInput
                    {...offsetMethods.register('offsetAmount')}
                    label={C01.label.offsetAmount}
                    placeholder={C01.placeholder.offsetAmount}
                    className="tw-col-span-2"
                    type="positiveDecimal"
                    value={offsetMethods.watch('offsetAmount')}
                    maxLength={13}
                    required
                  />
                  <HgbSelect
                    {...offsetMethods.register('offsetAmountUnit')}
                    className="tw-col-span-2"
                    required
                    autoInitValue
                    options={[{ label: 'tCO2', value: 'tCO2' }]}
                    label={C01.label.offsetAmountUnit}
                    placeholder={C01.placeholder.offsetAmountUnit}
                    value={offsetMethods.watch('offsetAmountUnit')}
                    disabled
                  />
                  <HgbDatePicker
                    {...offsetMethods.register('offsetCertificateDate')}
                    className="tw-col-span-2"
                    required
                    format="YYYY/MM/DD"
                    picker="date"
                    label={C01.label.offsetCertificateDate}
                    placeholder={C01.placeholder.offsetCertificateDate}
                    defaultValue={offsetMethods.watch('offsetCertificateDate')}
                  />
                  <HgbAntdInput
                    {...offsetMethods.register('offsetTarget')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.offsetTarget + common.label.optional}
                    placeholder={C01.placeholder.offsetTarget}
                  />
                  <HgbAntdInput
                    {...offsetMethods.register('projectName')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.projectName + common.label.optional}
                    placeholder={C01.placeholder.projectName}
                  />
                  <HgbAntdInput
                    {...offsetMethods.register('creditType')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.creditType + common.label.optional}
                    placeholder={C01.placeholder.creditType}
                  />
                </>
              </FormProvider>
            )}
          </div>
        </HgbAntdModal>
      )}

      <HgbAntdModal
        open={modalConfirmStatus}
        okText={common.button.add}
        cancelText={common.button.cancel}
        title={C01.modal.emissionCreateConfirmTitle}
        formProps={{
          onSubmit:
            emissions.scopeCode === '4'
              ? offsetSubmitHandler
              : step2SubmitHandler,
        }}
        onCancel={() => {
          setModalConfirmStatus(false);
        }}
      >
        <div>{C01.message.emissionCreateConfirm}</div>
      </HgbAntdModal>
    </>
  );
};

export default UploadModal;
