import { DivProps } from '@common/types';
import * as React from 'react';

interface DataTableTemplateProps extends Omit<DivProps, 'title'> {
  buttonComponent?: React.ReactNode;
  inputsComponent?: React.ReactNode;
  tableComponent?: React.ReactNode;
  paginationComponents?: React.ReactNode;
  title?: React.ReactNode;
}
export const DataTableTemplate = React.forwardRef<
  HTMLDivElement,
  DataTableTemplateProps
>(
  (
    {
      className = '',
      buttonComponent,
      inputsComponent,
      tableComponent,
      paginationComponents,
      children,
      title,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={`tw-grid tw-h-full DataTableTemplate
        ${
          !!inputsComponent
            ? 'tw-grid-rows-[auto_auto_1fr_auto]'
            : 'tw-grid-rows-[auto_1fr_auto]'
        } tw-gap-16 tw-rounded-16 tw-bg-white tw-p-16 pc:tw-gap-24 pc:tw-px-24 large:tw-px-40 ${className}`}
        {...props}
      >
        {!!title && (
          <div>
            <>
              <div className="tw-flex tw-flex-auto tw-flex-wrap tw-items-center pc:tw-flex-nowrap">
                <h1 className="tw-mr-24 tw-w-fit tw-break-words tw-font-bold">
                  {title}
                </h1>
                {buttonComponent && (
                  <div className="tw-flex-auto">{buttonComponent}</div>
                )}
              </div>
              <div className="tw-mt-4 tw-h-1 tw-bg-neutral-1 pc:tw-mt-8" />
            </>
          </div>
        )}
        {inputsComponent}
        <div
          className="
          tw-relative tw-grid tw-grid-cols-[minmax(0,_1fr)]
         [&_.ant-spin-container]:tw-h-full
         [&_.ant-spin-nested-loading]:tw-h-full
         [&_.ant-spin]:tw-absolute
         [&_.ant-spin]:tw-left-1/2
         [&_.ant-spin]:tw-top-1/2
         [&_.ant-spin]:-tw-translate-x-1/2 
         [&_.ant-spin]:-tw-translate-y-1/2 
         [&_.ant-table-container]:tw-h-full 
         [&_.ant-table-content]:tw-h-full 
         [&_.ant-table-wrapper]:tw-h-full 
         [&_.ant-table]:tw-h-full
         [&_.hgb-ant-empty]:tw-shadow
         "
        >
          {children}
        </div>
        {paginationComponents}
      </div>
    );
  },
);
