import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbResponsive } from '@common/components';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import {
  HgbAntdInput,
  HgbSelect,
  parseToHgbSelectOptions
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { joinSafe } from '@utils/text';
import { Spin } from 'antd';
import Table from 'antd/es/table';
import type { ColumnType } from 'antd/lib/table';
import { isEmpty, toNumber } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useHgbMutationDelete,
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery, useHgbQueryWithPaging } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  CreateEmissionRequest,
  CreateEmissionResponse,
  DeleteEmissionRequest,
  DeleteEmissionResponse,
  Emission,
  GetEmissionRequest,
  UpdateEmissionRequest,
  UpdateEmissionResponse,
} from 'services/types/emission';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';
import {
  GetScopeListBoxRequest,
  GetScopeListBoxResponse,
} from 'services/types/scope';
import {
  GetEmissionYearListBoxRequest,
  GetEmissionYearListBoxResponse,
} from 'services/types/year';
import { v4 } from 'uuid';
import * as yup from 'yup';

const EMISSION_TARGET_FORM_DEFAULT_VALUE: Partial<Emission> = {
  id: -1,
  year: '',
  target: undefined,
  scopeCode: '',
  scopeName: '',
};

const EMISSION_TARGET_SEARCH_FORM_DEFAULT_VALUE: GetEmissionRequest = {
  pageNo: 1,
  pageSize: 10,
  sortBy: 'year',
  sortName: 'desc',
  year: '',
  enterpriseId: '',
  bankId: '',
};

export const D06: React.FC = () => {
  const {
    text: { D06, common },
    language,
  } = React.useContext(LanguageContext)!;

  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const { user } = useContext(AuthContext)!;

  const userRole = user.role;
  const searchMethods = useForm<GetEmissionRequest>({
    values: EMISSION_TARGET_SEARCH_FORM_DEFAULT_VALUE,
  });

  const EmissionSchema = yup.object({
    year: yup.string().required(D06.message.year),
    target: yup.string().required(D06.message.target),
    scopeCode: yup.string().required(D06.message.scopeCode),
  });

  const updateMethods = useForm<Emission>({
    resolver: yupResolver(EmissionSchema),
    defaultValues: EMISSION_TARGET_FORM_DEFAULT_VALUE,
  });

  const { watch: watchGet, register: registerSearch } = searchMethods;
  const requestSearch = watchGet();
  const {
    register,
    handleSubmit: handleSubmitUpdate,
    watch: watchUpdate,
    reset: resetUpdate,
  } = updateMethods;

  const isHighRole = useMemo(
    () => user.isManagementBank || user.isPresidingBank,
    [userRole],
  );
  const { ...currentEmission } = watchUpdate();

  const onSubmit = () => {
    const { id, scopeCode, year, target } = currentEmission;
    const createPayload = { year, target, scopeCode };
    if (id === -1) {
      createEmissionMutate(createPayload);
    } else {
      editEmissionMutate({
        ...createPayload,
        id,
      });
    }
  };

  const { data: banksQuery, isFetchedAfterMount: banksFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      undefined,
      {
        enabled: user.isPresidingBank,
        queryKey: [API_URLS.BANK_LIST_BOX_API_URL, user.isPresidingBank],
      },
    );

  const { data: yearLBQuery, refetch: yearLBRefetch } = useHgbQuery<
    GetEmissionYearListBoxResponse,
    GetEmissionYearListBoxRequest
  >(
    API_URLS.EMISSION_TARGET_YEAR,
    {
      enterpriseId:
        requestSearch.enterpriseId === ''
          ? undefined
          : requestSearch.enterpriseId,
      isLoadAllOption: true,
    },
    {
      enabled: !(
        (user.isPresidingBank || user.isManagementBank) &&
        requestSearch.enterpriseId === ''
      ),
      queryKey: [requestSearch.enterpriseId, API_URLS.EMISSION_TARGET_YEAR],
    },
  );

  const {
    data: enterprisesQuery,
    isFetchedAfterMount: enterprisesFetchedAfterMount,
  } = useHgbQuery<GetEnterpriseListBoxResponse, GetEnterpriseListBoxRequest>(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    user.isManagementBank
      ? undefined
      : {
          bankId: requestSearch.bankId,
        },
    {
      enabled:
        user.isManagementBank ||
        (user.isPresidingBank && requestSearch.bankId !== ''),
      queryKey: [API_URLS.ENTERPRISE_LIST_BOX_API_URL, requestSearch.bankId],
    },
  );

  const { data: scopeQuery } = useHgbQuery<
    GetScopeListBoxResponse,
    GetScopeListBoxRequest
  >(API_URLS.SCOPE_LIST_BOX_API_URL);

  const [isEmissionsEmpty, setIsEmissionsEmpty] = useState(false);

  const {
    data: emissionsQuery,
    refetch: emissionsRefetch,
    isFetching: emissionsFetching,
  } = useHgbQueryWithPaging<Emission[], GetEmissionRequest>(
    API_URLS.EMISSION_TARGETS,
    {
      ...requestSearch,
      ...paginationProps,
      enterpriseId:
        user.isPresidingBank || user.isManagementBank
          ? requestSearch.enterpriseId
          : undefined,
    },
    {
      queryKey: [API_URLS.EMISSION_TARGETS, paginationProps, requestSearch],
      enabled: requestSearch.year !== '',
      onSuccess: (data) => {
        if (toNumber(data?.result?.totalRecord ?? 0) === 0) {
          setIsEmissionsEmpty(true);
        } else {
          setIsEmissionsEmpty(false);
        }
      },
    },
  );

  const refreshEmission = useCallback(() => {
    if (paginationProps.pageNo !== 1) {
      resetPaginations();
    } else {
      emissionsRefetch();
    }
  }, [paginationProps.pageNo, resetPaginations]);

  const { mutate: editEmissionMutate } = useHgbMutationPut<
    UpdateEmissionResponse,
    UpdateEmissionRequest
  >(API_URLS.EMISSION_TARGETS, {
    onSuccess: () => {
      setShowUpdateModal(false);
      refreshEmission();
    },
  });

  const { mutate: createEmissionMutate } = useHgbMutationPost<
    CreateEmissionResponse,
    CreateEmissionRequest
  >(API_URLS.EMISSION_TARGETS, {
    onSuccess: () => {
      setShowUpdateModal(false);
      refreshEmission();
      yearLBRefetch();
    },
  });

  const { mutate: deleteEmissionMutate } = useHgbMutationDelete<
    DeleteEmissionResponse,
    DeleteEmissionRequest
  >(API_URLS.EMISSION_TARGETS, {
    onSuccess: () => {
      setShowDeleteModal(false);
      refreshEmission();
    },
  });

  const handleSubmitDelete = () => {
    deleteEmissionMutate(currentEmission.id.toString());
  };

  const handleEdit = (item: Emission) => {
    resetUpdate({ ...item });
    setShowUpdateModal(true);
  };

  const handleDelete = (item: Emission) => {
    resetUpdate(item);
    setShowDeleteModal(true);
  };

  const columns: ColumnType<Emission>[] = useMemo(() => {
    return [
      {
        title: D06.label.year,
        dataIndex: '',
        key: v4(),
        fixed: 'left',
        width: 100,
        render: (item: Emission) => (
          <HgbCellLink
            onClick={() => {
              resetUpdate({ ...item });
              setShowViewModal(true);
            }}
          >
            <HgbCellTooltip>{item.year}</HgbCellTooltip>
          </HgbCellLink>
        ),
      },
      {
        title: common.label.scope,
        dataIndex: 'scopeName',
        key: v4(),
        width: 120,
        render: (text: string) => <HgbCellTooltip>{text}</HgbCellTooltip>,
      },
      {
        title: D06.label.target,
        dataIndex: 'target',
        key: v4(),
        width: 120,
        render: (text: string) => <HgbCellTooltip>{text} tCO2</HgbCellTooltip>,
      },

      // {
      //   title: label.categoryCode,
      //   dataIndex: 'categoryName',
      //   key: v4(),
      //   width: 220,
      //   render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
      // },
      // {
      //   title: label.baseId,
      //   dataIndex: 'baseName',
      //   key: v4(),
      //   width: 220,
      //   render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
      // },
      ...(user.isEnterpriseAdmin
        ? [
            {
              title: common.label.action,
              align: 'center',
              dataIndex: '',
              key: v4(),
              width: 190,
              render: (item: Emission) => (
                <div className="tw-flex tw-justify-center tw-gap-24">
                  <HgbActionButton
                    onClick={() => handleEdit(item)}
                    disabled={isHighRole}
                    icon={<EditOutlined />}
                  >
                    {common.button.update}
                  </HgbActionButton>
                  <HgbActionButton
                    onClick={() => handleDelete(item)}
                    disabled={isHighRole}
                    icon={<DeleteOutlined />}
                  >
                    {common.button.delete}
                  </HgbActionButton>
                </div>
              ),
            } as any,
          ]
        : []),
    ];
  }, [user]);

  useEffect(() => {
    resetPaginations();
  }, [requestSearch.year, requestSearch.enterpriseId]);

  const emissions = emissionsQuery?.result?.content ?? [];
  const DataTable = useCallback(() => {
    return emissions.length > 0 ? (
      <Table
        columns={columns}
        dataSource={emissions}
        pagination={false}
        scroll={{
          x: columns.reduce((prev, cur) => prev + Number(cur?.width ?? 0), 0),
        }}
      />
    ) : isEmissionsEmpty ? (
      <HgbAntdEmpty description={common.message.noResult} />
    ) : null;
  }, [emissions, isEmissionsEmpty, language, columns]);

  const bankOptions = parseToHgbSelectOptions(banksQuery?.result);
  const enterpriseIdOptions = parseToHgbSelectOptions(enterprisesQuery?.result);
  const yearOptions = parseToHgbSelectOptions(yearLBQuery?.result);
  const scopeCodeOptions = (scopeQuery?.result ?? []).map((item) => ({
    label: joinSafe([item.name, item.note], ' : '),
    value: item.value,
  }));

  const errorMessage = useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(bankOptions)) {
        return banksFetchedAfterMount
          ? common.message.partnerBanksEmpty
          : undefined;
      }
      if (isEmpty(requestSearch.bankId)) {
        return common.message.choosePartnerBankAndCompany;
      }
      if (isEmpty(enterpriseIdOptions)) {
        return enterprisesFetchedAfterMount
          ? common.message.companyEmpty
          : undefined;
      }
      if (isEmpty(requestSearch.enterpriseId)) {
        return common.message.chooseCompany;
      }
    }
    if (user.isManagementBank) {
      if (isEmpty(enterpriseIdOptions)) {
        return enterprisesFetchedAfterMount
          ? common.message.companyEmpty
          : undefined;
      }
      if (isEmpty(requestSearch.enterpriseId)) {
        return common.message.chooseCompany;
      }
    }
    if (isEmpty(yearOptions)) {
      return common.message.noResult;
    }
  }, [
    user,
    bankOptions,
    requestSearch,
    yearOptions,
    enterpriseIdOptions,
    enterprisesFetchedAfterMount,
    banksFetchedAfterMount,
    language,
  ]);

  return (
    <ManagementTemplate>
      <FormProvider {...searchMethods}>
        <DataTableTemplate
          title={D06.pageTitle}
          paginationComponents={
            (emissionsQuery?.result?.totalRecord ?? 0) > 0 && (
              <PaginationComponent
                total={emissionsQuery?.result?.totalRecord}
                current={emissionsQuery?.result?.currentPage}
                pageSize={emissionsQuery?.result?.pageSize}
              />
            )
          }
          buttonComponent={
            user.isEnterpriseAdmin && (
              <HgbAntdButton
                type="primary"
                className="tw-shrink-0"
                icon={<PlusOutlined />}
                onClick={() => {
                  const year = new Date().getFullYear().toString();
                  resetUpdate({ ...EMISSION_TARGET_FORM_DEFAULT_VALUE, year });
                  setShowUpdateModal(true);
                }}
              >
                <>{common.button.register}</>
              </HgbAntdButton>
            )
          }
          inputsComponent={
            <HgbResponsive
              gap
              className="tw-grid tw-flex-auto tw-grid-cols-[repeat(auto-fill,minmax(220px,auto))] tw-items-end"
            >
              {user.isPresidingBank && (
                <HgbSelect
                  {...registerSearch('bankId')}
                  disabled={isEmpty(bankOptions)}
                  options={bankOptions}
                  placeholder={D06.placeholder.bankIdSearch}
                  label={common.placeholder.bankId}
                  showSearch
                />
              )}

              {(user.isPresidingBank || user.isManagementBank) && (
                <HgbSelect
                  {...registerSearch('enterpriseId')}
                  disabled={isEmpty(enterpriseIdOptions)}
                  options={enterpriseIdOptions}
                  placeholder={D06.placeholder.enterpriseIdSearch}
                  label={D06.label.enterpriseIdSearch}
                  showSearch
                />
              )}
              <HgbSelect
                {...registerSearch('year')}
                className="tw-w-fit"
                autoInitValue
                disabled={isEmpty(yearOptions)}
                options={yearOptions}
                placeholder={D06.placeholder.yearSearch}
                label={D06.label.yearSearch}
              />
            </HgbResponsive>
          }
        >
          {emissionsFetching ? (
            <Spin />
          ) : errorMessage ? (
            <HgbAntdEmpty description={errorMessage} />
          ) : (
            <DataTable />
          )}
        </DataTableTemplate>
      </FormProvider>
      <HgbAntdModal
        cancelText={common.button.cancel}
        okText={common.button.delete}
        open={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        title={D06.modal.emissionDeleteTitle}
        formProps={{
          onSubmit: handleSubmitDelete,
        }}
      >
        <p className="tw-text-center">
          {D06.message.deleteYearAlertSplit1}
          {currentEmission.year}
          {D06.message.deleteYearAlertSplit2}
        </p>
      </HgbAntdModal>
      <FormProvider {...updateMethods}>
        {showUpdateModal && (
          <HgbAntdModal
            cancelText={common.button.cancel}
            okText={
              currentEmission.id === -1
                ? common.button.add
                : common.button.change
            }
            open
            onCancel={() => {
              setShowUpdateModal(false);
            }}
            title={
              currentEmission.id === -1
                ? D06.modal.emissionAddTitle
                : D06.modal.emissionUpdateTitle
            }
            formProps={{
              onSubmit: handleSubmitUpdate(onSubmit),
            }}
          >
            <div className="tw-flex tw-flex-col tw-gap-24">
              <HgbAntdInput
                {...register('year')}
                maxLength={4}
                type="positiveInteger"
                required
                disabled={currentEmission.id !== -1}
                placeholder={D06.placeholder.year}
                label={D06.label.year}
              />
              <HgbSelect
                {...register('scopeCode')}
                required
                disabled={currentEmission.id !== -1}
                options={scopeCodeOptions}
                placeholder={D06.placeholder.scopeCode}
                label={common.label.scope}
              />
              <HgbAntdInput
                {...register('target')}
                maxLength={10}
                required
                type="positiveInteger"
                placeholder={D06.placeholder.target}
                label={D06.label.target}
              />
              {/* <HgbSelect
                {...register('categoryCode')}
                required
                disabled={
                  currentEmission.id !== -1 || !watchUpdate('scopeCode')
                }
                options={categoryListBox}
                placeholder={D06.placeholder.categoryCode}
                label={D06.label.categoryCode}
              />
              <HgbTreeSelect
                {...register('baseId')}
                required
                disabled={currentEmission.id !== -1}
                treeData={baseIdOptions}
                placeholder={D06.placeholder.baseId}
                label={D06.label.baseId}
              /> */}
            </div>
          </HgbAntdModal>
        )}

        {showViewModal && (
          <HgbAntdModal
            cancelText={common.button.cancel}
            open
            onCancel={() => {
              setShowViewModal(false);
            }}
            title={D06.modal.emissionViewTitle}
          >
            <div className="tw-flex tw-flex-col tw-gap-24">
              <HgbAntdInput
                {...register('year')}
                maxLength={4}
                type="positiveInteger"
                required
                disabled
                placeholder={D06.placeholder.year}
                label={D06.label.year}
              />
              <HgbSelect
                {...register('scopeCode')}
                disabled
                required
                options={scopeCodeOptions}
                placeholder={D06.placeholder.scopeCode}
                label={common.label.scope}
              />
              <HgbAntdInput
                {...register('target')}
                disabled
                required
                maxLength={10}
                type="positiveInteger"
                placeholder={D06.placeholder.target}
                label={D06.label.target}
              />

              {/* <HgbSelect
                {...register('categoryCode')}
                required
                disabled
                options={categoryListBox}
                placeholder={D06.placeholder.categoryCode}
                label={D06.label.categoryCode}
              />
              <HgbTreeSelect
                required
                disabled
                treeData={baseIdOptions}
                placeholder={D06.placeholder.baseId}
                label={D06.label.baseId}
                {...register('baseId')}
              /> */}
            </div>
          </HgbAntdModal>
        )}
      </FormProvider>
    </ManagementTemplate>
  );
};
