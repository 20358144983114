import { LanguageContext } from '@contexts/LanguageContext';
import { useMatchWidth } from '@hooks/useMatchWidth';
import { DataTableTemplate } from '@layouts/templates';
import { cn } from '@utils/cn';
import { toJPFormat } from '@utils/number';
import { sumOfObject } from '@utils/object';
import { Tabs } from 'antd';
import * as React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { CategoryItem } from './components/CategoryItem';
import { RecLegend } from './components/Legend';
import { PieBoundary } from './components/PieBoundary';
import { scope1Color, scope2Color, scope3Color, scope3Colors } from './const';
import {
  CategoryStatisticsDetails,
  FuelDashboardDisplayInfo,
  PieStatisticsChartDetails,
  PieStatisticsChartDetailsForScope1,
  PieStatisticsChartDetailsForSenvenTypeEmissonScope1,
  PieStatisticsChartDetailsForSenvenTypeEmissonScope3,
  SevenTypeEmissionDashboardDisplayInfos,
} from './types';

export const makeScope1Data = (
  fuelDashboardDisplayInfos: Partial<FuelDashboardDisplayInfo>[],
  pieStatisticsChartDetailsForScope1: Partial<PieStatisticsChartDetailsForScope1>,
  pieStatisticsChartDetailsForSenvenTypeEmisson: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope1>,
) => {
  const totalMissionCO2 = sumOfObject(pieStatisticsChartDetailsForScope1 || {});
  const totalNonMissionCO2 = sumOfObject(
    pieStatisticsChartDetailsForSenvenTypeEmisson || {},
  );

  return fuelDashboardDisplayInfos
    .map((item) => ({
      code: item?.fuelCode || '',
      name: item?.fuelName || '',
      color: item?.dashboardColorCode || '',
      dashboardDisplayOrder: item.dashboardDisplayOrder,
      unit: item?.unit,
      value:
        pieStatisticsChartDetailsForScope1?.[
        (item?.fuelCode || 'FUEL001') as 'FUEL001'
        ] || 0,
      percent: (
        (100 *
          (pieStatisticsChartDetailsForScope1?.[
            (item?.fuelCode || 'FUEL001') as 'FUEL001'
          ] || 0)) /
        (totalMissionCO2 + totalNonMissionCO2)
      ).toFixed(1),
    }))
    .filter((item) => item.value !== 0)
    .sort(
      (item1, item2) =>
        (item1.dashboardDisplayOrder || 0) - (item2.dashboardDisplayOrder || 0),
    );
};

export const makeScope3Data = (
  categoryStatisticsDetails: Partial<CategoryStatisticsDetails>,
  pieStatisticsChartDetailsForSenvenTypeEmisson: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope3>,
  B01: any,
) => {
  const totalMissionCO2 = sumOfObject(categoryStatisticsDetails['3'] || {});
  const totalNonMissionCO2 = sumOfObject(
    pieStatisticsChartDetailsForSenvenTypeEmisson['3'] || {},
  );
  return Object.keys(
    {
      ...categoryStatisticsDetails['3'],
      ...pieStatisticsChartDetailsForSenvenTypeEmisson['3'],
    },
  )
    .map((key) => ({
      code: key,
      name: key,
      color: scope3Colors[Number(key) - 1],
      additionValues: [
        {
          name: B01.label.energyOriginCO2,
          value:
            Number(categoryStatisticsDetails['3']?.[key as '01']?.toFixed(2)) ||
            0,
        },
        {
          name: B01.label.nonEnergyOriginCO2,
          value:
            Number(
              pieStatisticsChartDetailsForSenvenTypeEmisson['3']?.[
                key as '01'
              ]?.toFixed(2),
            ) || 0,
        },
      ],
      value: Number(
        (
          (categoryStatisticsDetails['3']?.[key as '01'] || 0) +
          (pieStatisticsChartDetailsForSenvenTypeEmisson['3']?.[key as '01'] ||
            0)
        ).toFixed(2),
      ),
      totalNonMissionCO2: totalNonMissionCO2,
      percent: (
        (100 *
          ((categoryStatisticsDetails['3']?.[key as '01'] || 0) +
            (pieStatisticsChartDetailsForSenvenTypeEmisson['3']?.[
              key as '01'
            ] || 0))) /
        (totalMissionCO2 + totalNonMissionCO2)
      ).toFixed(1),
    }))
    .sort((itemA, itemB) => Number(itemA.code) - Number(itemB.code));
};

export const makeSenvenTypeEmissonData = (
  pieStatisticsChartDetailsForSenvenTypeEmisson: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope1>,
  severTypeEmissionDashboardDisplayInfos: Partial<SevenTypeEmissionDashboardDisplayInfos>[],
  categoryStatisticsDetails: Partial<CategoryStatisticsDetails>,
  pieStatisticsChartDetailsForScope1: Partial<PieStatisticsChartDetailsForScope1>,
) => {
  const totalNonMissionCO2 = sumOfObject(
    pieStatisticsChartDetailsForSenvenTypeEmisson,
  );
  const totalMissionCO2 =
    sumOfObject(categoryStatisticsDetails['3'] || {}) ||
    sumOfObject(pieStatisticsChartDetailsForScope1 || {});
  return severTypeEmissionDashboardDisplayInfos
    .map((item) => ({
      code: item?.emissionCode || '',
      name: item?.emissionName || '',
      color: item?.dashboardColorCode || '',
      dashboardDisplayOrder: item.dashboardDisplayOrder,
      unit: item?.unit,
      value:
        pieStatisticsChartDetailsForSenvenTypeEmisson?.[
        (item?.emissionCode || 'GHG01') as 'GHG01'
        ] || 0,
      percent: (
        (100 *
          (pieStatisticsChartDetailsForSenvenTypeEmisson?.[
            (item?.emissionCode || 'GHG01') as 'GHG01'
          ] || 0)) /
        (totalMissionCO2 + totalNonMissionCO2)
      ).toFixed(1),
    }))
    .filter((item) => item.value !== 0)
    .sort(
      (item1, item2) =>
        (item1.dashboardDisplayOrder || 0) - (item2.dashboardDisplayOrder || 0),
    );
};

export const makeOveralData = (
  categoryStatisticsDetails: Partial<CategoryStatisticsDetails>,
  pieStatisticsChartDetails: Partial<PieStatisticsChartDetails>,
  pieStatisticsChartDetailsForScope1: Partial<PieStatisticsChartDetailsForScope1>,
  fuelDashboardDisplayInfos: Partial<FuelDashboardDisplayInfo>[],
  pieStatisticsChartDetailsForSenvenTypeEmissonScope1: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope1>,
  pieStatisticsChartDetailsForSenvenTypeEmissonScope3: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope3>,
  severTypeEmissionDashboardDisplayInfos: Partial<SevenTypeEmissionDashboardDisplayInfos>[],
  B01: any,
) => {
  const totalScope1 = pieStatisticsChartDetails?.['1'] || 0;
  const totalScope2 = pieStatisticsChartDetails?.['2'] || 0;
  const totalScope3 = pieStatisticsChartDetails?.['3'] || 0;

  const total = totalScope1 + totalScope2 + totalScope3;
  const percentScope1 = ((100 * totalScope1) / total).toFixed(1);
  const percentScope2 = ((100 * totalScope2) / total).toFixed(1);
  const percentScope3 = ((100 * totalScope3) / total).toFixed(1);
  return {
    scope1: {
      value: totalScope1,
      color: scope1Color,
      values: [
        ...makeScope1Data(
          fuelDashboardDisplayInfos,
          pieStatisticsChartDetailsForScope1,
          pieStatisticsChartDetailsForSenvenTypeEmissonScope1,
        ),
        ...makeSenvenTypeEmissonData(
          pieStatisticsChartDetailsForSenvenTypeEmissonScope1,
          severTypeEmissionDashboardDisplayInfos,
          {},
          pieStatisticsChartDetailsForScope1,
        ),
      ],
      percent: percentScope1,
    },
    scope2: {
      value: pieStatisticsChartDetails['2'] || 0,
      color: scope2Color,
      percent: percentScope2,
    },
    scope3: {
      value: totalScope3,
      color: scope3Color,
      values: [
        ...makeScope3Data(
          categoryStatisticsDetails,
          pieStatisticsChartDetailsForSenvenTypeEmissonScope3,
          B01,
        ),
      ],
      percent: percentScope3,
    },

    general: [
      {
        name: 'scope3',
        value: totalScope3,
        color: scope3Color,
        percent: percentScope3,
      },
      {
        name: 'scope2',
        value: totalScope2,
        color: scope2Color,
        percent: percentScope2,
      },
      {
        name: 'scope1',
        value: totalScope1,
        color: scope1Color,
        percent: percentScope1,
      },
    ],
  };
};

interface IScopesChartProps {
  pieStatisticsChartDetailsForScope1?: Partial<PieStatisticsChartDetailsForScope1>;
  fuelDashboardDisplayInfos?: Partial<FuelDashboardDisplayInfo>[];
  categoryStatisticsDetails?: Partial<CategoryStatisticsDetails>;
  pieStatisticsChartDetails?: Partial<PieStatisticsChartDetails>;
  pieStatisticsChartDetailsForSenvenTypeEmissonScope1?: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope1>;
  pieStatisticsChartDetailsForSenvenTypeEmissonScope3?: Partial<PieStatisticsChartDetailsForSenvenTypeEmissonScope3>;
  severTypeEmissionDashboardDisplayInfos?: Partial<SevenTypeEmissionDashboardDisplayInfos>[];
  companySupplier: string;
}

export const ScopesChart: React.FunctionComponent<IScopesChartProps> =
  React.memo(
    ({
      pieStatisticsChartDetailsForScope1 = {},
      fuelDashboardDisplayInfos,
      categoryStatisticsDetails = {},
      pieStatisticsChartDetails = {},
      pieStatisticsChartDetailsForSenvenTypeEmissonScope1 = {},
      pieStatisticsChartDetailsForSenvenTypeEmissonScope3 = {},
      severTypeEmissionDashboardDisplayInfos,
      companySupplier = '',
    }) => {
      const {
        text: { B01, common },
      } = React.useContext(LanguageContext)!;

      const [activeKey, setActiveKey] = React.useState<
        'scope-1' | 'scope-2' | 'scope-3'
      >(
        companySupplier === '-1' ||
          companySupplier === '0' ||
          companySupplier === ''
          ? 'scope-1'
          : 'scope-3',
      );

      const overalData = makeOveralData(
        categoryStatisticsDetails,
        pieStatisticsChartDetails,
        pieStatisticsChartDetailsForScope1,
        fuelDashboardDisplayInfos || [],
        pieStatisticsChartDetailsForSenvenTypeEmissonScope1,
        pieStatisticsChartDetailsForSenvenTypeEmissonScope3,
        severTypeEmissionDashboardDisplayInfos || [],
        B01,
      );

      const tabItems = [
        {
          name: common.label.scope1,
          value: overalData.scope1.value,
          percent: overalData.scope1.percent,
        },
        {
          name: common.label.scope2,
          value: overalData.scope2.value,
          percent: overalData.scope2.percent,
        },
        {
          name: common.label.scope3,
          value: overalData.scope3.value,
          percent: overalData.scope3.percent,
        },
      ].map((item, i) => {
        const id = String(i + 1);
        return {
          label: `${item.name}　${toJPFormat(item.value)} tCO2 (${item.percent
            }%)`,
          key: 'scope-' + id,
          children: null,
        };
      });
      const onChangeTab = (k: string) => setActiveKey(k as 'scope-1');
      const isLarge = useMatchWidth('greatThan', 1200);

      return (
        <DataTableTemplate
          className="tw-scroll-mt-64 tw-my-24"
          title={B01.label.breakdownEmission}
        >
          <div className="tw-flex tw-flex-col tw-gap-24 large:tw-flex-row">
            <div className="tw-flex tw-w-full tw-shrink-0 tw-flex-col tw-items-center tw-gap-16 tw-self-center tw-rounded-16 tw-border tw-border-neutral-2 tw-p-16 large:tw-w-fit large:tw-self-stretch">
              <PieChart width={200} height={200} className="-tw-rotate-90">
                <Pie
                  data={overalData.general}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  dataKey="value"
                  innerRadius={70}
                >
                  {overalData.general.map((item) => (
                    <Cell
                      key={`cell-${item.name}`}
                      style={{
                        fill: item.color,
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div className="tw-flex tw-flex-wrap tw-gap-16">
                <RecLegend
                  color={overalData.scope1.color}
                  label={common.label.scope1}
                  subLabel={`${overalData.scope1.percent}%`}
                />
                <RecLegend
                  color={overalData.scope2.color}
                  label={common.label.scope2}
                  subLabel={`${overalData.scope2.percent}%`}
                />
                <RecLegend
                  color={overalData.scope3.color}
                  label={common.label.scope3}
                  subLabel={`${overalData.scope3.percent}%`}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-auto tw-flex-col tw-items-start tw-gap-16 tw-rounded-16 tw-border tw-border-neutral-2 tw-p-16 large:tw-flex-row">
              <div className="tw-w-full tw-shrink-0 tw-overflow-x-auto tw-overflow-y-hidden large:tw-w-fit">
                <Tabs
                  tabPosition={isLarge ? 'left' : 'top'}
                  className="[&_.ant-tabs-tab-btn]:tw-font-bold"
                  items={tabItems}
                  activeKey={activeKey}
                  onChange={onChangeTab}
                />
              </div>
              <div
                className={cn(
                  'tw-flex tw-flex-auto tw-flex-col tw-gap-24 tw-self-stretch',
                  {
                    'tw-hidden': activeKey !== 'scope-1',
                  },
                )}
              >
                <PieBoundary
                  title={B01.label.detail}
                  description={B01.label.scope1Desc}
                  value={`${toJPFormat(overalData.scope1.value)} tCO2`}
                  subValue={`(100.0%)`}
                  pieName={common.label.scope1}
                >
                  <PieChart width={200} height={200} className="-tw-rotate-90">
                    <Pie
                      data={
                        overalData.scope1.values.length === 0
                          ? [{ name: 'Scope A', value: 1 }]
                          : overalData.scope1.values
                      }
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={100}
                      dataKey="value"
                      innerRadius={70}
                      style={
                        overalData.scope1.values.length === 0
                          ? { fill: scope1Color }
                          : undefined
                      }
                    >
                      {overalData?.scope1?.values.map((entry) => (
                        <Cell
                          key={`cell-${entry.code}`}
                          style={{
                            fill: entry.color,
                          }}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </PieBoundary>
                {overalData.scope1.values.filter(
                  (item) => !item.code.includes('GHG'),
                ).length !== 0 && (
                    <>
                      <h3 className="tw-left-0 tw-top-0 tw-border-b tw-border-neutral-2 tw-pb-16 tw-font-bold">
                        {B01.label.energyOriginCO2}
                      </h3>
                      <div
                        className="tw-grid tw-gap-24 tw-pb-8 tw-pr-8"
                        style={{
                          gridTemplateColumns:
                            'repeat( auto-fill, 300px)',
                        }}
                      >
                        {overalData.scope1.values
                          .filter((item) => !item.code.includes('GHG'))
                          .map((item) => (
                            <CategoryItem
                              key={'scope1' + item.code}
                              title={item.name}
                              value={`${item.value} tCO2`}
                              subValue={`(${item.percent}%)`}
                              color={item.color}
                            />
                          ))}
                      </div>
                    </>
                  )}

                {overalData.scope1.values.filter((item) =>
                  item.code.includes('GHG'),
                ).length !== 0 && (
                    <>
                      <h3 className="tw-left-0 tw-top-0 tw-border-b tw-border-neutral-2 tw-pb-16 tw-font-bold">
                        {B01.label.nonEnergyOriginCO2}
                      </h3>
                      <div
                        className="tw-grid tw-gap-24 tw-pb-8 tw-pr-8"
                        style={{
                          gridTemplateColumns:
                            'repeat( auto-fill, 300px)',
                        }}
                      >
                        {overalData.scope1.values
                          .filter((item) => item.code.includes('GHG'))
                          .map((item) => (
                            <CategoryItem
                              key={'scope1' + item.code}
                              title={item.name}
                              value={`${item.value} ${item.unit}`}
                              subValue={`(${item.percent}%)`}
                              color={item.color}
                            />
                          ))}
                      </div>
                    </>
                  )}
              </div>
              <div
                className={cn(
                  'tw-flex tw-flex-col tw-items-start tw-gap-24 tw-self-stretch',
                  {
                    'tw-hidden': activeKey !== 'scope-2',
                  },
                )}
              >
                <PieBoundary
                  title={B01.label.detail}
                  description={B01.label.scope2Desc}
                  value={`${toJPFormat(overalData.scope2.value)} tCO2`}
                  subValue={`(100.0%)`}
                  pieName={common.label.scope2}
                >
                  <PieChart width={200} height={200} className="-tw-rotate-90">
                    <Pie
                      data={[{ name: 'Scope A', value: 1 }]}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={100}
                      dataKey="value"
                      innerRadius={70}
                      style={{
                        fill: overalData.scope2.color,
                      }}
                    />
                  </PieChart>
                </PieBoundary>
              </div>
              <div
                className={cn(
                  'tw-flex tw-flex-auto tw-flex-col tw-gap-24 tw-self-stretch',
                  {
                    'tw-hidden': activeKey !== 'scope-3',
                  },
                )}
              >
                <PieBoundary
                  title={B01.label.detail}
                  description={B01.label.scope3Desc}
                  value={`${toJPFormat(overalData.scope3.value)} tCO2`}
                  subValue={`(100.0%)`}
                  pieName={common.label.scope3}
                >
                  <PieChart width={200} height={200} className="-tw-rotate-90">
                    <Pie
                      data={
                        overalData.scope3.values.length === 0
                          ? [{ name: 'Scope A', value: 1 }]
                          : overalData.scope3.values
                      }
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={100}
                      dataKey="value"
                      innerRadius={70}
                      style={
                        overalData.scope3.values.length === 0
                          ? { fill: scope3Color }
                          : undefined
                      }
                    >
                      {overalData.scope3.values.map((entry) => (
                        <Cell
                          key={`cell-${entry.code}`}
                          style={{
                            fill: entry.color,
                          }}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </PieBoundary>
                <div
                  className="tw-grid tw-gap-24 tw-pb-8 tw-pr-8"
                  style={{
                    gridTemplateColumns:
                      'repeat( auto-fill, 300px)',
                  }}
                >
                  {overalData.scope3.values.map((item) => (
                    <CategoryItem
                      key={'scope3' + item.code}
                      title={`${common.label.category}${Number(item.code)}`}
                      subTitle={B01.scope3Descs[item.code as '01']}
                      total={`${item.value} t-eqCO2`}
                      subValue={`(${item.percent}%)`}
                      color={item.color}
                      additionValues={item.additionValues}
                      totalNonMissionCO2={item.totalNonMissionCO2}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </DataTableTemplate>
      );
    },
  );
