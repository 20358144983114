import HgbInfoLink from '@common/contents/HgbInfoLink';
import { cn } from '@utils/cn';
import { forwardRef, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

export type HgbAntdRadioProps = {
  label: ReactNode;
  value: string;
  infoLink?: string;
  disabled?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const HgbAntdRadio = forwardRef(
  (
    { label, className, disabled, infoLink, ...props }: HgbAntdRadioProps,
    ref: any,
  ) => {
    const { watch } = useFormContext();
    return (
      <label
        className={cn(
          'tw-flex tw-w-fit tw-shrink-0 tw-cursor-pointer tw-gap-4 tw-text-paragraph tw-min-h-40 tw-pt-12',
          { 'tw-cursor-not-allowed tw-opacity-70': disabled },
          className,
        )}
      >
        <input ref={ref} type="radio" className="tw-hidden" {...props} />
        <div
          className={cn(
            'tw-mt-[2px] tw-h-16 tw-w-16 tw-shrink-0 tw-rounded-full tw-border',
            {
              'tw-border-[5px] tw-border-primary-5':
                props.value === watch(props?.name ?? 'no_value'),
            },
            { 'tw-pointer-events-none': disabled },
          )}
        ></div>
        <span className={cn({ 'tw-pointer-events-none': disabled })}>
          {label}
        </span>
        {infoLink && (
          <HgbInfoLink to={infoLink} target="_blank" className="tw-ml-4" />
        )}
      </label>
    );
  },
);
