import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Step1Fields } from '../type';
import { STEP1_DEFAULT_VALUE } from '../utils';

export const useStep1 = () => {
  const {
    text: { C01, common, E0000 },
  } = useContext(LanguageContext)!;
  const step1Schema = yup.object({
    targetFiscalYear: yup.string().required(E0000(C01.label.targetFiscalYear)),
    targetYearMonth: yup.string().required(E0000(C01.label.targetYearMonth)),
    baseId: yup.string().required(E0000(C01.label.baseId)),
    greenHouseCode: yup.string().required(E0000(C01.label.greenHouseGases)),
    scopeCode: yup.string().required(E0000(C01.label.scopeCode)),
    categoryCode: yup.string().when('scopeCode', {
      is: (value: string) => value === '3',
      then: (schema) => schema.required(E0000(common.label.category)),
      otherwise: (schema) => schema,
    }),
  });

  const step1Methods = useForm<Step1Fields>({
    values: STEP1_DEFAULT_VALUE,
    resolver: yupResolver(step1Schema),
  });

  return {
    step1Schema,
    step1Methods,
  };
};
