import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { HgbExpandMoreOutlinedIcon } from '@common/icons/outlined';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { hgbAxios } from '@utils/axios';
import { cn } from '@utils/cn';
import {
  currentYearMonth,
  getFiscalYearFromMonth,
  getFiscalYearFromMonthDirectInput,
} from '@utils/date.util';
import { debug } from '@utils/debug';
import { Modal, RadioChangeEvent } from 'antd';
import { LocalDataClass } from 'data-class/LocalDataClass';
import parse from 'html-react-parser';
import { debounce, isEmpty } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  EmissionOutput,
  OffsetDetails,
} from 'types/api/emission-output/emission-output.type';
import { useStep1 } from './hooks/useStep1';
import { useStep2 } from './hooks/useStep2';
import {
  EmissionInputScope1Response,
  FlowInputDetail,
  GreenGasHouseCode,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from './type';
import {
  checkInitValueSelectionInfo,
  createEmissionApiLink,
  createFlowApi,
  createMessageDataEntry,
  createNewParams,
  createSelectioInfoOptions,
  disableStep2Submit,
  getInfoLink,
  getInputType,
  getResultFromFormula,
  isSearchSelect,
  OFFSET_DEFAULT,
  offsetAmountUnitOptions,
  preventNull,
  shouldResetNextSelections,
  STEP1_DEFAULT_VALUE,
  STEP2_DEFAULT_VALUE,
  uniqPropertyArray,
  unitByRange,
  unitFlowInputDetails,
  updateSelectionInfos,
  useBase,
  useCategory,
  useFiscalYearMonth,
  useLastTenYearsListBox,
  useScope,
} from './utils';
import { AuthContext } from '@contexts/AuthContext';

type Step = 'STEP_1' | 'STEP_2';

interface EmissionUpdateModalProp {
  modalState: BooleanState;
  dataEmission?: EmissionInputScope1Response;
  itemEmission: OffsetDetails | EmissionOutput | undefined;
  mode: 'DUPLICATE' | 'UPDATE';
  setModeEmission: React.Dispatch<React.SetStateAction<'UPDATE' | 'DUPLICATE'>>;
}

export const EmissionUpdateModal: FunctionComponent<
  EmissionUpdateModalProp
> = ({ modalState, dataEmission, itemEmission, mode, setModeEmission }) => {
  const formulaRef = useRef(dataEmission?.formula || undefined);
  const formualCargoWeight = useRef(
    dataEmission?.flowInputDetails?.[0]?.inputGroup?.find(
      (p) => p?.fieldTarget === 'transportMaximumCargoWeight',
    )?.formula,
  );
  const scopeRef = useRef('');
  const categoryRef = useRef('');
  const calculationTargetCodeRef = useRef('');
  const scopeCategoryRef = useRef(
    `${itemEmission?.scopeCode || ''}-${itemEmission?.categoryCode || ''}`,
  );
  const greenGasHouseRef = useRef(itemEmission?.typeOfGas);

  const checkFlowFinished = useRef(dataEmission?.flowFinished);
  const {
    text: { common, C01 },
  } = useContext(LanguageContext)!;
  const { user } = React.useContext(AuthContext)!;
  const { addMessage } = useContext(PortalContext)!;
  const [step, setStep] = useState<Step>('STEP_1');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const backStep = () => {
    setStep('STEP_1');
  };

  const nextStep = () => {
    setStep('STEP_2');
  };

  /*=================== STEP 1 HANDLER ===================*/

  const step1Schema = useStep1().step1Schema;

  const step1Methods = useForm<Step1Fields>({
    values: STEP1_DEFAULT_VALUE,
    resolver: yupResolver(step1Schema),
  });

  const handleChangeScope = async (_name: string, value: string) => {
    scopeRef.current = `/scope-${value}`;
  };

  const handleChangeCategory = (_name: string, value: string) => {
    categoryRef.current = '';
    if (scopeRef.current === '/scope-3' && !!Number(value)) {
      categoryRef.current = `/category-${Number(value)}`;
    }
  };

  const { data: greenGasQuery } = useHgbQuery<GreenGasHouseCode[]>(
    `${API_URLS.GREEN_GAS_HOUSE_CODE}`,
    {},
    {
      enabled:
        step1Methods.watch('scopeCode') === '3' ||
        step1Methods.watch('scopeCode') === '1',
    },
  );

  useHgbQuery<any>(
    `${API_URLS.CALCULATION_METHOD_GAS_CODE}`,
    {},
    {
      enabled:
        (step1Methods.watch('scopeCode') === '3' ||
          step1Methods.watch('scopeCode') === '1') &&
        itemEmission?.typeOfGas !== 'CO2',
      onSuccess: (data) =>
        (calculationTargetCodeRef.current = data?.result?.[0]?.value),
    },
  );

  const step1SubmitHandler: SubmitHandler<Step1Fields> = async (data) => {
    if (!!data.targetYearMonth) {
      const searchParams = new URLSearchParams('');
      searchParams.set(
        'lciDBYear',
        data.targetYearMonth.replaceAll('-', '').replaceAll('/', ''),
      );
      searchParams.set('lang', LocalDataClass.language);
      try {
        const response = await hgbAxios().get(
          `${API_URLS.CHECK_LCIDB_YEAR_API_URL}?${searchParams.toString()}`,
        );
        const targetLciDbYear = response.data?.result ?? '';
        if (isEmpty(targetLciDbYear)) {
          addMessage('error', response.data?.message ?? '');
          return;
        }
        step1Methods.setValue('targetLciDbYear', targetLciDbYear);
        const { targetYearMonth } = data;

        // Khi qua step phải check xem scope hoặc category có thay đổi hay không mới tiến hành reset và call lại api
        // Và check thêm lciDbYear có đổi so với lúc chưa update hay không
        const scopeCategory = `${data.scopeCode}-${data.categoryCode}`;
        const greenHouseCode = step1Methods.watch('greenHouseCode');
        const categoryCode = step1Methods.watch('categoryCode');

        if (greenHouseCode && greenHouseCode !== 'CO2') {
          step2Methods.setValue(
            'calculationTargetCode',
            calculationTargetCodeRef.current,
          );
        }
        if (
          scopeCategory !== scopeCategoryRef.current ||
          greenHouseCode !== greenGasHouseRef.current ||
          getFiscalYearFromMonthDirectInput(
            itemEmission?.targetYearMonth ?? '',
          ).toString() !== targetLciDbYear
        ) {
          resetStep2();
          const params: any = {
            targetLciDbYear,
            targetYearMonth,
            greenHouseCode,
            categoryCode,
            calculationTargetCode:
              step1Methods.watch('greenHouseCode') !== 'CO2'
                ? calculationTargetCodeRef.current
                : '',
          };

          callFlowApi(params);
        }
        nextStep();
        scopeCategoryRef.current = scopeCategory;
        greenGasHouseRef.current = greenHouseCode || '';
      } catch (error) {}
    }
  };

  const step1SubmitError: SubmitErrorHandler<Step1Fields> = (err) => {
    debug.error(err);
  };
  /*=================== END STEP 1 HANDLER ===================*/

  /*=================== STEP 2 HANDLER ===================*/
  /*=================== SCOPE 123 HANDLER ===================*/

  const [selectionInfos, setSelectionInfos] = useState<SelectionInfo[]>(
    dataEmission?.nextStepSelectionInfos || [],
  );
  const [flowInputDetails, setFlowInputDetails] = useState<FlowInputDetail[]>(
    dataEmission?.flowInputDetails || [],
  );
  const [flowResultItem, setFlowResultItem] = useState<InputInfo | undefined>(
    dataEmission?.flowResultItem as InputInfo,
  );

  const { ConfirmModal, hgbConfirm } = useConfirm();
  const queryClient = useQueryClient();

  const updateEmissionMutation = useHgbMutationPut<unknown, any>(
    createEmissionApiLink(
      step1Methods.watch('greenHouseCode'),
      step1Methods.watch('scopeCode'),
      step1Methods.watch('categoryCode'),
      itemEmission?.id,
    ),
    {
      onSuccess: () => {
        handleCancel();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.EMISSION_LIST],
        });
      },
    },
  );

  const createEmissionMutation = useHgbMutationPost<unknown, any>(
    createEmissionApiLink(
      step1Methods.watch('greenHouseCode'),
      step1Methods.watch('scopeCode'),
      step1Methods.watch('categoryCode'),
    ),
    {
      onSuccess: () => {
        handleCancel();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.EMISSION_LIST],
        });
      },
    },
  );

  const { step2Schema, offsetSchema } = useStep2(
    step1Methods.watch('scopeCode'),
    flowInputDetails,
  );

  const step2Methods = useForm<Step2Fields>({
    values: STEP2_DEFAULT_VALUE,
    resolver: yupResolver(step2Schema),
  });

  const resetStep2 = () => {
    step2Methods.reset(STEP2_DEFAULT_VALUE);
    setSelectionInfos([]);
    setFlowResultItem(undefined);
    setFlowInputDetails([]);
  };

  // Nghĩa là các field cần người dùng input hàng loạt, khi full mới tiến hành call api tiếp
  const nextSelections = useRef<SelectionInfo[]>(
    dataEmission?.nextStepSelectionInfos || [],
  );
  const nextSelectionsList = useRef<string[][]>([]);
  const currentFieldTarget = useRef('');
  const isStep2Initialized = useRef(false);

  const handleChangeBeforeCallApi = useCallback(
    (name: string, value: string) => {
      setErrors({});
      currentFieldTarget.current = name;

      setSelectionInfos((prev: SelectionInfo[]) => {
        const updatedSelectionInfos = updateSelectionInfos(
          name,
          value,
          prev,
          step2Methods,
        );

        const newParams = createNewParams(
          name,
          value,
          updatedSelectionInfos,
          step1Methods,
          step2Methods,
        );

        // Reset lại params cho step2 và gọi API
        step2Methods.reset(newParams);
        if (step1Methods.watch('greenHouseCode') !== 'CO2') {
          newParams.greenHouseCode = step1Methods.watch('greenHouseCode');
          newParams.calculationTargetCode = calculationTargetCodeRef.current;
        }

        callFlowApi(newParams);

        return updatedSelectionInfos;
      });
    },
    [],
  );

  const callFlowApi = async (newParams: Record<string, any>) => {
    try {
      const queryString = createFlowApi(
        scopeRef.current,
        categoryRef.current,
        newParams,
      );
      const data = (await hgbAxios().post(queryString, newParams))?.data;
      const responseFlowInputDetails = (data?.result?.flowInputDetails ||
        []) as FlowInputDetail[];
      handleChangeSelectionInfosAfterCallApiSuccess(
        (data?.result?.nextStepSelectionInfos || []) as SelectionInfo[],
      );
      setFlowInputDetails(responseFlowInputDetails);
      handleChangeFlowResultItemAfterCallApiSuccess(
        data?.result?.flowResultItem as InputInfo,
      );

      checkFlowFinished.current = data?.result?.flowFinished;

      if (data?.result?.flowFinished) {
        const flowInputDetails = data?.result
          ?.flowInputDetails as FlowInputDetail[];
        const fieldTarget = flowInputDetails?.[0]?.intensity?.fieldTarget || '';
        const value = flowInputDetails?.[0]?.intensity?.value || '';
        formulaRef.current = data?.result?.formula || '';
        if (fieldTarget) {
          step2Methods.setValue(fieldTarget as any, value);
        }

        const inputGroup = responseFlowInputDetails?.[0]?.inputGroup || [];
        const cargoWeightField = inputGroup.find(
          (item) => item?.fieldTarget === 'transportMaximumCargoWeight',
        );
        formualCargoWeight.current = cargoWeightField?.formula || '';
      }
    } catch (error: any) {
      const fieldTargetWithMessage = (
        error?.response?.data?.message || ''
      ).split('|||');
      const fieldTarget = fieldTargetWithMessage?.[0];
      const message = fieldTargetWithMessage?.[1];
      if (fieldTarget) {
        setErrors({ [fieldTarget]: message });
      }
      setFlowResultItem(undefined);
      setFlowInputDetails([]);
    }
  };

  const handleChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      handleChangeBeforeCallApi(e.target?.name || '', e.target.value);
    },
    [step],
  );

  const handleChangeFlowResultItemAfterCallApiSuccess = useCallback(
    (inputInfo?: InputInfo) => {
      if (inputInfo) {
        // Ở mode Update thì phải reset về giá trị gốc của record khi có inputInfo trả về, còn mode Dupplicate thì reset về giá trị inputInfo
        let value = inputInfo.value;
        value = dataEmission?.flowResultItem?.value;
        setTimeout(() => {
          step2Methods.setValue(
            `${inputInfo?.fieldTarget}` as keyof Step2Fields,
            mode === 'UPDATE' ? Number(value) : '',
          );
        }, 200);
      }
      setFlowResultItem(inputInfo);
    },
    [],
  );

  const handleChangeSelectionInfosAfterCallApiSuccess = useCallback(
    (nextStepSelectionInfos: SelectionInfo[]) => {
      // khi lấy được nextStepSelectionInfos, reset lại value các field đã chọn trước đó
      nextStepSelectionInfos.forEach((item) => {
        const fieldTarget = item?.fieldTarget as keyof Step2Fields;
        if (fieldTarget)
          step2Methods.setValue(fieldTarget, STEP2_DEFAULT_VALUE[fieldTarget]);
      });
      if (
        // Để tránh trường hợp set lại next selection khi gặp 1 bộ selection đi chung
        shouldResetNextSelections(
          nextSelections.current,
          currentFieldTarget.current,
          nextStepSelectionInfos,
        )
      ) {
        nextSelections.current = nextStepSelectionInfos;
        // Lưu lại tất cả các selection để khi cần sử dụng
        nextSelectionsList.current.push(
          nextStepSelectionInfos.map((item) => item.fieldTarget),
        );
        /* ================================================ */
        setSelectionInfos((prevStepSelectionInfos) => {
          return uniqPropertyArray<SelectionInfo>(
            [...prevStepSelectionInfos, ...nextStepSelectionInfos],
            'fieldTarget',
          );
        });
      }
    },
    [],
  );

  const onUpdateValue = debounce(() => {
    try {
      if (formulaRef.current) {
        step2Methods.setValue(
          `${flowResultItem?.fieldTarget}` as keyof Step2Fields,
          getResultFromFormula(
            { ...step1Methods.getValues(), ...step2Methods.getValues() },
            formulaRef.current,
          ),
        );
      }
      if (formualCargoWeight.current) {
        step2Methods.setValue(
          'transportMaximumCargoWeight',
          getResultFromFormula(
            { ...step1Methods.getValues(), ...step2Methods.getValues() },
            formualCargoWeight.current,
          ),
        );
      }
    } catch (error) {
      debug.error('onUpdateValue', error);
    }
  }, 500);

  const step2SubmitHandler: SubmitHandler<Step2Fields | OffsetFields> = async (
    data,
  ) => {
    //thêm các đơn vị unit nếu có vào
    const convertData = {
      ...data,
      ...unitFlowInputDetails(flowInputDetails),
      ...(flowResultItem && flowResultItem.unit && flowResultItem?.fieldTarget
        ? { [`${flowResultItem?.fieldTarget}Unit`]: flowResultItem.unit }
        : {}),
      ...step1Methods.getValues(),

      //calculationTargetCode là field ẩn, khi update check !== CO2 thì gửi lên
      calculationTargetCode:
        step1Methods.getValues('greenHouseCode') !== 'CO2'
          ? calculationTargetCodeRef.current
          : step2Methods.getValues('calculationTargetCode'),
    };

    const result = await hgbConfirm();
    if (!result) return;

    if (mode === 'UPDATE') {
      updateEmissionMutation.mutate({ ...convertData, id: itemEmission?.id });
    } else {
      createEmissionMutation.mutate(convertData);
    }
  };

  const step2SubmitErrorHandler: SubmitErrorHandler<Step2Fields> = (error) => {
    console.log('error', error);
  };
  /*=================== END SCOPE 123 HANDLER ===================*/

  /*=================== OFFSET HANDLER ===================*/

  const offsetMethods = useForm<OffsetFields>({
    values: OFFSET_DEFAULT,
    resolver: yupResolver(offsetSchema),
  });
  /*=================== END OFFSET HANDLER ===================*/

  /*=================== END STEP 2 HANDLER ===================*/

  const handleCancel = () => {
    // setAdditionalSelectionInfos([]);
    nextSelections.current = [];
    setSelectionInfos([]);
    backStep();
    modalState.turnOff();
    step1Methods.reset();
    step2Methods.reset();
    setModeEmission('UPDATE');
  };

  const targetFiscalYearOptions = useLastTenYearsListBox().options;
  const baseOptions = useBase(mode === 'UPDATE').options;
  const targetYearMonthOptions = useFiscalYearMonth(
    step1Methods.watch('targetFiscalYear'),
  ).options;
  const scopeOptions = useScope().options;
  const categoryOptions = useCategory(
    step1Methods.watch('scopeCode'),
    user.enterpriseId,
  ).options;

  React.useEffect(() => {
    if (targetYearMonthOptions?.length > 0) {
      const fiscalYear = getFiscalYearFromMonth(
        `${new Date().getFullYear()}/${new Date().getMonth()}`,
      );
      const watchedFiscalYear = step1Methods.watch('targetFiscalYear');
      const formattedTargetYearMonth = itemEmission?.targetYearMonth
        .replaceAll('-', '')
        .replaceAll('/', '');
      let targetYearMonthValue;

      if (itemEmission?.targetFiscalYear === watchedFiscalYear) {
        targetYearMonthValue = formattedTargetYearMonth;
      } else if (fiscalYear === Number(watchedFiscalYear)) {
        targetYearMonthValue = currentYearMonth() || formattedTargetYearMonth;
      } else {
        targetYearMonthValue = targetYearMonthOptions?.[0]?.value ?? '';
      }

      step1Methods.setValue(
        'targetYearMonth',
        targetYearMonthValue?.replaceAll('-', '').replaceAll('/', '') ?? '',
      );
    }
  }, [
    targetYearMonthOptions,
    step1Methods.watch('targetFiscalYear'),
    itemEmission,
  ]);

  React.useEffect(() => {
    step1Methods.reset({
      targetLciDbYear: itemEmission?.targetLciDbYear,
      targetFiscalYear: itemEmission?.targetFiscalYear,
      targetYearMonth: itemEmission?.targetYearMonth
        .replaceAll('-', '')
        .replaceAll('/', ''),
      baseId: itemEmission?.baseId,
      scopeCode: itemEmission?.scopeCode,
      categoryCode: itemEmission?.categoryCode,
      greenHouseCode: itemEmission?.typeOfGas || 'CO2', //hard 'CO2' với case offset vì BE ko trả lên đc
    });

    if (itemEmission?.scopeCode === '4') {
      const offset = itemEmission as OffsetDetails;
      setTimeout(() => {
        offsetMethods.reset({
          creditType: offset.creditType,
          offsetAmount: offset.offsetAmount,
          offsetAmountUnit: offset.offsetAmountUnit,
          offsetCertificateDate: offset.offsetCertificateDate ?? '',
          offsetTarget: offset.offsetTarget,
          projectName: offset.projectName,
        });
      }, 0);
    }
  }, [itemEmission]);

  React.useEffect(() => {
    //xử lí init value của step 2
    if (
      step1Methods.watch('targetLciDbYear') === dataEmission?.targetLciDbYear &&
      step1Methods.watch('greenHouseCode') === itemEmission?.typeOfGas &&
      (!isStep2Initialized.current || mode === 'DUPLICATE')
    ) {
      isStep2Initialized.current = true;
      setSelectionInfos(dataEmission?.nextStepSelectionInfos || []);
      setFlowInputDetails(dataEmission?.flowInputDetails || []);
      setFlowResultItem(dataEmission?.flowResultItem as InputInfo);
      if (!isEmpty(dataEmission?.nextStepSelectionInfos)) {
        dataEmission?.nextStepSelectionInfos?.forEach((e) =>
          step2Methods.setValue(
            e?.fieldTarget as keyof Step2Fields,
            e?.selectedValue || '',
          ),
        );
      }

      if (!isEmpty(dataEmission?.flowInputDetails)) {
        dataEmission?.flowInputDetails?.forEach(({ inputGroup, intensity }) => {
          // Xử lý inputGroup
          (inputGroup ?? []).forEach((e) => {
            if (mode === 'UPDATE') {
              step2Methods.setValue(
                e?.fieldTarget as keyof Step2Fields,
                e?.value || '',
              );
            }
            if (mode === 'DUPLICATE' && !e?.disabled) {
              step2Methods.setValue(e.fieldTarget as keyof Step2Fields, '');
            }
          });

          // Xử lý intensity
          if (intensity) {
            step2Methods.setValue(
              intensity?.fieldTarget as keyof Step2Fields,
              intensity?.value || '',
            );
            if (mode === 'DUPLICATE' && !intensity?.disabled) {
              step2Methods.setValue(
                intensity.fieldTarget as keyof Step2Fields,
                '',
              );
            }
          }
        });
      }

      if (!isEmpty(dataEmission?.flowResultItem)) {
        setTimeout(() => {
          step2Methods.setValue(
            `${dataEmission?.flowResultItem?.fieldTarget}` as keyof Step2Fields,
            mode === 'DUPLICATE'
              ? ''
              : Number(dataEmission?.flowResultItem?.value),
          );
        }, 200);
      }
    }
  }, [dataEmission, step1Methods.watch('targetLciDbYear'), step]);

  React.useEffect(() => {
    //fix lỗi triger onUpdateValue
    if (step === 'STEP_2') {
      const inputs: InputInfo[] = [];
      dataEmission?.flowInputDetails
        ?.flatMap((s) => s.inputGroup ?? [])
        .forEach((k) => {
          inputs.push(k);
        });
      const activityName = (itemEmission as EmissionOutput)?.activityName;
      if (activityName) {
        step2Methods.setValue('activityName', activityName);
      }
      if (!isEmpty(flowInputDetails)) {
        flowInputDetails?.forEach((item) => {
          const valueIntensity =
            item.intensity?.value ||
            dataEmission?.flowInputDetails?.find(
              (p) => p?.intensity?.fieldTarget === item?.intensity?.fieldTarget,
            )?.intensity?.value;
          if (!item.intensity?.disabled && mode === 'DUPLICATE') {
            setTimeout(() => {
              item.intensity &&
                step2Methods.setValue(
                  item?.intensity?.fieldTarget as keyof Step2Fields,
                  '',
                );
            });
          } else {
            setTimeout(() => {
              item.intensity &&
                step2Methods.setValue(
                  item.intensity?.fieldTarget as keyof Step2Fields,
                  valueIntensity,
                );
            }, 0);
          }

          item?.inputGroup?.forEach((field) => {
            // Tìm trường tương ứng trong inputGroup
            const valueflowInputs = inputs?.find(
              (l) => l.fieldTarget === field.fieldTarget,
            )?.value;

            if (!field.disabled && mode === 'DUPLICATE') {
              setTimeout(() => {
                step2Methods.setValue(
                  field.fieldTarget as keyof Step2Fields,
                  '',
                );
              }, 200);
            } else {
              setTimeout(() => {
                step2Methods.setValue(
                  field.fieldTarget as keyof Step2Fields,
                  !isEmpty(field?.value?.toString())
                    ? Number(field?.value)
                    : valueflowInputs,
                );
              }, 0);
            }
          });
        });
      }
    }
  }, [flowInputDetails, mode, step]);

  return (
    <>
      <Modal
        className="ant-modal-hgb-custom"
        centered
        title={
          <HgbAntdModalTitle
            className={cn({ 'tw-relative tw-indent-16': step === 'STEP_2' })}
          >
            {step === 'STEP_2' && (
              <HgbExpandMoreOutlinedIcon
                onClick={backStep}
                size="24"
                className="tw-absolute tw-left-32 tw-top-24 tw-rotate-90 tw-cursor-pointer"
              />
            )}
            {mode === 'DUPLICATE'
              ? C01.modal.emissionDuplicateTitle
              : C01.modal.emissionUpdateTitle}
          </HgbAntdModalTitle>
        }
        open={modalState.value}
        footer={null}
        closable={true}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleCancel}
      >
        <HgbAntdModalForm>
          <HgbAntdModalFormBody>
            {/* STEP 1 */}
            <FormProvider {...step1Methods}>
              <div
                className={cn('tw-space-y-24', {
                  'tw-hidden': step === 'STEP_2',
                })}
              >
                <div className="tw-grid tw-grid-cols-2 tw-gap-24">
                  <HgbSelect
                    {...step1Methods.register('targetFiscalYear')}
                    options={targetFiscalYearOptions}
                    label={C01.label.fiscalYear}
                    placeholder={C01.placeholder.fiscalYear}
                    required
                    disabled={mode === 'UPDATE'}
                  />
                  <HgbSelect
                    {...step1Methods.register('targetYearMonth')}
                    options={targetYearMonthOptions}
                    label={C01.label.targetYearMonth}
                    placeholder={C01.placeholder.targetYearMonth}
                    required
                    disabled={mode === 'UPDATE'}
                  />
                </div>
                <HgbTreeSelect
                  {...step1Methods.register('baseId')}
                  placeholder={C01.placeholder.baseId}
                  label={C01.label.baseId}
                  required
                  treeData={baseOptions}
                  showSearch
                  disabled={mode === 'UPDATE'}
                />

                <HgbSelect
                  {...step1Methods.register('greenHouseCode')}
                  options={parseToHgbSelectOptions(greenGasQuery?.result)}
                  label={C01.label.greenHouseGases}
                  placeholder={C01.placeholder.categoryCode}
                  required
                  disabled
                />

                <HgbSelect
                  {...step1Methods.register('scopeCode')}
                  required
                  label={C01.label.scopeCode}
                  placeholder={C01.placeholder.scopeCode}
                  options={scopeOptions?.filter(
                    (scope) =>
                      step1Methods.watch('greenHouseCode') === 'CO2' ||
                      (scope?.value !== '2' && scope?.value !== '4'),
                  )}
                  onWatchValue={handleChangeScope}
                  disabled
                />

                {step1Methods.watch('scopeCode') === '3' && (
                  <HgbSelect
                    {...step1Methods.register('categoryCode')}
                    options={categoryOptions}
                    label={common.label.category}
                    placeholder={C01.placeholder.categoryCode}
                    required
                    onWatchValue={handleChangeCategory}
                    disabled
                  />
                )}
              </div>
            </FormProvider>
            {/* END STEP 1 */}
            {/* STEP 2 */}
            <div className={cn({ 'tw-hidden': step === 'STEP_1' })}>
              {step1Methods.watch('scopeCode') === '4' ||
              createMessageDataEntry(
                step1Methods.watch('scopeCode'),
                step1Methods.watch('greenHouseCode'),
              ) ? (
                <h4 className="tw-font-bold">{C01.label.dataEntry}</h4>
              ) : null}
              <div className="tw-space-y-24">
                <FormProvider {...step2Methods}>
                  {step1Methods.watch('scopeCode') !== '4' && (
                    <div>
                      {createMessageDataEntry(
                        step1Methods.watch('scopeCode'),
                        step1Methods.watch('greenHouseCode'),
                      ) ? (
                        <small className="tw-text-error-6">
                          {C01.message.dataEntryScopeNotice}
                        </small>
                      ) : (
                        <div className="tw-text-h4 tw-font-bold">
                          {
                            greenGasQuery?.result?.find(
                              (p) =>
                                p?.value ===
                                step1Methods.watch('greenHouseCode'),
                            )?.name
                          }
                        </div>
                      )}
                      {step1Methods.watch('targetLciDbYear') !==
                        getFiscalYearFromMonthDirectInput(
                          itemEmission?.targetYearMonth ?? '',
                        ).toString() && (
                        <small className="tw-block tw-text-error-6">
                          {parse(C01.message.dataEntryScopeNoticeReset)}
                        </small>
                      )}
                      <div className="tw-mt-16 tw-space-y-24">
                        {selectionInfos
                          ?.filter((item) => item.data)
                          .map((selectionInfo) =>
                            selectionInfo.type === 'LIST_BOX' ? (
                              <div key={selectionInfo.fieldTarget}>
                                <HgbSelect
                                  key={selectionInfo?.fieldTarget}
                                  options={createSelectioInfoOptions(
                                    selectionInfo.fieldTarget ===
                                      'supplyMenuCode'
                                      ? selectionInfo.data?.sort((p1, p2) =>
                                          p1.value > p2.value
                                            ? 1
                                            : p1.value < p2.value
                                            ? -1
                                            : 0,
                                        )
                                      : selectionInfo.data,
                                    selectionInfo?.fieldTarget,
                                  )}
                                  required
                                  label={selectionInfo?.name}
                                  name={
                                    selectionInfo?.fieldTarget as keyof Step2Fields
                                  }
                                  onChangeValue={handleChangeBeforeCallApi}
                                  showSearch={isSearchSelect(
                                    selectionInfo?.fieldTarget,
                                    step1Methods.watch('scopeCode'),
                                    step1Methods.watch('categoryCode'),
                                  )}
                                  autoInitValue={checkInitValueSelectionInfo(
                                    selectionInfo,
                                  )}
                                />
                                {errors?.[
                                  selectionInfo?.fieldTarget || '_'
                                ] && (
                                  <HgbFieldError className="tw-mt-4">
                                    {errors[selectionInfo.fieldTarget]}
                                  </HgbFieldError>
                                )}
                              </div>
                            ) : (
                              <HgbAntdRadioGroup
                                key={selectionInfo?.fieldTarget}
                                options={parseToHgbSelectOptions(
                                  selectionInfo?.data,
                                )}
                                label={selectionInfo?.name}
                                name={selectionInfo?.fieldTarget}
                                info={getInfoLink(selectionInfo?.fieldTarget)}
                                direction={
                                  selectionInfo?.fieldTarget ===
                                    'cityGasOptionSelected' ||
                                  selectionInfo?.fieldTarget === 'heatSelected'
                                    ? 'vertical'
                                    : 'horizontal'
                                }
                                onChangeValue={handleChangeRadio}
                              />
                            ),
                          )}
                        {flowInputDetails?.map(
                          ({ inputGroup, intensity }, index) => {
                            return (
                              <React.Fragment
                                key={`${intensity?.name}${index}`}
                              >
                                {(inputGroup ?? []).map((input) => (
                                  <HgbAntdInput
                                    onUpdateValue={onUpdateValue}
                                    key={input?.name}
                                    required
                                    label={input?.name}
                                    suffix={preventNull(input.unit)}
                                    subLabel={unitByRange(input)}
                                    disabled={input.disabled}
                                    type={getInputType(input?.fieldTarget)}
                                    minValue={
                                      input.constraints?.MIN_VALUE ?? undefined
                                    }
                                    maxValue={
                                      input.constraints?.MAX_VALUE ?? undefined
                                    }
                                    maxLength={input.maxLength}
                                    {...step2Methods.register(
                                      input?.fieldTarget as keyof Step2Fields,
                                    )}
                                    forceMaxDecimal={
                                      input.constraints?.DECIMAL_LENGTH ??
                                      undefined
                                    }
                                    initValue={input.value}
                                  />
                                ))}
                                {intensity && (
                                  <HgbAntdInput
                                    onUpdateValue={onUpdateValue}
                                    required
                                    label={intensity?.name}
                                    suffix={preventNull(intensity?.unit)}
                                    subLabel={unitByRange(intensity)}
                                    disabled={intensity?.disabled}
                                    maxLength={intensity?.maxLength}
                                    name={intensity?.fieldTarget}
                                    type={getInputType(intensity?.fieldTarget)}
                                    minValue={
                                      intensity?.constraints?.MIN_VALUE ??
                                      undefined
                                    }
                                    maxValue={
                                      intensity?.constraints?.MAX_VALUE ??
                                      undefined
                                    }
                                    initValue={intensity?.value}
                                  />
                                )}
                              </React.Fragment>
                            );
                          },
                        )}
                        {flowResultItem && (
                          <HgbAntdInput
                            required
                            label={flowResultItem.name}
                            suffix={preventNull(flowResultItem.unit)}
                            subLabel={unitByRange(flowResultItem)}
                            readOnly={flowResultItem.disabled}
                            maxLength={flowResultItem.maxLength}
                            type={'positiveDecimal'}
                            minValue={
                              flowResultItem.constraints?.MIN_VALUE ?? undefined
                            }
                            maxValue={
                              flowResultItem.constraints?.MAX_VALUE ?? undefined
                            }
                            forceMaxDecimal={
                              flowResultItem.constraints?.DECIMAL_LENGTH ??
                              undefined
                            }
                            {...step2Methods.register(
                              flowResultItem.fieldTarget as any,
                            )}
                            initValue={flowResultItem.value}
                          />
                        )}

                        <HgbAntdInput
                          {...step2Methods.register('activityName')}
                          label={
                            step1Methods.watch('scopeCode') === '3' &&
                            step1Methods.watch('greenHouseCode') === 'CO2'
                              ? C01.label.activityName
                              : C01.label.note
                          }
                          placeholder={C01.placeholder.activityName}
                          required={
                            step1Methods.watch('scopeCode') === '3' &&
                            step1Methods.watch('greenHouseCode') === 'CO2'
                          }
                          maxLength={200}
                        />
                      </div>
                    </div>
                  )}
                  <FormProvider {...offsetMethods}>
                    {step1Methods.watch('scopeCode') === '4' && (
                      <div className="tw-space-y-24">
                        <small className="tw-text-error-6">
                          {parse(C01.message.dataEntryOffsetNotice)}
                        </small>
                        <HgbAntdInput
                          {...offsetMethods.register('offsetAmount')}
                          label={C01.label.offsetAmount}
                          placeholder={C01.placeholder.offsetAmount}
                          type="positiveDecimal"
                          maxLength={14}
                          required
                          forceMaxDecimal={3}
                          maxValue={9999999999}
                        />
                        <HgbSelect
                          {...offsetMethods.register('offsetAmountUnit')}
                          required
                          autoInitValue
                          options={offsetAmountUnitOptions}
                          label={C01.label.offsetAmountUnit}
                          placeholder={C01.placeholder.offsetAmountUnit}
                        />
                        <HgbDatePicker
                          {...offsetMethods.register('offsetCertificateDate')}
                          required
                          format="YYYY/MM/DD"
                          picker="date"
                          label={C01.label.offsetCertificateDate}
                          placeholder={C01.placeholder.offsetCertificateDate}
                        />
                        <HgbAntdInput
                          {...offsetMethods.register('offsetTarget')}
                          maxLength={255}
                          label={C01.label.offsetTarget + common.label.optional}
                          placeholder={C01.placeholder.offsetTarget}
                        />
                        <HgbAntdInput
                          {...offsetMethods.register('projectName')}
                          maxLength={255}
                          label={C01.label.projectName + common.label.optional}
                          placeholder={C01.placeholder.projectName}
                        />
                        <HgbAntdInput
                          {...offsetMethods.register('creditType')}
                          maxLength={255}
                          label={C01.label.creditType + common.label.optional}
                          placeholder={C01.placeholder.creditType}
                        />
                      </div>
                    )}
                  </FormProvider>
                </FormProvider>
              </div>
            </div>

            {/* END STEP 2 */}
          </HgbAntdModalFormBody>
          <HgbAntdModalFormFooter>
            <HgbAntdButton className="tw-min-w-100" onClick={handleCancel}>
              {common.button.cancel}
            </HgbAntdButton>
            {step === 'STEP_1' ? (
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="button"
                onClick={step1Methods.handleSubmit(
                  step1SubmitHandler,
                  step1SubmitError,
                )}
              >
                {common.button.next}
              </HgbAntdButton>
            ) : null}
            {step === 'STEP_2' ? (
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="button"
                disabled={disableStep2Submit(
                  formulaRef.current,
                  {
                    ...step1Methods.watch(),
                    ...step2Methods.watch(),
                    ...offsetMethods.watch(),
                  },
                  !!checkFlowFinished.current,
                )}
                onClick={
                  step1Methods.getValues('scopeCode') !== '4'
                    ? step2Methods.handleSubmit(
                        step2SubmitHandler,
                        step2SubmitErrorHandler,
                      )
                    : offsetMethods.handleSubmit(
                        step2SubmitHandler,
                        step2SubmitErrorHandler,
                      )
                }
              >
                {mode === 'UPDATE' ? common.button.change : common.button.add}
              </HgbAntdButton>
            ) : null}
          </HgbAntdModalFormFooter>
        </HgbAntdModalForm>
      </Modal>

      <ConfirmModal
        okText={mode === 'UPDATE' ? common.button.change : common.button.add}
        cancelText={common.button.cancel}
        title={
          mode === 'UPDATE'
            ? C01.modal.emissionUpdateConfirmTitle
            : C01.modal.emissionCreateConfirmTitle
        }
      >
        <div>
          {mode === 'UPDATE'
            ? C01.message.emissionUpdateConfirm
            : C01.message.emissionCreateConfirm}
        </div>
      </ConfirmModal>
    </>
  );
};
