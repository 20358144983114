import { useFormContext } from 'react-hook-form';
import { HgbAntdRadio } from './HgbAntdRadio';
import { HgbAntdRadioOption } from './HgbAntdRadioGroup';
import { cn } from '@utils/cn';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { useEffect } from 'react';

export type HgbAntdRadioGroup2Props = {
  options: (HgbAntdRadioOption & { infoLink?: string })[];
  name: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  optionsClassName?: string;
  infoLink?: string;
  onChangeValue?: (name: string, value: string) => void;
  required?: boolean;
  direction?: 'vertical' | 'horizontal';
};

export const HgbAntdRadioGroup2 = ({
  label,
  options,
  className,
  disabled,
  optionsClassName,
  infoLink,
  onChangeValue,
  required,
  direction,
  ...props
}: HgbAntdRadioGroup2Props) => {
  const { register, watch } = useFormContext();
  useEffect(() => {
    onChangeValue?.(props.name, watch(props.name));
  }, [watch(props.name), props.name]);

  return (
    <div
      className={cn(
        { 'tw-opacity-70 [&_*]:tw-cursor-not-allowed': disabled },
        className,
      )}
    >
      {label && (
        <p className="tw-mb-8 tw-font-bold">
          {label}{' '}
          {required && (
            <span className="tw-text-paragraph tw-font-bold tw-text-error-7">
              *
            </span>
          )}
          {infoLink && (
            <HgbInfoLink to={infoLink} target="_blank" className="tw-ml-4" />
          )}
        </p>
      )}

      <div
        className={cn('tw-flex tw-gap-16', {
          'tw-flex-col': direction === 'vertical',
        })}
      >
        {options.map((option) => (
          <HgbAntdRadio
            infoLink={option.infoLink}
            className={cn(
              { 'tw-pointer-events-none': disabled },
              optionsClassName,
            )}
            key={'k' + option.value}
            {...register(props.name)}
            label={option.label}
            value={option.value}
          />
        ))}
      </div>
    </div>
  );
};