import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { Modal } from 'antd';
import { debounce } from 'lodash';
import * as React from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useHgbMutationDelete,
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { ExternalLink } from 'services/types/externalLink';
import * as yup from 'yup';

export type UpdateExternalLinkPayload = {
  id: string;
  linkName: string;
  url: string;
  isManual: boolean;
};
export type AddExternalLinkPayload = Omit<UpdateExternalLinkPayload, 'id'>;

export type ExternalLinkType = 'normal' | 'manual';
interface IExternalLinkModalProps {
  modalStatus: BooleanState;
  data: ExternalLink | null;
  linkType: ExternalLinkType;
  type: boolean;
}
const UPDATE_LINK_DEFAULT_VALUE: ExternalLink = {
  id: -1,
  linkName: '',
  url: '',
};

const ExternalLinkModal: React.FunctionComponent<IExternalLinkModalProps> = ({
  modalStatus,
  data,
  linkType = 'normal',
  type,
}) => {
  const queryClient = useQueryClient();
  const {
    text: { CM01, common, E0000 },
  } = React.useContext(LanguageContext)!;

  const baseSchema = yup.object().shape({
    linkName: yup.string().required(E0000(CM01.label.linkName)),
    url: yup.string().required(E0000(CM01.label.url)),
  });

  const updateMethods = useForm<ExternalLink>({
    resolver: yupResolver(baseSchema),
  });

  React.useEffect(() => {
    let timeoutId: any;
    timeoutId = setTimeout(() => {
      updateMethods.reset(data || UPDATE_LINK_DEFAULT_VALUE);
      clearTimeout(timeoutId);
    }, 0);
  }, [data, modalStatus.value]);

  const handleCancel = () => {
    updateMethods.reset(UPDATE_LINK_DEFAULT_VALUE);
    modalStatus.turnOff();
  };

  const updateExternalLinkMutation = useHgbMutationPut<
    unknown,
    UpdateExternalLinkPayload
  >(API_URLS.EXTERNAL_LINK_API_URL, {
    onSuccess() {
      updateMethods.reset(UPDATE_LINK_DEFAULT_VALUE);
      modalStatus.turnOff();
      queryClient.invalidateQueries({
        queryKey: [API_URLS.EXTERNAL_LINK_API_URL],
      });
    },
  });

  const addExternalLinkMutation = useHgbMutationPost<
    unknown,
    AddExternalLinkPayload
  >(API_URLS.EXTERNAL_LINK_API_URL, {
    onSuccess() {
      updateMethods.reset(UPDATE_LINK_DEFAULT_VALUE);
      modalStatus.turnOff();
      queryClient.invalidateQueries({
        queryKey: [API_URLS.EXTERNAL_LINK_API_URL],
      });
    },
  });

  const deleteExternalLinkMutation = useHgbMutationDelete<unknown, number>(
    API_URLS.EXTERNAL_LINK_API_URL,
    {
      onSuccess() {
        updateMethods.reset(UPDATE_LINK_DEFAULT_VALUE);
        modalStatus.turnOff();
        queryClient.invalidateQueries({
          queryKey: [API_URLS.EXTERNAL_LINK_API_URL],
        });
      },
    },
  );

  const submitLinkHandler: SubmitHandler<ExternalLink> = debounce(
    (formData: ExternalLink) => {
      const isManual = linkType === 'manual';
      const linkName = formData.linkName;
      const url = encodeURIComponent(formData.url);

      if (data) {
        if (type) {
          deleteExternalLinkMutation.mutate(data.id);
          return;
        }
        updateExternalLinkMutation.mutate({
          isManual,
          linkName,
          url,
          id: formData.id?.toString()!,
        });
        return;
      }

      addExternalLinkMutation.mutate({
        isManual,
        linkName,
        url,
      });
    },
    200,
  );

  const submitLinkErrorHandler: SubmitErrorHandler<ExternalLink> = (err) => {
    debug.log({ err });
  };
  const title = React.useMemo(() => {
    if (data) {
      if (type) {
        return CM01.modal.linkDeleteTitle;
      }
      return CM01.modal.linkUpdateTitle;
    }
    return CM01.modal.linkAddTitle;
  }, [
    data,
    type,
    CM01.modal.linkUpdateTitle,
    CM01.modal.linkAddTitle,
    CM01.modal.linkDeleteTitle,
  ]);

  const okText = React.useMemo(() => {
    if (data) {
      if (type) {
        return common.button.delete;
      }
      return common.button.update;
    }
    return common.button.add;
  }, [
    data,
    type,
    common.button.update,
    common.button.add,
    common.button.delete,
  ]);
  return (
    <>
      <Modal
        className="ant-modal-hgb-custom"
        centered
        open={modalStatus.value}
        footer={null}
        title={<HgbAntdModalTitle>{title}</HgbAntdModalTitle>}
        onCancel={handleCancel}
      >
        {type && (
          <p className={'tw-mb-24 tw-px-24 tw-text-h3 tw-font-regular'}>
            {CM01.modal.linkDeleteSubTitle}
          </p>
        )}
        <HgbAntdModalFormBody>
          <FormProvider {...updateMethods}>
            <div className="tw-space-y-24">
              <HgbAntdInput
                {...updateMethods.register('linkName')}
                type="K"
                required
                maxLength={250}
                label={CM01.label.linkName}
                placeholder={CM01.placeholder.linkName}
                readOnly={!!type}
              />
              <HgbAntdInput
                {...updateMethods.register('url')}
                type="K"
                required
                maxLength={250}
                label={CM01.label.url}
                placeholder={CM01.placeholder.url}
                readOnly={!!type}
              />
            </div>
          </FormProvider>
        </HgbAntdModalFormBody>
        <HgbAntdModalFormFooter>
          <HgbAntdButton className="tw-min-w-100" onClick={handleCancel}>
            {common.button.cancel}
          </HgbAntdButton>

          <HgbAntdButton
            type="primary"
            className="tw-min-w-100"
            htmlType="button"
            loading={updateExternalLinkMutation.isLoading}
            onClick={updateMethods.handleSubmit(
              submitLinkHandler,
              submitLinkErrorHandler,
            )}
          >
            {okText}
          </HgbAntdButton>
        </HgbAntdModalFormFooter>
      </Modal>
      {/* <HgbAntdModal
        title={modalTitle[modalStatus.type]}
        cancelText={common.button.cancel}
        okText={modalOkText[modalStatus.type]}
        open={modalStatus.isOpen}
        formProps={{
          onSubmit: handleSubmitUpdate(submitLinkHandler),
        }}
        onCancel={closeModal}
      >
        <FormProvider {...updateMethods}>
          <div className="tw-space-y-24">
            <HgbAntdInput
              {...registerUpdate('linkName')}
              type="K"
              required
              maxLength={250}
              label={CM01.label.linkName}
              placeholder={CM01.placeholder.linkName}
            />
            <HgbAntdInput
              {...registerUpdate('url')}
              type="K"
              required
              maxLength={250}
              label={CM01.label.url}
              placeholder={CM01.placeholder.url}
            />
          </div>
        </FormProvider>
      </HgbAntdModal> */}
    </>
  );
};

export default ExternalLinkModal;
