export type Enterprise = {
  id: string;
  bankId: string;
  bankName: string;
  enterpriseCode: string;
  enterpriseName: string;
  enterpriseNameKana: string;
  representative: string;
  representativeKana: string;
  postalCode: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  startMonthOfYear: string;
  billingStartDate: string;
  industryCodeMajorClassification: string;
  industryCodeMediumClassification: string;
  availableScope3Category: string;
  availableScope3CategoryArray: string[];
  paymentType: string;
  hasAccount: string;
  branchNumber: string;
  corporateAccountNumber: string;
  picEmail: string;
  isInvested: string;
  picName: string;
  picNameKana: string;
  status?: string;
  groupStatus?: 'INACTIVE' | 'REGISTER' | 'PENDING' | 'ACTIVE' | '';
  email?: string;
  expireDate?: string;
  picId: string;
  cif: string;
  groupStatusSelect?: 'INACTIVE' | 'REGISTER' | 'PENDING' | 'ACTIVE' | '';
  createdDate: string;
  kaiinId: string | null;
  kaiinPass: string | null;
  billingStartDateApproved: string | null;
  lastActivityModifyDate: string | null;
};

export const ENTERPRISE_FORM_DEFAULT_VALUE: Enterprise = {
  id: '',
  bankId: '',
  bankName: '',
  enterpriseCode: '',
  enterpriseName: '',
  enterpriseNameKana: '',
  representative: '',
  representativeKana: '',
  postalCode: '',
  address1: '',
  address2: '',
  phoneNumber: '',
  startMonthOfYear: '04',
  industryCodeMajorClassification: '',
  industryCodeMediumClassification: '',
  billingStartDate: '',
  availableScope3Category: '',
  availableScope3CategoryArray: [],
  paymentType: '1',
  hasAccount: '',
  branchNumber: '',
  corporateAccountNumber: '',
  picEmail: '',
  isInvested: '',
  picName: '',
  picNameKana: '',
  groupStatus: '',
  picId: '',
  cif: '',
  groupStatusSelect: 'PENDING',
  createdDate: '',
  kaiinId: null,
  kaiinPass: null,
  billingStartDateApproved: null,
  lastActivityModifyDate: null,
};

export type GetEnterpriseResponse = {
  totalPage: number;
  currentPage: number;
  pageSize: number;
  totalRecord: number;
  content: Enterprise[];
};

export type GetEnterpriseRequest = {
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortName?: string;
  bankId?: string;
  nameSearch?: string;
  status: string;
};

export type CreateEnterpriseRequest = Partial<Enterprise>;
export type CreateEnterpriseResponse = null;

export type UpdateEnterpriseRequest = Partial<Enterprise>;
export type UpdateEnterpriseResponse = null;

export type DeleteEnterpriseRequest = string;
export type DeleteEnterpriseResponse = null;

export interface GetAddressByPostalCodeResponse {
  allCode: string;
  prefCode: string;
  cityCode: string;
  postcode: string;
  oldPostcode: string;
  hiragana: Hiragana;
  halfWidthKana: HalfWidthKana;
  fullWidthKana: FullWidthKana;
  generalPostcode: boolean;
  officePostcode: boolean;
  location: Location;
  pref: string;
  city: string;
  town: string;
  allAddress: string;
}

export interface Hiragana {
  pref: string;
  city: string;
  town: string;
  allAddress: string;
}

export interface HalfWidthKana {
  pref: string;
  city: string;
  town: string;
  allAddress: string;
}

export interface FullWidthKana {
  pref: string;
  city: string;
  town: string;
  allAddress: string;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export type GetEnterpriseByIdRequest = string;
export type GetEnterpriseByIdResponse = Partial<Enterprise>;

export type EnterpriseListBox = {
  value: string;
  name: string;
};

export type GetEnterpriseListBoxRequest = {
  bankId?: string;
  isLoadAllOption?: boolean;
};
export type GetEnterpriseListBoxResponse = EnterpriseListBox[];

export type EnterpriseStatusRequest = {
  hasChildStatuses: boolean;
};

export type StatusInGroup = {
  name: string;
  value: string;
};

export type EnterpriseStatusResponse = {
  name: string;
  value: string;
  statusInGroup?: StatusInGroup;
}[];

export type EnterprisePendingType = {
  id: string;
  email: string;
  expireDate: string;
  status: string;
};

export type EnterprisePendingResponse = EnterprisePendingType[];

export type PicIDListboxRequest = {
  enterpriseId: string;
};
export type PicIDListboxResponse = EnterpriseListBox[];

export type EmailByPicIDRequest = {
  enterpriseId?: string;
  picId: string;
};
export type EmailByPicIDResponse = string;

export type UpdateCardRequest = {
  maskedCardNumber: string;
  id: string;
  email: string;
  kaninId: string;
  kaninPass: string;
};
