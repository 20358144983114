import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import HgbAntdUpload from '@common/antd/HgbAntdUpload';
import { HgbSelect, HgbSelectOption } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { isMobileOs } from '@utils/os.util';
import Encoding from 'encoding-japanese';
import { isEmpty } from 'lodash';
import { FunctionComponent, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQueryDownload } from 'services/common/query';

type ModalStatus = {
  isOpen: boolean;
  numberOfTotalRecords: string;
  numberOfErrorRecords: string;
  byteArrayResource: string;
  fileName: string;
};

type UploadProps = {
  targetFiscalYear: string;
  file: File[];
};

const DEFAULT_VALUE = {
  targetFiscalYear: '',
  file: [],
};

export const C06: FunctionComponent = (props) => {
  const {
    text: { C01, common },
  } = useContext(LanguageContext)!;
  const methods = useForm<UploadProps>({
    defaultValues: DEFAULT_VALUE,
  });
  const { watch, reset } = methods;
  const { addMessage, fileName } = useContext(PortalContext)!;
  const [resetUpload, setResetUpload] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);
  const [statusModal, setStatusModal] = useState<ModalStatus>({
    isOpen: false,
    numberOfErrorRecords: '',
    numberOfTotalRecords: '',
    byteArrayResource: '',
    fileName: '',
  });

  const { refetch: downloadTemplate } = useHgbQueryDownload<any, any>(
    API_URLS.LCIDB_TEMPLATE_DOWNLOAD_API_URL,
    undefined,
    {
      enabled: false,
      onSuccess: (data) => {
        if (!data.file) {
          addMessage('error', C01.message.fileFormatCsv);
          return;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data.file]));
        link.setAttribute('download', 'LCI_DB_Template_csv.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  );

  const { mutate, isLoading } = useHgbMutationPost<any, FormData>(
    API_URLS.LCIDB_TEMPLATE_DOWNLOAD_API_URL,
    {
      onSuccess: (data) => {
        if (data?.statusCode?.toString() === '10011') {
          addMessage('success', data.message ?? '');
          setResetUpload(true);
          return reset({
            ...DEFAULT_VALUE,
            targetFiscalYear:
              targetFiscalYearOptions[0].value ?? new Date().getFullYear(),
          });
        } else {
          if (isEmpty(data.result)) {
            return addMessage('error', data.message ?? '');
          }
          return setStatusModal((prev) => ({
            ...prev,
            isOpen: true,
            byteArrayResource: data.result,
          }));
        }
      },
      isAlert: false,
    },
  );

  const submitFile = async () => {
    setConfirmModal(false);
    const formData = new FormData();
    const { file, targetFiscalYear } = watch();
    file?.forEach((file: File) => {
      formData.append('file', file);
    });
    formData.append('targetFiscalYear', targetFiscalYear);
    mutate(formData);
  };

  const targetFiscalYearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const options: HgbSelectOption[] = [];
    for (let i = 0; i < 10; i++) {
      const valueOptions = (currentYear - i).toString();
      options.push({ label: valueOptions, value: valueOptions });
    }
    return options;
  }, []);

  return (
    <ManagementTemplate>
      <DataTableTemplate title={C01.label.lcidbImport}>
        <FormProvider {...methods}>
          <form className="tw-flex tw-flex-col tw-items-center tw-justify-between">
            {isMobileOs ? (
              <HgbAntdEmpty description={common.message.device_not_match} />
            ) : (
              <div className="tw-flex tw-flex-col tw-gap-24">
                <HgbSelect
                  name={'targetFiscalYear'}
                  className="tw-w-fit"
                  label={C01.label.businessYear}
                  options={targetFiscalYearOptions}
                  autoInitValue
                />
                <HgbAntdUpload
                  accept=".csv"
                  className={`tw-w-300 pc:tw-w-400`}
                  name="file"
                  multiple={true}
                  isReset={resetUpload}
                  setIsReset={setResetUpload}
                />
                <div className="tw-flex tw-flex-col tw-gap-16">
                  <HgbAntdButton
                    loading={isLoading}
                    disabled={isEmpty(watch('file'))}
                    onClick={() => {
                      setConfirmModal(true);
                    }}
                    type="primary"
                  >
                    {C01.label.import}
                  </HgbAntdButton>
                  <HgbAntdButton
                    onClick={() => {
                      downloadTemplate();
                    }}
                    type="primary"
                    ghost
                  >
                    {C01.label.download}
                  </HgbAntdButton>
                </div>
              </div>
            )}
          </form>
          <HgbAntdModal
            okText={C01.modal.csvConfirmModalOkButton}
            cancelText={common.button.cancel}
            title={C01.modal.csvConfirmModalTitle}
            open={confirmModal}
            onCancel={() => setConfirmModal(false)}
            formProps={{
              onSubmit: () => {
                submitFile();
              },
            }}
          >
            <p className="tw-text-center">{C01.modal.csvConfirmModalContent}</p>
          </HgbAntdModal>
          <HgbAntdModal
            okText={C01.modal.csvErrorModalCancelButton}
            cancelText={common.button.close}
            title={C01.modal.csvErrorModalTitle}
            open={statusModal.isOpen}
            onCancel={() => {
              setStatusModal((prev) => ({ ...prev, isOpen: false }));
            }}
            onOk={() => {
              const unicodeArray = Encoding.stringToCode(
                statusModal.byteArrayResource,
              ); // Convert string to code array
              const sjisArray = Encoding.convert(unicodeArray, {
                to: 'SJIS',
                from: 'UNICODE',
              });
              const uint8_array = new Uint8Array(sjisArray);
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(
                new Blob([uint8_array], { type: 'text/csv' }),
              );
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            errorModal
          >
            <p className="tw-text-center">{C01.modal.lcidbErrorModalContent}</p>
          </HgbAntdModal>
        </FormProvider>
      </DataTableTemplate>
    </ManagementTemplate>
  );
};
