import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbInformationIcon } from '@common/icons/filled/HgbInformationIcon';
import { HgbMaintainIcon } from '@common/icons/filled/HgbMaintainIcon';
import { FunctionComponent } from 'react';
import { IPreviewMessageModalProps } from 'services/types/D13';

const PreviewMessageModal: FunctionComponent<IPreviewMessageModalProps> = ({
  title,
  description,
  type,
  ...props
}) => {
  return (
    <HgbAntdModal  {...props} maskClosable={false} destroyOnClose>
      <div className="tw-flex tw-items-start">
        {type === '1' && <HgbMaintainIcon size="24" />}
        {type === '2' && <HgbInformationIcon size="24" />}
        <div className="tw-flex-1">
          <p className="tw-mb-8 tw-text-h4 tw-font-bold">{title}</p>
          <div
            className="ql-editor tw-w-full"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      </div>
    </HgbAntdModal>
  );
};

export default PreviewMessageModal;
