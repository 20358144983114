import {
  CopyOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import {
  HgbAntdRadioGroup,
  HgbAntdRadioOption,
} from '@common/antd/HgbAntdRadioGroup';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbResponsive } from '@common/components';
import { HgbCellTooltip } from '@common/contents';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbDatePicker,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxiosGetSingle } from '@utils/axios';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useHgbMutationDelete } from 'services/common/mutation';
import { useHgbQuery, useHgbQueryWithPaging } from 'services/common/query';
import {
  GetLast10FiscalYearListBoxRequest,
  GetLast10FiscalYearListBoxResponse,
} from 'services/types/year';
import { WithKey } from 'types/common';
import { v4 } from 'uuid';
import {
  AddSupplierAnswerModal,
  GetSupplierEmissionInputResponse,
} from '../H03/AddSupplierAnswerModal';
import { DuplicateSupplierAnswerModal } from '../H03/DuplicateSupplierAnswerModal';
import { EditSupplierAnswerModal } from '../H03/EditSupplierAnswerModal';

const TYPE_TIME = {
  FISCAL_YEAR: 'FISCAL_YEAR',
  MONTH: 'MONTH',
};

const STATUS_SUPPLIER = {
  pending: 'PENDING',
  register: 'REGISTER',
  returned: 'RETURNED',
};

type GroupBuyerResponse = {
  value: string;
  name: string;
};

type GroupBuyerRequest = {
  groupBuyerId: string;
};

type SupplierSearchRequest = {
  buyerId: string;
  supplyChainName?: string;
  dateType?: string;
  fiscalYear?: string;
  startMonth?: string;
  endMonth?: string;
} & Partial<PaginationProps>;

export type SupplierSubmission = {
  id: number;
  supplyChainGroupName: string;
  supplierName: string;
  buyerName: string;
  responseType: { value: string; name: string };
  targetStartMonth: string;
  targetEndMonth: string;
  productName: string;
  baseName: string;
  quantity: number;
  emissionIntensity: string;
  baseVolume: string;
  allocation: number;
  emissionVolume: string;
  sentTime: string;
  status: { value: string; name: string };
  responseSummary: string;
};

const SUPPLIER_SEARCH_REQUEST_DEFAULT: SupplierSearchRequest = {
  buyerId: '',
  // supplyChainName: undefined,
  dateType: TYPE_TIME.FISCAL_YEAR,
  // fiscalYear: undefined,
  // startMonth: undefined,
  // endMonth: undefined,
};

export const SUPPLIER_RESPONSE_TYPE = {
  PRODUCT: '1',
  ORG: '2',
  MANUAL: '3',
} as const;

export const useOptionsSupplier = (): HgbSelectOption[] => {
  const {
    text: { H02 },
  } = useContext(LanguageContext)!;

  return [
    { label: H02.productUnit, value: SUPPLIER_RESPONSE_TYPE.PRODUCT },
    { label: H02.organizationalUnit, value: SUPPLIER_RESPONSE_TYPE.ORG },
  ];
};

const H02 = () => {
  const location = useLocation();
  const {
    text: { common, H02, C01, E0041, E0091, E0049 },
    language,
  } = useContext(LanguageContext)!;
  const { addMessage } = useContext(PortalContext)!;

  const searchMethods = useForm<SupplierSearchRequest>({
    defaultValues: SUPPLIER_SEARCH_REQUEST_DEFAULT,
  });

  const FISCAL_YEAR_OPTIONS: HgbAntdRadioOption<string>[] = [
    {
      label: H02.fiscalYear,
      value: TYPE_TIME.FISCAL_YEAR,
    },
    {
      label: C01.label.targetYearMonth,
      value: TYPE_TIME.MONTH,
    },
  ];

  const { paginationProps, PaginationComponent } = usePagin();
  const queryClient = useQueryClient();

  const { ConfirmModal: ConfirmModalDelete, hgbConfirm: hgbConfirmDelete } =
    useConfirm();
  const { ConfirmModal: ConfirmModalAction, hgbConfirm: hgbConfirmAction } =
    useConfirm();
  const addModalState = useBooleanState(false);
  const editModalState = useBooleanState(false);
  const [editDataId, setEditDataId] = useState<number>();
  const [editDataStatus, setEditDataStatus] = useState<string>();
  const [detailData, setDetaiData] =
    useState<GetSupplierEmissionInputResponse>();
  const duplicateModalState = useBooleanState(false);
  const [selectedOwner, setSelectedOwner] = useState<SupplierSubmission>();
  const maxLength = 50;
  const [searchBuyer, setSearchsearchBuyer] = useState<string>('');
  const [searchSupplyChainName, setsearchSupplyChainName] =
    useState<string>('');
  const handleSearchBuyer = (value: string) => {
    setSearchsearchBuyer(value.substring(0, maxLength));
  };
  const handleSearchSupplier = (value: string) => {
    setsearchSupplyChainName(value.substring(0, maxLength));
  };

  const supplierParams: SupplierSearchRequest | undefined = useMemo(() => {
    const rs: SupplierSearchRequest = {
      buyerId: searchMethods.watch('buyerId'),
      supplyChainName: searchMethods.watch('supplyChainName'),
      startMonth:
        searchMethods.watch('dateType') === TYPE_TIME.MONTH
          ? searchMethods.watch('startMonth')?.split('/').join('')
          : undefined,
      endMonth:
        searchMethods.watch('dateType') === TYPE_TIME.MONTH
          ? searchMethods.watch('endMonth')?.split('/').join('')
          : undefined,
      fiscalYear:
        searchMethods.watch('dateType') === TYPE_TIME.FISCAL_YEAR
          ? searchMethods.watch('fiscalYear')
          : undefined,
      ...paginationProps,
    };
    return rs;
  }, [searchMethods.watch(), paginationProps]);

  const supplierSubmissionQuery = useHgbQueryWithPaging<
    SupplierSubmission[],
    SupplierSearchRequest
  >(API_URLS.SUPPLIER_SUPPLIER_RESPONSE, supplierParams, {
    enabled:
      supplierParams.buyerId !== SUPPLIER_SEARCH_REQUEST_DEFAULT.buyerId &&
      supplierParams.supplyChainName !==
        SUPPLIER_SEARCH_REQUEST_DEFAULT.supplyChainName,
    queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST, supplierParams, language],
  });

  const groupBuyerQuery = useHgbQuery<GroupBuyerResponse[]>(
    API_URLS.LISTBOX_GROUP_BUYER,
    undefined,
    {
      queryKey: [QUERY_KEYS.LISTBOX_GROUP_BUYER, language],
    },
  );

  const groupSupplyChainQuery = useHgbQuery<
    GroupBuyerResponse[],
    GroupBuyerRequest
  >(
    API_URLS.LISTBOX_SUPPLY_CHAIN,
    { groupBuyerId: searchMethods.watch('buyerId') },
    {
      enabled:
        searchMethods.watch('buyerId') !==
        SUPPLIER_SEARCH_REQUEST_DEFAULT.buyerId,
      queryKey: [
        QUERY_KEYS.LISTBOX_SUPPLY_CHAIN,
        searchMethods.watch('buyerId'),
        language,
      ],
    },
  );

  const { data: yearLBQuery } = useHgbQuery<
    GetLast10FiscalYearListBoxResponse,
    GetLast10FiscalYearListBoxRequest
  >(API_URLS.LAST_TEN_FISCAL_YEAR, undefined);

  const suspendSupplierProductMutate = useHgbMutationDelete<unknown, number>(
    API_URLS.SUPPLIER_SUPPLIER_RESPONSE,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
      },
    },
  );

  const targetYearOptions = useMemo(
    () =>
      parseToHgbSelectOptions(yearLBQuery?.result).filter(
        (word) => Number(word.value) > 2018,
      ),
    [yearLBQuery?.result],
  );

  const groupBuyerList = useMemo(() => {
    return parseToHgbSelectOptions(groupBuyerQuery.data?.result);
  }, [groupBuyerQuery.data?.result]);

  const supplyChainList = useMemo(() => {
    return parseToHgbSelectOptions(groupSupplyChainQuery.data?.result);
  }, [groupSupplyChainQuery.data?.result]);

  const dateErrorMessage = useMemo(() => {
    let startMonth = moment(searchMethods.watch('startMonth'), 'YYYY/MM');
    let endMonth = moment(searchMethods.watch('endMonth'), 'YYYY/MM');
    if (startMonth.isAfter(endMonth)) {
      return E0049;
    }
    return undefined;
  }, [searchMethods.watch('startMonth'), searchMethods.watch('endMonth')]);

  const handelNextActionDuplicate = async (id: number, value: any) => {
    const responseDetailSupplier = await hgbAxiosGetSingle<any>(
      API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW,
      id,
    );
    if (responseDetailSupplier?.response) {
      if (
        responseDetailSupplier?.response.data.statusCode ===
          STATUS_CODE.orgBaseNotExit ||
        responseDetailSupplier?.response.data.statusCode ===
          STATUS_CODE.productNotExit
      ) {
        addMessage('error', responseDetailSupplier?.response.data.message);
        return;
      }
    }
    setEditDataId(id);
    setEditDataStatus(value);
    setDetaiData(responseDetailSupplier);
    duplicateModalState.turnOn();
  };

  const handelNextActionEdit = async (id: number, value: any) => {
    const responseDetailSupplier = await hgbAxiosGetSingle<any>(
      API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW,
      id,
    );
    if (responseDetailSupplier?.response) {
      if (
        responseDetailSupplier?.response.data.statusCode ===
          STATUS_CODE.orgBaseNotExit ||
        responseDetailSupplier?.response.data.statusCode ===
          STATUS_CODE.productNotExit
      ) {
        addMessage('error', responseDetailSupplier?.response.data.message);
        return;
      }
    }
    setEditDataId(id);
    setEditDataStatus(value);
    setDetaiData(responseDetailSupplier);
    editModalState.turnOn();
  };

  const handleDelete = async (record: SupplierSubmission) => {
    setSelectedOwner(record);
    const result = await hgbConfirmDelete();
    if (result) {
      const response = await hgbAxiosGetSingle<SupplierSubmission>(
        API_URLS.SUPPLIER_SUPPLIER_RESPONSE,
        record.id,
      );
      if (response?.status?.value !== record.status?.value) {
        addMessage('error', E0091);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        return;
      }
      suspendSupplierProductMutate.mutate(record?.id);
    }
  };

  const handleDuplicate = async (record: SupplierSubmission) => {
    const responseDetailSupplierChecked = await hgbAxiosGetSingle<any>(
      API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW,
      record.id,
      { checked: true },
    );
    setSelectedOwner(record);
    const response = await hgbAxiosGetSingle<SupplierSubmission>(
      API_URLS.SUPPLIER_SUPPLIER_RESPONSE,
      record.id,
    );
    if (response?.status?.value !== record.status?.value) {
      addMessage('error', E0041);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
      });
      return;
    }
    if (
      responseDetailSupplierChecked?.response?.data?.statusCode ===
      STATUS_CODE.scopeEmissionsChanged
    ) {
      const result = await hgbConfirmAction();
      if (result) {
        handelNextActionDuplicate(record.id, record.status.value);
      }
    } else {
      handelNextActionDuplicate(record.id, record.status.value);
    }
  };

  const handleEdit = async (record: SupplierSubmission) => {
    const responseDetailSupplierChecked = await hgbAxiosGetSingle<any>(
      API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW,
      record.id,
      { checked: true },
    );
    setSelectedOwner(record);
    const response = await hgbAxiosGetSingle<SupplierSubmission>(
      API_URLS.SUPPLIER_SUPPLIER_RESPONSE,
      record.id,
    );
    if (response?.status?.value !== record.status?.value) {
      addMessage('error', E0091);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
      });
      return;
    }
    if (
      responseDetailSupplierChecked?.response?.data?.statusCode ===
      STATUS_CODE.scopeEmissionsChanged
    ) {
      const result = await hgbConfirmAction();
      if (result) {
        handelNextActionEdit(record.id, record.status.value);
      }
    } else {
      handelNextActionEdit(record.id, record.status.value);
    }
  };

  useEffect(() => {
    if (location.state) {
      searchMethods.reset({
        ...SUPPLIER_SEARCH_REQUEST_DEFAULT,
        buyerId: location.state?.buyerId?.toString(),
        supplyChainName: location.state?.supplyChainName?.toString(),
      });
    }

    return () => {
      if (location.state !== null) {
        location.state = null;
      }
    };
  }, [location.state]);

  const columns: ColumnsType<SupplierSubmission> = [
    {
      title: H02.columns.sentDateTime,
      dataIndex: 'sentTime',
      width: 150,
      align: 'left',
    },
    {
      title: H02.columns.status,
      dataIndex: 'status',
      width: 130,
      align: 'left',
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: common.label.groupBuyer,
      dataIndex: 'buyerName',
      width: 150,
      align: 'left',
      render(value) {
        return <HgbCellTooltip>{value}</HgbCellTooltip>;
      },
    },
    {
      title: common.columns.supplyChainGroupName,
      dataIndex: 'supplyChainGroupName',
      width: 230,
      align: 'left',
      render(value) {
        return <HgbCellTooltip>{value}</HgbCellTooltip>;
      },
    },
    {
      title: H02.columns.responseType,
      dataIndex: 'responseType',
      width: 140,
      align: 'left',
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: H02.responsePeriod,
      dataIndex: '_',
      width: 220,
      align: 'left',
      render(_, record) {
        return (
          <div>
            {moment(record.targetStartMonth, 'YYYYMM').format('YYYY/MM')} ~{' '}
            {moment(record.targetEndMonth, 'YYYYMM').format('YYYY/MM')}
          </div>
        );
      },
    },
    {
      title: H02.columns.detail,
      dataIndex: '_',
      width: 200,
      align: 'left',
      render(_, record) {
        return (
          <HgbCellTooltip row={2}>
            {record?.responseType?.value === SUPPLIER_RESPONSE_TYPE.PRODUCT ? (
              record?.productName
            ) : (
              <div>
                {record?.baseName} <br />
                {record?.allocation}%
              </div>
            )}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: H02.columns.emissionVolume,
      dataIndex: 'emissionVolume',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <div>
            {value}
            <br />
            tCO2
          </div>
        );
      },
    },
    {
      title: common.label.action,
      dataIndex: '_',
      align: 'center',
      render(_, record) {
        return (
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => handleEdit(record)}
              icon={<EditOutlined />}
              disabled={record?.status?.value === STATUS_SUPPLIER.register}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              icon={<CopyOutlined />}
              onClick={() => handleDuplicate(record)}
            >
              {common.button.duplicate}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => handleDelete(record)}
              icon={<StopOutlined />}
              disabled={record?.status?.value === STATUS_SUPPLIER.register}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        );
      },
    },
  ];

  const rows: WithKey<SupplierSubmission>[] = useMemo(() => {
    return (supplierSubmissionQuery?.data?.result?.content ?? []).map(
      (item) => ({
        ...item,
        key: item.id,
      }),
    );
  }, [supplierSubmissionQuery?.data?.result?.content]);

  return (
    <ManagementTemplate>
      <FormProvider {...searchMethods}>
        <DataTableTemplate
          title={H02.title}
          buttonComponent={
            <HgbAntdButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={addModalState.turnOn}
            >
              {common.button.register}
            </HgbAntdButton>
          }
          paginationComponents={
            <PaginationComponent
              current={supplierSubmissionQuery.data?.result?.currentPage}
              total={supplierSubmissionQuery.data?.result?.totalRecord}
              pageSize={supplierSubmissionQuery.data?.result?.pageSize}
            />
          }
          inputsComponent={
            <HgbResponsive gap className="tw-flex tw-flex-wrap tw-items-start">
              <HgbSelect
                {...searchMethods.register('buyerId')}
                options={groupBuyerList}
                placeholder=""
                label={common.label.groupBuyer}
                showSearch
                autoInitValue={!location.state?.buyerId}
                searchValue={searchBuyer}
                onSearch={handleSearchBuyer}
                className="tw-w-220"
              />

              <HgbSelect
                {...searchMethods.register('supplyChainName')}
                options={supplyChainList}
                placeholder=""
                label={common.columns.supplyChainGroupName}
                showSearch
                autoInitValue={!location.state?.supplyChainName}
                searchValue={searchSupplyChainName}
                onSearch={handleSearchSupplier}
                className="tw-w-220"
              />

              <div className="tw-flex tw-flex-col">
                <label
                  htmlFor={v4()}
                  className="tw-mb-4 tw-flex tw-shrink-0 tw-select-none tw-gap-4 tw-text-paragraph tw-font-bold"
                >
                  {H02.responsePeriod}
                </label>
                <div className="tw-flex tw-flex-col tw-gap-x-16 pc:tw-flex-row pc:tw-items-start">
                  <HgbAntdRadioGroup
                    options={[FISCAL_YEAR_OPTIONS[0]]}
                    {...searchMethods.register('dateType')}
                    value={searchMethods.watch('dateType')}
                  />
                  <HgbSelect
                    disabled={
                      isEmpty(targetYearOptions) ||
                      searchMethods.watch('dateType') === TYPE_TIME.MONTH
                    }
                    options={targetYearOptions}
                    {...searchMethods.register('fiscalYear')}
                    placeholder={C01.placeholder.fiscalYear}
                    className="tw-w-120"
                    autoInitValue
                  />

                  <HgbAntdRadioGroup
                    options={[FISCAL_YEAR_OPTIONS[1]]}
                    {...searchMethods.register('dateType')}
                    className="tw-shrink-0"
                    value={searchMethods.watch('dateType')}
                  />
                  <div className="tw-flex tw-gap-16">
                    <HgbDatePicker
                      picker="month"
                      {...searchMethods.register('startMonth')}
                      format="YYYY/MM"
                      className="tw-w-120 tw-cursor-pointer"
                      disabled={
                        searchMethods.watch('dateType') ===
                        TYPE_TIME.FISCAL_YEAR
                      }
                      allowClear
                    />
                    <div className="tw-mt-20 tw-h-1 tw-w-8 tw-shrink-0 tw-bg-neutral-8"></div>
                    <HgbDatePicker
                      picker="month"
                      {...searchMethods.register('endMonth')}
                      format="YYYY/MM"
                      className="tw-w-120 tw-cursor-pointer"
                      disabled={
                        searchMethods.watch('dateType') ===
                        TYPE_TIME.FISCAL_YEAR
                      }
                      allowClear
                    />
                    {searchMethods.watch('dateType') === TYPE_TIME.MONTH &&
                      dateErrorMessage && (
                        <HgbFieldError>{dateErrorMessage}</HgbFieldError>
                      )}
                  </div>
                </div>
              </div>
            </HgbResponsive>
          }
        >
          {rows.length > 0 ? (
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              className="tw-overflow-hidden"
              scroll={{ x: 'max-content' }}
            />
          ) : null}

          {supplierSubmissionQuery.isLoading ? (
            <HgbAntdSpin placement="middle" />
          ) : null}

          {supplierSubmissionQuery.isFetched && rows.length === 0 ? (
            <HgbAntdEmpty description={common.message.noResult} />
          ) : null}
        </DataTableTemplate>
      </FormProvider>

      <AddSupplierAnswerModal modalState={addModalState} />
      <EditSupplierAnswerModal
        modalState={editModalState}
        id={editDataId}
        status={editDataStatus}
        detailData={detailData}
      />
      <DuplicateSupplierAnswerModal
        modalState={duplicateModalState}
        id={editDataId}
        status={editDataStatus}
        detailData={detailData}
      />
      <ConfirmModalDelete
        title={H02.modal.title}
        subTitle={H02.modal.announcementMessage}
        okText={common.button.delete}
        cancelText={common.button.cancel}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      >
        <p>{H02.modal.GroupBuyer}&nbsp;:</p>
        <p className="tw-break-all">&nbsp;{selectedOwner?.buyerName}</p>
        <p>{H02.modal.groupName}&nbsp;:</p>
        <p className="tw-break-all">
          &nbsp;{selectedOwner?.supplyChainGroupName}
        </p>
        <p>{H02.responsePeriod}&nbsp;:</p>
        <p className="tw-break-all">
          &nbsp;
          {moment(selectedOwner?.targetStartMonth, 'YYYYMM').format(
            'YYYY/MM',
          )}{' '}
          ~ {moment(selectedOwner?.targetEndMonth, 'YYYYMM').format('YYYY/MM')}
        </p>
        <p>{common.label.emissions}&nbsp;:</p>
        <p className="tw-break-all">
          &nbsp;{selectedOwner?.emissionVolume} tCO2
        </p>
      </ConfirmModalDelete>
      <ConfirmModalAction
        title={
          <ExclamationCircleOutlined className="tw-text-[50px] tw-text-[#fd7e14]" />
        }
        okText={common.button.confirm}
        cancelText={common.button.cancel}
        className="tw-font-regular"
      >
        {H02.modalAction.content(
          selectedOwner?.baseName || '',
          moment(selectedOwner?.targetStartMonth, 'YYYYMM').format('YYYY/MM'),
          moment(selectedOwner?.targetEndMonth, 'YYYYMM').format('YYYY/MM'),
        )}
      </ConfirmModalAction>
    </ManagementTemplate>
  );
};

export default H02;
