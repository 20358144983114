import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { useDebounce } from '@hooks/useDebound';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxiosGetSingle } from '@utils/axios';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useHgbMutationDelete } from 'services/common/mutation';
import { useHgbQueryWithPaging } from 'services/common/query';
import { WithKey } from 'types/common';
import { CertificateDetail, CertificateModalMode } from '../C09/C09';
import { CertificateModal } from './CertificateModal';

export type EnergyCertificate = {
  id: number;
  certificateKind: GreenEnergyOptions;
  useVolume: number;
  deductedUseVolume: number;
  calculationMethodCode: GreenEnergyOptions;
  issueDate: string;
  startDate: string;
  endDate: string;
  status: string;
  certificateNumber: string;
  unit: string;
  certificateName: string;
};

export type GreenEnergyOptions = {
  value: string;
  name: string;
};

export type GetListEnergyCetificateRequest = PaginationProps & {
  sortBy?: string;
  nameSearch?: string;
};

const C07 = () => {
  const {
    text: { common, C07 },
  } = useContext(LanguageContext)!;

  const certificateMethods = useForm<{ nameSearch: string }>({
    values: { nameSearch: '' },
  });
  const {
    paginationProps,
    resetPaginations,
    PaginationComponent,
    setPaginationProps,
  } = usePagin();

  useEffect(() => {
    resetPaginations();
  }, [certificateMethods.watch('nameSearch')]);

  const nameSearchDebounce = useDebounce(
    certificateMethods.watch('nameSearch'),
    300,
  );

  const { language } = useContext(LanguageContext)!;
  const { addMessage } = useContext(PortalContext)!;
  const [mode, setMode] = useState<CertificateModalMode>('ADD');
  const [confirmData, setConfirmData] = useState<EnergyCertificate>();
  const [editData, setEditData] = useState<EnergyCertificate>();
  const editModalState = useBooleanState(false);
  const responseModalState = useBooleanState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { ConfirmModal, hgbConfirm } = useConfirm();

  const listEnergyCertificateRequest: GetListEnergyCetificateRequest = {
    ...paginationProps,
    nameSearch: nameSearchDebounce,
  };

  const listEnergyCertificateQuery = useHgbQueryWithPaging<
    EnergyCertificate[],
    GetListEnergyCetificateRequest
  >(API_URLS.ENERGY_CERTIFICATE, listEnergyCertificateRequest, {
    queryKey: [
      QUERY_KEYS.ENERGY_CERTIFICATE,
      listEnergyCertificateRequest,
      language,
    ],
  });

  const deleteEnergyCertificateMutate = useHgbMutationDelete<unknown, number>(
    API_URLS.ENERGY_CERTIFICATE,
    {
      onSuccess: () => {
        if (rows?.length === 1) {
          setPaginationProps((prev) => ({
            ...prev,
            pageNo: prev.pageNo > 0 ? prev.pageNo - 1 : 0,
          }));
        } else {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.ENERGY_CERTIFICATE],
          });
        }
        responseModalState.turnOff();
      },
      onError: () => {},
    },
  );

  const handleRegister = () => {
    if (editData) {
      setEditData(undefined);
    }
    setMode('ADD');
    editModalState.turnOn();
  };

  const handleEdit = async (record: EnergyCertificate) => {
    const certificateDetail = await hgbAxiosGetSingle<EnergyCertificate>(
      API_URLS.ENERGY_CERTIFICATE,
      record.id,
    );

    if (certificateDetail?.response) {
      if (
        certificateDetail?.response?.data?.statusCode === STATUS_CODE.notFound
      ) {
        addMessage('error', certificateDetail?.response?.data?.message);
        return;
      }
    }

    if (certificateDetail?.id) {
      setEditData(certificateDetail);
      setMode('EDIT');
      editModalState.turnOn();
    }
  };

  const handleDelete = async (record: EnergyCertificate) => {
    setConfirmData(record);
    const result = await hgbConfirm();
    if (result) {
      deleteEnergyCertificateMutate.mutate(record?.id);
    }
  };

  const handleCertificateActivities = (id: number) => {
    return navigate(routs_en['/certificate/detail'].link, {
      state: id,
    });
  };

  const columns: ColumnsType<EnergyCertificate> = [
    {
      title: C07.column.creditID,
      dataIndex: 'id',
      width: 100,
      className: 'tw-max-w-200',
      align: 'center',
    },
    {
      title: C07.certificateNo,
      dataIndex: 'certificateNumber',
      width: 160,
      className: 'tw-max-w-200',
      render(value, record) {
        return (
          <HgbCellLink
            className="tw-cursor-pointer"
            onClick={() => handleCertificateActivities(record.id)}
          >
            <HgbCellTooltip>{value}</HgbCellTooltip>
          </HgbCellLink>
        );
      },
    },
    {
      title: C07.certificateName,
      dataIndex: 'certificateName',
      width: 150,
      className: 'tw-max-w-200',
    },
    {
      title: C07.certificateType,
      dataIndex: 'certificateKind',
      width: 150,
      className: 'tw-max-w-200',
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: C07.column.generatedPowerAmount,
      dataIndex: 'useVolume',
      width: 220,
      align: 'center',
      render(value, record) {
        return (
          <div>
            {value} {record?.unit}
          </div>
        );
      },
    },
    {
      title: C07.column.deductedAmount,
      dataIndex: 'deductedUseVolume',
      width: 160,
      align: 'center',
      render(value, record) {
        return (
          <div>
            {value} {record?.unit}
          </div>
        );
      },
    },
    {
      title: C07.column.remainingAmount,
      dataIndex: 'deductedUseVolume',
      width: 170,
      align: 'center',
      render(value, record) {
        return (
          <div>
            {record?.useVolume - value} {record?.unit}
          </div>
        );
      },
    },
    {
      title: C07.column.certificateIssueDate,
      dataIndex: 'issueDate',
      align: 'center',
      width: 180,
    },
    {
      title: C07.certificateValidityPeriod,
      dataIndex: '',
      align: 'center',
      width: 210,
      render(_, record) {
        return (
          (record?.startDate || record?.endDate) && (
            <div className="tw-whitespace-nowrap">
              {record?.startDate} ～ {record?.endDate}
            </div>
          )
        );
      },
    },

    {
      title: C07.column.action,
      dataIndex: 'status',
      align: 'center',
      render(_, record) {
        return (
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-24">
            <HgbActionButton
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
              disabled={record?.deductedUseVolume > 0}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        );
      },
    },
  ];

  const rows: WithKey<EnergyCertificate>[] = useMemo(() => {
    return (listEnergyCertificateQuery?.data?.result?.content ?? []).map(
      (item) => ({
        ...item,
        key: item.id,
      }),
    );
  }, [listEnergyCertificateQuery?.data?.result?.content]);

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={C07.title}
        buttonComponent={
          <HgbAntdButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleRegister}
          >
            {common.button.register}
          </HgbAntdButton>
        }
        paginationComponents={
          <PaginationComponent
            current={listEnergyCertificateQuery.data?.result?.currentPage}
            total={listEnergyCertificateQuery.data?.result?.totalRecord}
            pageSize={listEnergyCertificateQuery.data?.result?.pageSize}
          />
        }
        inputsComponent={
          <div className="tw-rounded-8 tw-bg-primary-1/20 tw-p-16">
            <FormProvider {...certificateMethods}>
              <HgbAntdInput
                {...certificateMethods.register('nameSearch')}
                type="K"
                maxLength={20}
                search
                className="pc:tw-w-240"
                placeholder={common.placeholder.filter}
              />
            </FormProvider>
          </div>
        }
      >
        {rows.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            className="tw-overflow-hidden"
            scroll={{ x: 'max-content' }}
          />
        ) : null}

        {listEnergyCertificateQuery.isLoading ? (
          <HgbAntdSpin placement="middle" />
        ) : null}

        {listEnergyCertificateQuery.isFetched && rows.length === 0 ? (
          <HgbAntdEmpty description={common.message.noResult} />
        ) : null}
      </DataTableTemplate>

      <ConfirmModal
        cancelText={common.button.cancel}
        okText={common.button.delete}
        title={C07.modal.titleModalConfirm}
        subTitle={C07.modal.attentionMessage}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      >
        <p>{C07.certificateType}&nbsp;:</p>
        <p>&nbsp;{confirmData?.certificateKind?.name}</p>
        <p>
          {confirmData?.calculationMethodCode?.value === 'M00005'
            ? C07.generatedPowerAmount
            : C07.generatedHeatAmount}
          &nbsp;:
        </p>
        <p>
          &nbsp;{confirmData?.useVolume} {confirmData?.unit}
        </p>
        <p>{C07.modal.certificateIssueDate}&nbsp;:</p>
        <p>&nbsp;{confirmData?.issueDate}</p>
        <p>{C07.certificateValidityPeriod}&nbsp;:</p>

        {confirmData?.startDate || confirmData?.endDate ? (
          <p>
            &nbsp;
            {confirmData?.startDate} ～ {confirmData?.endDate}
          </p>
        ) : (
          <p></p>
        )}

        <p>
          {confirmData?.certificateKind?.value === 'EC001'
            ? C07.certificateNo
            : C07.serialNo}
          &nbsp;:
        </p>
        <p className="tw-break-all">&nbsp;{confirmData?.certificateNumber}</p>

        <p>
          {C07.certificateName}
          &nbsp;:
        </p>
        <p className="tw-break-all">&nbsp;{confirmData?.certificateName}</p>
      </ConfirmModal>

      {editModalState?.value && (
        <CertificateModal
          modalState={editModalState}
          CertificateDetail={editData}
          mode={mode}
        />
      )}
    </ManagementTemplate>
  );
};

export default C07;
