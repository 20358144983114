export const PASSWORD_REGEX = new RegExp(
  '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*\\W).+$',
);
export const EMAIL_REGEX = new RegExp(
  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
);
export const DATE_REGEX = new RegExp('^([1-9]\\d{3})\\/(0[1-9]|1[0-2])$');
export const SPLIT_FORMULA_FIELDS_REGEX = new RegExp(
  '[\\s()\\-+*\\/^－.]',
  'g',
);
export const FULL_SIZE_REGEX = new RegExp('[\\uff01-\\uff5e]', 'g');
export const DOUBLE_BYTES_SPACE_REGEX = new RegExp('\u3000', 'g');
export const PLUS_MINUS_SIGN_REGEX = new RegExp('[\\+\\-]', '');
export const ONE_OR_MORE_NONE_ALPHA_NUMERIC_REGEX = new RegExp(
  '[^a-zA-Z0-9\\-]+',
  'g',
);
