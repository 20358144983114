import {
  BellOutlined,
  CloseOutlined,
  GlobalOutlined,
  LogoutOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { SquareButton } from '@common/forms';
import { HgbInformationIcon } from '@common/icons/filled/HgbInformationIcon';
import { HgbMaintainIcon } from '@common/icons/filled/HgbMaintainIcon';
import {
  HgbCloseOutlinedIcon,
  HgbMenuOutlinedIcon,
} from '@common/icons/outlined';
import { HgbNavbarLogo } from '@common/logos';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useMatchWidth } from '@hooks/useMatchWidth';
import { MessageHistoryResponseType } from '@pages/Dashboard/B02/types';
import PreviewMessageModal from '@pages/Management/Support/D13/PreviewMessageModal';
import { Button, Drawer, Menu, MenuProps } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import { Language, LocalDataClass } from 'data-class/LocalDataClass';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useHgbQuery } from 'services/common/query';
import { IPreviewMessageModalProps } from 'services/types/D13';
import { ExternalLink } from 'services/types/externalLink';
import { v4 } from 'uuid';
import ExternalLinkModal, { ExternalLinkType } from './ExternalLinkModal';
import { HgbExternalLink, SubMenuTitle, useExternalLink } from './utils';

type MenuItem = Required<MenuProps>['items'][number];

const getSelectedKeys = (pathname: string) => {
  const list = pathname.split('/').filter((item) => item !== '');
  return ['/' + list[0], ...list.slice(1), pathname];
};

export const HgbNavbar: React.FC = () => {
  const {
    routs,
    text: { CM01, common },
  } = useContext(LanguageContext)!;

  const messageRef = useRef<HTMLDivElement>(null);

  const { normalLinks, manualLinks } = useExternalLink();

  const { language } = useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;
  const { logout } = React.useContext(AuthContext)!;
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [previewModalProps, setPreviewModalProps] = useState<
    IPreviewMessageModalProps & { id: string }
  >({
    open: false,
    title: '',
    description: '',
    id: '',
  });

  useEffect(() => {
    const removeAllEventHandler = () => {
      setPreviewModalProps((prev) => ({ ...prev, open: false, id: '' }));
    };
    window.addEventListener('removeAll', removeAllEventHandler);
    return () => window.removeEventListener('removeAll', removeAllEventHandler);
  }, []);

  const { data: messageHistoryQuery } = useHgbQuery<
    MessageHistoryResponseType,
    undefined
  >(API_URLS.MESSAGE_HISTORY_API_URL);

  const bgclass = useMemo(() => {
    if (user.isPresidingBank) {
      return 'tw-bg-[#dcfce7]';
    }
    if (user.isManagementBank) {
      return 'tw-bg-[#fef3c7]';
    }
    return 'tw-bg-white';
  }, [user]);

  const mobileMobileClass = useMemo(() => {
    if (user.isPresidingBank) {
      return '[&_.ant-drawer-header]:tw-bg-[#dcfce7]';
    }
    if (user.isManagementBank) {
      return '[&_.ant-drawer-header]:tw-bg-[#fef3c7]';
    }
    return 'tw-bg-white';
  }, [user]);
  const onClick: MenuProps['onClick'] = (e) => {
    e.key === routs['/logout'].link && logout();
    !e.keyPath.includes('/lang') && navigate(e.key);
    if (e.keyPath.includes('/lang')) {
      const lang = e.key.split('/')[2] as Language;
      LocalDataClass.language = lang;
    }
  };

  const closeMenu = () => setOpen(false);
  const openMenu = () => setOpen(true);
  const divRef = useRef<HTMLDivElement | null>(null);
  const isMatch = useMatchWidth('greatThan', 1190, divRef);

  const items: MenuProps['items'] = [
    getItem(routs['/dashboard'].name, routs['/dashboard'].link),
    getItem(
      <SubMenuTitle showIcon={isMatch}>{CM01.label.inputUpload}</SubMenuTitle>,
      '/data-entry-parent',
      undefined,
      [
        getItem(routs['/data-entry'].name, routs['/data-entry'].link),
        user.isEnterpriseAdmin || user.isEnterpriseUser
          ? getItem(routs['/certificate'].name, routs['/certificate'].link)
          : null,
      ],
    ),
    getItem(
      <SubMenuTitle showIcon={isMatch}>{routs['/report'].name}</SubMenuTitle>,
      '/report',
      undefined,
      [
        getItem(routs['/report/emission'].name, routs['/report/emission'].link),
        user.isManagementBank || user.isPresidingBank
          ? getItem(
              routs['/report/company'].name,
              routs['/report/company'].link,
            )
          : null,
      ],
    ),
    user.isEnterpriseAdmin
      ? getItem(
          <SubMenuTitle showIcon={isMatch}>
            {CM01.label.supplyChain}
          </SubMenuTitle>,
          '/supply-chain',
          undefined,
          [
            getItem(
              routs['/group/supplier-receive'].name,
              routs['/group/supplier-receive'].link,
            ),
            getItem(
              routs['/group/supplier-submission'].name,
              routs['/group/supplier-submission'].link,
            ),
          ],
        )
      : null,
    getItem(
      <SubMenuTitle showIcon={isMatch}>
        {routs['/management'].name}
      </SubMenuTitle>,
      '/management',
      undefined,
      [
        user.isManagementBank || user.isPresidingBank
          ? getItem(
              routs['/management/bank'].name,
              routs['/management/bank'].link,
            )
          : null,
        ...(user.isEnterpriseUser
          ? []
          : [
              user.isEnterpriseAdmin
                ? getItem(
                    routs['/management/supply-chain'].name,
                    routs['/management/supply-chain'].link,
                    undefined,
                    [
                      getItem(
                        routs['/management/supplychain-buyer'].name,
                        routs['/management/supplychain-buyer'].link,
                      ),
                      getItem(
                        routs['/management/supplychain-supplier'].name,
                        routs['/management/supplychain-supplier'].link,
                      ),
                      getItem(
                        routs['/management/supplier-product'].name,
                        routs['/management/supplier-product'].link,
                      ),
                    ],
                  )
                : null,
              getItem(
                routs['/management/company'].name,
                routs['/management/company'].link,
              ),
              getItem(
                routs['/management/organization'].name,
                routs['/management/organization'].link,
              ),
              getItem(
                routs['/management/user'].name,
                routs['/management/user'].link,
              ),
            ]),
        getItem(
          routs['/management/emission'].name,
          routs['/management/emission'].link,
        ),
        user.isManagementBank
          ? getItem(
              routs['/management/introducer'].name,
              routs['/management/introducer'].link,
            )
          : null,
        user.isPresidingBank
          ? getItem(
              routs['/management/lci-db-pload'].name,
              routs['/management/lci-db-pload'].link,
            )
          : null,
      ],
    ),
    getItem(
      <SubMenuTitle showIcon={isMatch}>{routs['/support'].name}</SubMenuTitle>,
      '/support',
      undefined,
      [
        user.isManagementBank || user.isPresidingBank
          ? getItem(
              routs['/support/message'].name,
              routs['/support/message'].link,
            )
          : null,
        getItem(
          routs['/support/manual'].name,
          routs['/support/manual'].link,
          undefined,
          [
            user.isPresidingBank
              ? getItem(CM01.label.higoManual, '/support/manual/1')
              : null,
            user.isManagementBank
              ? getItem(CM01.label.partnerManual, '/support/manual/2')
              : null,
            getItem(CM01.label.userManual, '/support/manual/0'),
            user.isEnterpriseAdmin || user.isEnterpriseUser
              ? getItem(CM01.label.supplyChainManual, '/support/manual/3')
              : null,
            getItem(CM01.label.tutorialVideo, 'external2', undefined, [
              getItem(
                <div className="tw-flex tw-flex-col tw-gap-12 tw-py-4">
                  <div className="tw-flex tw-flex-col tw-gap-12">
                    {manualLinks.length ? (
                      manualLinks.map((link) => (
                        <HgbExternalLink
                          key={v4()}
                          linkName={link.linkName}
                          url={link.url}
                          onEdit={
                            user.isPresidingBank
                              ? () => {
                                  setExternalLinkData(link);
                                  setExternalLinkType('manual');
                                  externalModalStatus.turnOn();
                                  setIsDeleteLink(false);
                                }
                              : undefined
                          }
                          onDelete={
                            user.isPresidingBank
                              ? () => {
                                  setIsDeleteLink(true);
                                  setExternalLinkType('normal');
                                  externalModalStatus.turnOn();
                                  setExternalLinkData(link);
                                }
                              : undefined
                          }
                        />
                      ))
                    ) : (
                      <p>{CM01.message.externalLinksEmpty}</p>
                    )}
                  </div>

                  {user.isPresidingBank && (
                    <>
                      <div className="tw-h-1 tw-bg-neutral-1"></div>
                      <Button
                        icon={<PlusOutlined />}
                        size="small"
                        className="tw-self-end !tw-text-[11px]"
                        onClick={() => {
                          setExternalLinkData(null);
                          setExternalLinkType('manual');
                          externalModalStatus.turnOn();
                          setIsDeleteLink(false);
                        }}
                      >
                        {CM01.label.addExternalLink}
                      </Button>
                    </>
                  )}
                </div>,
                'g2',
                null,
                undefined,
                'group',
              ),
            ]),
          ],
        ),
        getItem(routs['/support/faq'].name, routs['/support/faq'].link),
        getItem(
          routs['/support/contact-us'].name,
          routs['/support/contact-us'].link,
        ),
        getItem(CM01.label.externalLink, 'external', undefined, [
          getItem(
            <div className="tw-flex tw-flex-col tw-gap-12 tw-py-4">
              <div className="tw-flex tw-flex-col tw-gap-12">
                {normalLinks.length ? (
                  normalLinks.map((link) => (
                    <HgbExternalLink
                      key={'link' + link.id}
                      linkName={link.linkName}
                      url={link.url}
                      onEdit={
                        user.isPresidingBank
                          ? () => {
                              setExternalLinkData(link);
                              setExternalLinkType('normal');
                              externalModalStatus.turnOn();
                              setIsDeleteLink(false);
                            }
                          : undefined
                      }
                      onDelete={
                        user.isPresidingBank
                          ? () => {
                              setIsDeleteLink(true);
                              setExternalLinkType('normal');
                              externalModalStatus.turnOn();
                              setExternalLinkData(link);
                            }
                          : undefined
                      }
                    />
                  ))
                ) : (
                  <p>{CM01.message.externalLinksEmpty}</p>
                )}
              </div>

              {user.isPresidingBank && (
                <>
                  <div className="tw-h-1 tw-bg-neutral-1"></div>
                  <Button
                    icon={<PlusOutlined />}
                    size="small"
                    className="tw-self-end !tw-text-[11px]"
                    onClick={() => {
                      setExternalLinkData(null);
                      setExternalLinkType('normal');
                      externalModalStatus.turnOn();
                      setIsDeleteLink(false);
                    }}
                  >
                    {CM01.label.addExternalLink}
                  </Button>
                </>
              )}
            </div>,
            'g2',
            null,
            undefined,
            'group',
          ),
        ]),
      ],
    ),

    getItem(
      <SubMenuTitle showIcon={isMatch}>
        {common.label.notification}
      </SubMenuTitle>,
      '/history-message',
      <BellOutlined />,
      [
        getItem(
          <div className="tw-flex tw-w-280 tw-flex-col tw-items-start tw-gap-16 tw-py-8">
            {messageHistoryQuery?.result?.map((message, index) => {
              const selected = previewModalProps.id === message.id;
              return (
                <button
                  type="button"
                  key={index}
                  className=" tw-flex tw-cursor-pointer tw-items-center tw-gap-8"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewModalProps({
                      open: true,
                      title: message.title,
                      description: message.description,
                      type: message.type.toString(),
                      id: message.id,
                    });
                  }}
                >
                  {message.type == '1' ? (
                    <HgbMaintainIcon className="tw-shrink-0" />
                  ) : (
                    <HgbInformationIcon className="tw-shrink-0" />
                  )}
                  <p
                    className={`tw-line-clamp-1 tw-break-all ${
                      selected && ' tw-font-bold tw-text-primary-7'
                    }`}
                  >
                    {message.title}
                  </p>
                </button>
              );
            })}
            {previewModalProps.open && (
              <div
                ref={messageRef}
                className="tw-absolute tw-right-316 tw-top-8 tw-z-50 tw-hidden tw-w-440 tw-rounded-16 tw-bg-white  tw-p-16 tw-shadow-lg large:tw-block"
              >
                <CloseOutlined
                  className="tw-ml-auto tw-block tw-w-fit tw-cursor-pointer tw-text-[150%]"
                  onClick={() => {
                    setPreviewModalProps((prev) => ({
                      ...prev,
                      open: false,
                      id: '',
                    }));
                  }}
                />
                <div className="tw-flex tw-items-start tw-p-24 tw-pt-0">
                  {previewModalProps.type === '1' && (
                    <HgbMaintainIcon size="24" />
                  )}
                  {previewModalProps.type === '2' && (
                    <HgbInformationIcon size="24" />
                  )}
                  <div className="tw-w-full">
                    <p className="tw-mb-8 tw-text-h4 tw-font-bold">
                      {previewModalProps.title}
                    </p>
                    <div
                      className="ql-editor tw-max-h-[29vh] tw-w-full"
                      dangerouslySetInnerHTML={{
                        __html: previewModalProps.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>,
          'g2',
          null,
          undefined,
          'group',
        ),
      ],
      'divider',
    ),

    getItem(
      <SubMenuTitle showIcon={isMatch}>{CM01.label.userInfo} </SubMenuTitle>,
      'inf',
      undefined,
      [
        getItem(
          <div className="tw-flex tw-flex-col tw-gap-4">
            <p className="tw-font-bold">{user?.nameKanji}</p>
            <p className="tw-text-tiny">{user?.email}</p>
            <div className="tw-mt-8 tw-h-1 tw-bg-neutral-1"></div>
          </div>,
          'inf-1',
          null,
          undefined,
          'group',
        ),
        getItem(
          language === 'en' ? CM01.label.english : CM01.label.japanese,
          '/lang',
          <GlobalOutlined />,
          [
            language === 'en'
              ? getItem(CM01.label.japanese, '/lang/jp')
              : getItem(CM01.label.english, '/lang/en'),
          ],
          'divider',
        ),
        getItem(routs['/change-password'].name, routs['/change-password'].link),
        getItem(
          routs['/logout'].name,
          '',
          <LogoutOutlined size={24} />,
          undefined,
          undefined,
          logout,
        ),
      ],
      undefined,
      undefined,
      'o-hidden',
    ),
  ];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group' | 'divider',
    onClick?: () => void,
    className?: string,
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick,
      className,
    } as MenuItem;
  }

  const externalModalStatus = useBooleanState(false);
  const [externalLinkData, setExternalLinkData] = useState<ExternalLink | null>(
    null,
  );
  const [isDeleteLink, setIsDeleteLink] = useState<boolean>(false);
  const [externalLinkType, setExternalLinkType] =
    useState<ExternalLinkType>('normal');

  return (
    <>
      <div className={`tw-sticky tw-top-0 tw-z-50 ${bgclass}`}>
        <section id="navbar" className="tw-flex">
          <div className="tw-flex tw-flex-auto tw-flex-col tw-gap-12 tw-py-8 pc:tw-flex-row">
            <HgbNavbarLogo />
            <div className="tw-flex tw-flex-auto tw-justify-end" ref={divRef}>
              {isMatch ? (
                <Menu
                  className={`
              tw-w-[1184px]
              tw-justify-end tw-border-none
              [&_.ant-menu-item-selected_.ant-menu-title-content]:tw-font-bold
              [&_.ant-menu-item]:after:!tw-bottom-8 
              [&_.ant-menu-submenu-open_.icon-menu-custom]:-tw-rotate-180
              
              [&_.ant-menu-submenu-selected_.ant-menu-submenu-title_*]:tw-font-bold
              [&_.ant-menu-submenu-selected_.ant-menu-submenu-title_*]:tw-text-[#007daf]
              
              [&_.ant-menu-submenu]:after:!tw-bottom-8
              [&_.ant-menu-title-content]:tw-relative
              [&_.ant-menu-title-content]:after:tw-absolute
              [&_.ant-menu-title-content]:after:tw-inset-[unset]
              [&_.ant-menu-title-content]:after:!tw-left-[calc(100%+1rem)]
              [&_.ant-menu-title-content]:after:!tw-top-1/2
              [&_.ant-menu-title-content]:after:tw-h-24
              [&_.ant-menu-title-content]:after:tw-w-1
              [&_.ant-menu-title-content]:after:!-tw-translate-y-1/2
              [&_.ant-menu-title-content]:after:tw-bg-neutral-5
              [&_.ant-menu-title-content]:after:tw-content-['']
              [&_.o-hidden_.ant-menu-title-content]:after:tw-hidden
              [@media(min-width:1500px)]:tw-flex
              ${bgclass}`}
                  onClick={onClick}
                  selectedKeys={[...getSelectedKeys(pathname)]}
                  mode="horizontal"
                  items={items}
                  triggerSubMenuAction="click"
                ></Menu>
              ) : null}
            </div>
          </div>
          {!isMatch ? (
            <SquareButton onClick={openMenu} className="tw-mt-20">
              <HgbMenuOutlinedIcon size="24" />
            </SquareButton>
          ) : null}
        </section>
      </div>

      {previewModalProps.open && window.innerWidth < 1190 && (
        <PreviewMessageModal
          {...previewModalProps}
          onCancel={(e) => {
            e.stopPropagation();
            setPreviewModalProps((prev) => ({ ...prev, open: false, id: '' }));
          }}
        />
      )}

      <Drawer
        className={`[&_.ant-drawer-close]:tw-hidden ${mobileMobileClass}`}
        title={
          <div className="tw-flex tw-items-center tw-justify-between ">
            <HgbNavbarLogo />
            <SquareButton onClick={closeMenu}>
              <HgbCloseOutlinedIcon size="24" />
            </SquareButton>
          </div>
        }
        placement={'left'}
        closable={true}
        closeIcon={null}
        onClose={() => setOpen((prev) => !prev)}
        open={open}
        key={'placement'}
      >
        <Menu
          style={{
            borderInlineEnd: 'none',
          }}
          className={`tw-flex-auto tw-justify-end tw-border-none
          [&_.ant-menu-sub]:tw-overflow-hidden [&_.ant-menu-sub]:!tw-rounded-4
          [&_.ant-menu-submenu-selected_.ant-menu-submenu-title_span]:tw-font-bold 
          [&_.ant-menu-submenu-selected_.ant-menu-submenu-title_span]:tw-text-[#007daf]`}
          onClick={onClick}
          selectedKeys={[...getSelectedKeys(pathname)]}
          mode="inline"
          items={items}
          triggerSubMenuAction="click"
        />
      </Drawer>
      <ExternalLinkModal
        modalStatus={externalModalStatus}
        linkType={externalLinkType}
        data={externalLinkData}
        type={isDeleteLink}
      />
    </>
  );
};
