import { bigNumberToString } from './text';

export const sumOfArray = (arr: number[]): number => {
  return arr.reduce((prev, current) => prev + current, 0);
};

export const toJPFormat = (
  n: number | string,
  fractionDigits = 3,
  forceZero = true,
  typeScreen?: string,
) => {
  let num = Number(n);
  if (num.toString() === 'NaN') {
    num = 0;
  }
  if (!num && forceZero) return 0;
  const a = num.toFixed(fractionDigits).split('.')[0];
  const x = num.toFixed(fractionDigits).split('.')[1];
  if (typeScreen !== 'G01') {
    return (
      new Intl.NumberFormat('ja-JP', {
        maximumSignificantDigits: 20,
      }).format(Number(a)) +
      '.' +
      x
    );
  } else {
    return new Intl.NumberFormat('ja-JP', {
      maximumSignificantDigits: 20,
    }).format(Number(a));
  }
};

export const calculationFormula = (
  type?: string | null,
  allocation?: string | null,
  quantity?: string | null,
  emissionIntensity?: string,
  baseVolume?: string,
) => {
  let formula = '0';
  if (type === '1') {
    formula = bigNumberToString(
      preciseMultiple(Number(quantity), Number(emissionIntensity)).toString(),
    );
  }
  if (type === '2') {
    formula = bigNumberToString(
      preciseMultiple(
        preciseMultiple(Number(allocation), Number(baseVolume)),
        0.01,
      ).toString(),
      // /100
    );
  }
  return formula;
};

export const preciseMultiple = (a: number, b: number) => {
  const lengthA = a.toString().split('.')?.[1]?.length || 0;
  const lengthB = b.toString().split('.')?.[1]?.length || 0;

  const powA = Math.pow(10, lengthA);
  const powB = Math.pow(10, lengthB);
  return (a * powA * b * powB) / (powA * powB);
};

export const fixNumber = (value: number | string, fractionDigits: number) => {
  const filterValue = Number(value);
  if (filterValue.toString() === 'NaN') return '';

  const coef = Math.pow(10, fractionDigits);
  return (Math.round((filterValue + Number.EPSILON) * coef) / coef).toString();
};
