import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import { cn } from '@utils/cn';
import { FunctionComponent, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 } from 'uuid';
import { formats, modules, sizeList } from './quillConfigs';

interface IHgbEditorProps extends ReactQuillProps {
  name: string;
  length?: number;
}
const Size = Quill.import('attributors/style/size');
Size.whitelist = sizeList;
Quill.register(Size, true);

const HgbEditor: FunctionComponent<IHgbEditorProps> = ({
  name,
  length = 2000,
  ...props
}) => {
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const value = watch(name);
  const error = errors[name];
  const qrId = v4();
  const first = useRef(true);

  const qr = useRef<ReactQuill>(null);

  const handleChangeText = (d: string, a: any, b: any) => {
    if (first.current) {
      first.current = false;
      setValue(name, value);
      return;
    }
    let htmlValue = d.toString();
    if (countWords(d) > length) {
      htmlValue = value;
    }
    setValue(name, htmlValue);
  };

  const countWords = (html: string) => {
    const text = html.replace(/<[^>]*>/g, ''); // Remove HTML tags
    return text.length;
  };

  return (
    <div>
      <div
        className={cn('tw-relative', {
          'tw-rounded-6 tw-border-[1px] tw-border-error-7':
            error,
        })}
      >
        <p className="tw-absolute tw-bottom-4 tw-right-4">
          {countWords(value.toString())}/{length}
        </p>
        <ReactQuill
          id={qrId}
          ref={qr}
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          defaultValue={value}
          onChange={handleChangeText}
          {...props}
        />
      </div>
      {error && <HgbFieldError>{error.message?.toString()}</HgbFieldError>}
    </div>
  );
};

export default HgbEditor;
