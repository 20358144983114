import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { HgbExpandMoreOutlinedIcon } from '@common/icons/outlined';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { BooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { hgbAxios } from '@utils/axios';
import { cn } from '@utils/cn';
import { currentYearMonth, getFiscalYearFromMonth } from '@utils/date.util';
import { debug } from '@utils/debug';
import { Modal, RadioChangeEvent } from 'antd';
import { LocalDataClass } from 'data-class/LocalDataClass';
import parse from 'html-react-parser';
import { isEmpty } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import { useStep1 } from './hooks/useStep1';
import { useStep2 } from './hooks/useStep2';
import {
  FlowInputDetail,
  GreenGasHouseCode,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from './type';
import {
  checkInitValueSelectionInfo,
  createEmissionApiLink,
  createFlowApi,
  createMessageDataEntry,
  createSelectioInfoOptions,
  disableStep2Submit,
  getInfoLink,
  getInputType,
  getResultFromFormula,
  getSelectionsTarget,
  isFullData,
  isSearchSelect,
  offsetAmountUnitOptions,
  preventNull,
  shouldResetNextSelections,
  STEP1_DEFAULT_VALUE,
  STEP2_DEFAULT_VALUE,
  uniqPropertyArray,
  unitByRange,
  unitFlowInputDetails,
  useBase,
  useCategory,
  useFiscalYearMonth,
  useLastTenYearsListBox,
  useScope,
} from './utils';
import { AuthContext } from '@contexts/AuthContext';

type Step = 'STEP_1' | 'STEP_2';

interface EmissionCreateModalProp {
  modalState: BooleanState;
}

export const EmissionCreateModal: FunctionComponent<
  EmissionCreateModalProp
> = ({ modalState }) => {
  const formulaRef = useRef('');
  const formulaCargoWeight = useRef('');
  const scopeRef = useRef('');
  const categoryRef = useRef('');
  const scopeCategoryRef = useRef('');
  const greenGasHouseRef = useRef('');
  const checkFlowFinished = useRef('');
  const {
    text: { common, C01 },
  } = useContext(LanguageContext)!;
  const { user } = React.useContext(AuthContext)!;
  const { addMessage } = useContext(PortalContext)!;
  const [step, setStep] = useState<Step>('STEP_1');

  const backStep = () => {
    setStep('STEP_1');
  };

  const nextStep = () => {
    setStep('STEP_2');
  };

  /*=================== STEP 1 HANDLER ===================*/

  const { step1Methods } = useStep1();

  const { data: greenGasQuery } = useHgbQuery<GreenGasHouseCode[]>(
    `${API_URLS.GREEN_GAS_HOUSE_CODE}`,
    {},
    {
      enabled: true,
      queryKey: [API_URLS.GREEN_GAS_HOUSE_CODE],
    },
  );

  const { data: targetCodeGreenGasHouse } = useHgbQuery<any>(
    `${API_URLS.CALCULATION_METHOD_GAS_CODE}`,
    {},
    {
      enabled:
        step1Methods.watch('scopeCode') === '3' ||
        step1Methods.watch('scopeCode') === '1',
    },
  );

  const handleChangeGreenHouse = async (_name: string, _value: string) => {
    step1Methods.setValue('scopeCode', STEP1_DEFAULT_VALUE.scopeCode);
    step1Methods.setValue('categoryCode', STEP1_DEFAULT_VALUE.categoryCode);
  };

  const handleChangeScope = async (_name: string, value: string) => {
    scopeRef.current = `/scope-${value}`;

    step1Methods.setValue('categoryCode', STEP1_DEFAULT_VALUE.categoryCode);
    if (value !== '3') {
      categoryRef.current = '';
    }
  };

  const handleChangeCategory = (_name: string, value: string) => {
    categoryRef.current = '';
    if (scopeRef.current === '/scope-3' && !!Number(value)) {
      categoryRef.current = `/category-${Number(value)}`;
    }
  };

  // Lưu lại giá trị cũ cho lciDbYear, để check xem có thay đổi hay ko
  const previousLciDbYear = useRef<string | undefined>(undefined);
  useEffect(() => {
    return () => {
      previousLciDbYear.current = step1Methods.watch('targetLciDbYear');
    };
  }, [step1Methods.watch('targetLciDbYear')]);

  const step1SubmitHandler: SubmitHandler<Step1Fields> = async (data) => {
    if (!!data.targetYearMonth) {
      const searchParams = new URLSearchParams('');
      searchParams.set('lciDBYear', data.targetYearMonth);
      searchParams.set('lang', LocalDataClass.language);
      try {
        const response = await hgbAxios().get(
          `${API_URLS.CHECK_LCIDB_YEAR_API_URL}?${searchParams}`,
        );
        const targetLciDbYear = response.data?.result ?? '';
        if (isEmpty(targetLciDbYear)) {
          addMessage('error', response.data?.message ?? '');
          return;
        }
        step1Methods.setValue('targetLciDbYear', targetLciDbYear);
        const { targetYearMonth } = data;

        // Khi qua step phải check xem scope hoặc category có thay đổi hay không mới tiến hành reset và call lại api
        // Và check thêm lciDbYear có đổi hay không
        const scopeCategory = `${data.scopeCode}-${data.categoryCode}`;
        const greenHouseCode = step1Methods.watch('greenHouseCode');
        const categoryCode = step1Methods.watch('categoryCode');
        if (
          scopeCategory !== scopeCategoryRef.current ||
          targetLciDbYear !== previousLciDbYear.current ||
          greenHouseCode !== greenGasHouseRef.current
        ) {
          previousLciDbYear.current = targetLciDbYear;
          resetStep2();

          const params: any = {
            targetLciDbYear,
            targetYearMonth,
            greenHouseCode,
            categoryCode,
          };

          if (greenHouseCode && greenHouseCode !== 'CO2') {
            params.calculationTargetCode =
              targetCodeGreenGasHouse?.result?.[0]?.value;
            step2Methods.setValue(
              'calculationTargetCode',
              targetCodeGreenGasHouse?.result?.[0]?.value,
            );
          }

          callFlowApi(params);
        }
        nextStep();
        scopeCategoryRef.current = scopeCategory;
        greenGasHouseRef.current = greenHouseCode || '';
      } catch (error) {}
    }
  };

  const step1SubmitError: SubmitErrorHandler<Step1Fields> = (err) => {
    debug.error(err);
  };
  /*=================== END STEP 1 HANDLER ===================*/

  /*=================== STEP 2 HANDLER ===================*/

  const [selectionInfos, setSelectionInfos] = useState<SelectionInfo[]>([]);
  const [flowInputDetails, setFlowInputDetails] = useState<FlowInputDetail[]>(
    [],
  );
  const [flowResultItem, setFlowResultItem] = useState<InputInfo>();
  const { ConfirmModal, hgbConfirm } = useConfirm();
  const queryClient = useQueryClient();

  const createEmissionMutation = useHgbMutationPost<unknown, any>(
    createEmissionApiLink(
      step1Methods.watch('greenHouseCode'),
      step1Methods.watch('scopeCode'),
      step1Methods.watch('categoryCode'),
    ),
    {
      onSuccess: () => {
        modalState.turnOff();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.EMISSION_LIST],
        });
      },
    },
  );

  const { step2Methods, offsetMethods } = useStep2(
    step1Methods.watch('scopeCode'),
    flowInputDetails,
  );

  const resetStep2 = () => {
    step2Methods.reset(STEP2_DEFAULT_VALUE);
    setSelectionInfos([]);
    setFlowResultItem(undefined);
    setFlowInputDetails([]);
  };

  // Nghĩa là các field cần người dùng input hàng loạt, khi full mới tiến hành call api tiếp
  const nextSelections = useRef<SelectionInfo[]>([]);
  const nextSelectionsList = useRef<string[][]>([]);
  const currentFieldTarget = useRef('');
  const handleChangeBeforeCallApi = useCallback(
    (name: string, value: string) => {
      setErrors({});
      setSelectionInfos((prev) => {
        let newSelectionInfos = prev;
        // Phải xác định điểm chốt để lấy params
        let selectionsTarget = getSelectionsTarget(
          name,
          nextSelectionsList.current,
          newSelectionInfos,
        );
        /* ==================================================== */

        // Check xem field vừa chọn có nằm trong nextSelections hay không
        const includedByNextSelections = nextSelections.current.find(
          (selection) => selection.fieldTarget === selectionsTarget.targetName,
        );
        // Nếu không nằm trong nextSelections, nghĩa là user muốn thay đổi lựa chọn để render lại các fields, nên sẽ phải loại bỏ hết những fields đằng sau
        if (!includedByNextSelections) {
          const indexOfTargetName = newSelectionInfos.findIndex(
            (selection) =>
              selection.fieldTarget === selectionsTarget.targetName,
          );
          newSelectionInfos = newSelectionInfos.slice(0, indexOfTargetName + 1);
        }

        const { targetLciDbYear, targetYearMonth } = step1Methods.getValues();
        const newParams: Record<string, any> = {
          targetLciDbYear,
          targetYearMonth,
          [name]: value,
        };

        // Bắt buộc phải lấy params từ tất cả các fields đang hiện diện trên UI
        const paramNames = [
          ...newSelectionInfos.map((item) => item.fieldTarget),
          ...selectionsTarget.nameList,
        ];

        //thêm field ẩn calculationTargetCode đối với 7 loại khí thải
        if (step1Methods.watch('greenHouseCode') !== 'CO2') {
          paramNames.push('calculationTargetCode');
        }

        paramNames.forEach((item) => {
          newParams[item] = step2Methods.getValues(item as any);
        });

        /* ==================================================== */

        // Và reset lại params cho step2
        step2Methods.reset(newParams);
        /* Có 2 trường hợp cần call flow api
          1. Khi field được chọn không nằm trong nextSelections
          2. Khi field được chọn nằm trong nextSelections và tất cả fields của nextSelections có giá trị
        */

        if (step1Methods.watch('greenHouseCode') !== 'CO2') {
          newParams.greenHouseCode = step1Methods.watch('greenHouseCode');
        }

        if (
          !includedByNextSelections ||
          (includedByNextSelections &&
            isFullData(nextSelections.current, newParams))
        ) {
          callFlowApi(newParams);
        }
        return newSelectionInfos;
      });
    },
    [],
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  const callFlowApi = async (newParams: Record<string, any>) => {
    try {
      const queryString = createFlowApi(
        scopeRef.current,
        categoryRef.current,
        newParams,
      );
      const data = (await hgbAxios().post(queryString, newParams))?.data;
      const responseFlowInputDetails = (data?.result?.flowInputDetails ||
        []) as FlowInputDetail[];
      handleChangeSelectionInfosAfterCallApiSuccess(
        (data?.result?.nextStepSelectionInfos || []) as SelectionInfo[],
      );
      setFlowInputDetails(() => {
        window.dispatchEvent(new Event('FORCE_UPDATE_INIT_VALUE'));
        return responseFlowInputDetails;
      });
      handleChangeFlowResultItemAfterCallApiSuccess(
        data?.result?.flowResultItem as InputInfo,
      );

      checkFlowFinished.current = data?.result?.flowFinished;

      if (data?.result?.flowFinished) {
        const flowInputDetails = data?.result
          ?.flowInputDetails as FlowInputDetail[];
        const fieldTarget = flowInputDetails?.[0]?.intensity?.fieldTarget;
        const value = flowInputDetails?.[0]?.intensity?.value;
        formulaRef.current = data?.result?.formula || '';
        if (fieldTarget) {
          step2Methods.setValue(fieldTarget as 'emissionIntensity', value);
        }

        const inputGroup = responseFlowInputDetails?.[0]?.inputGroup || [];
        const cargoWeightField = inputGroup.find(
          (item) => item?.fieldTarget === 'transportMaximumCargoWeight',
        );
        formulaCargoWeight.current = cargoWeightField?.formula || '';
      }
    } catch (error: any) {
      const fieldTargetWithMessage = (
        error?.response?.data?.message || ''
      ).split('|||');
      const fieldTarget = fieldTargetWithMessage?.[0];
      const message = fieldTargetWithMessage?.[1];
      if (fieldTarget) {
        setErrors({ [fieldTarget]: message });
      }
      setFlowResultItem(undefined);
      setFlowInputDetails([]);
    }
  };

  const handleChangeRadio = useCallback((e: RadioChangeEvent) => {
    handleChangeBeforeCallApi(e.target?.name || '', e.target.value);
  }, []);

  const handleChangeFlowResultItemAfterCallApiSuccess = useCallback(
    (inputInfo?: InputInfo) => {
      if (inputInfo?.value) {
        step2Methods.setValue(
          `${inputInfo.fieldTarget}` as keyof Step2Fields,
          Number(inputInfo.value) ?? null,
        );
      }
      setFlowResultItem(inputInfo);
    },
    [],
  );

  const handleChangeSelectionInfosAfterCallApiSuccess = useCallback(
    (nextStepSelectionInfos: SelectionInfo[]) => {
      // khi lấy được nextStepSelectionInfos, reset lại value các field đã chọn trước đó
      nextStepSelectionInfos.forEach((item) => {
        const fieldTarget = item?.fieldTarget as keyof Step2Fields;
        if (fieldTarget)
          step2Methods.setValue(fieldTarget, STEP2_DEFAULT_VALUE[fieldTarget]);
      });
      if (
        // Để tránh trường hợp set lại next selection khi gặp 1 bộ selection đi chung
        shouldResetNextSelections(
          nextSelections.current,
          currentFieldTarget.current,
          nextStepSelectionInfos,
        )
      ) {
        nextSelections.current = nextStepSelectionInfos;
        // Lưu lại tất cả các selection để khi cần sử dụng
        nextSelectionsList.current.push(
          nextStepSelectionInfos.map((item) => item.fieldTarget),
        );
        /* ================================================ */
        setSelectionInfos((prevStepSelectionInfos) => {
          return uniqPropertyArray<SelectionInfo>(
            [...prevStepSelectionInfos, ...nextStepSelectionInfos],
            'fieldTarget',
          );
        });
      }
    },
    [],
  );

  const onUpdateValue = () => {
    try {
      if (formulaRef.current) {
        step2Methods.setValue(
          `${flowResultItem?.fieldTarget}` as keyof Step2Fields,
          getResultFromFormula(
            { ...step1Methods.getValues(), ...step2Methods.getValues() },
            formulaRef.current,
          ),
        );
      }
      if (formulaCargoWeight.current) {
        step2Methods.setValue(
          'transportMaximumCargoWeight',
          getResultFromFormula(
            { ...step1Methods.getValues(), ...step2Methods.getValues() },
            formulaCargoWeight.current,
          ),
        );
      }
    } catch (error) {
      debug.error('onUpdateValue', error);
    }
  };

  const step2SubmitHandler: SubmitHandler<Step2Fields | OffsetFields> = async (
    data,
  ) => {
    //thêm các đơn vị unit nếu có vào
    const convertData = {
      ...data,
      ...unitFlowInputDetails(flowInputDetails),
      ...(flowResultItem && flowResultItem.unit && flowResultItem.fieldTarget
        ? { [`${flowResultItem.fieldTarget}Unit`]: flowResultItem.unit }
        : {}),
      ...step1Methods.getValues(),
      //nếu như có chọn category thì mới gửi categoryCode, các case khác gửi '00'
      categoryCode: step1Methods.watch('categoryCode') || '00',
    };

    const result = await hgbConfirm();
    if (!result) return;

    createEmissionMutation.mutate(convertData);
  };

  const step2SubmitErrorHandler: SubmitErrorHandler<Step2Fields> = (error) => {
    debug.log('error', error);
  };

  /*=================== END STEP 2 HANDLER ===================*/

  const handleCancel = () => {
    nextSelections.current = [];
    setSelectionInfos([]);
    backStep();
    modalState.turnOff();
    step1Methods.reset();
    step2Methods.reset();
  };

  const targetFiscalYearOptions = useLastTenYearsListBox().options;
  const baseOptions = useBase().options;
  const targetYearMonthOptions = useFiscalYearMonth(
    step1Methods.watch('targetFiscalYear'),
  ).options;
  const scopeOptions = useScope().options;
  // const scopeOptions = [
  //   {
  //     label: 'Scope 1',
  //     value: '1',
  //   },
  //   {
  //     label: 'Scope 2',
  //     value: '2',
  //   },
  //   {
  //     label: 'Scope 3',
  //     value: '3',
  //   },
  //   { label: 'Offset', value: '4' },
  // ];
  const categoryOptions = useCategory(
    step1Methods.watch('scopeCode'),
    user.enterpriseId,
  ).options;

  React.useEffect(() => {
    if (targetYearMonthOptions?.length > 0) {
      const fiscalYear = getFiscalYearFromMonth(
        `${new Date().getFullYear()}/${new Date().getMonth()}`,
      );
      if (fiscalYear === Number(step1Methods.watch('targetFiscalYear'))) {
        step1Methods.setValue('targetYearMonth', currentYearMonth());
      } else {
        step1Methods.setValue(
          'targetYearMonth',
          targetYearMonthOptions?.[0]?.value ?? '',
        );
      }
    }
  }, [targetYearMonthOptions, step1Methods.watch('targetFiscalYear')]);

  // useEffect(() => {
  //   window.dispatchEvent(new Event('FORCE_UPDATE_INIT_VALUE'));
  // }, [JSON.stringify(flowInputDetails)]);

  return (
    <>
      <Modal
        className="ant-modal-hgb-custom"
        centered
        title={
          <HgbAntdModalTitle
            className={cn({ 'tw-relative tw-indent-16': step === 'STEP_2' })}
          >
            {step === 'STEP_2' && (
              <HgbExpandMoreOutlinedIcon
                onClick={backStep}
                size="24"
                className="tw-absolute tw-left-32 tw-top-24 tw-rotate-90 tw-cursor-pointer"
              />
            )}
            {C01.modal.emissionCreateTitle}
          </HgbAntdModalTitle>
        }
        open={modalState.value}
        footer={null}
        closable={true}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleCancel}
      >
        <HgbAntdModalForm>
          <HgbAntdModalFormBody>
            {/* STEP 1 */}
            <FormProvider {...step1Methods}>
              <div
                className={cn('tw-space-y-24', {
                  'tw-hidden': step === 'STEP_2',
                })}
              >
                <div className="tw-grid tw-grid-cols-2 tw-gap-24">
                  <HgbSelect
                    {...step1Methods.register('targetFiscalYear')}
                    options={targetFiscalYearOptions}
                    label={C01.label.fiscalYear}
                    placeholder={C01.placeholder.fiscalYear}
                    required
                    autoInitValue
                  />
                  <HgbSelect
                    {...step1Methods.register('targetYearMonth')}
                    options={targetYearMonthOptions}
                    label={C01.label.targetYearMonth}
                    placeholder={C01.placeholder.targetYearMonth}
                    required
                    autoInitValue
                  />
                </div>
                <HgbTreeSelect
                  {...step1Methods.register('baseId')}
                  placeholder={C01.placeholder.baseId}
                  label={C01.label.baseId}
                  required
                  treeData={baseOptions}
                  showSearch
                />

                <HgbSelect
                  {...step1Methods.register('greenHouseCode')}
                  options={parseToHgbSelectOptions(greenGasQuery?.result)}
                  label={C01.label.greenHouseGases}
                  placeholder={C01.placeholder.categoryCode}
                  onWatchValue={handleChangeGreenHouse}
                  required
                  autoInitValue
                />

                {step1Methods.watch('greenHouseCode') ? (
                  <HgbSelect
                    {...step1Methods.register('scopeCode')}
                    required
                    label={C01.label.scopeCode}
                    placeholder={C01.placeholder.scopeCode}
                    options={scopeOptions?.filter(
                      (scope) =>
                        step1Methods.watch('greenHouseCode') === 'CO2' ||
                        (scope?.value !== '2' && scope?.value !== '4'),
                    )}
                    onWatchValue={handleChangeScope}
                  />
                ) : null}

                {step1Methods.watch('scopeCode') === '3' && (
                  <HgbSelect
                    {...step1Methods.register('categoryCode')}
                    options={categoryOptions}
                    label={common.label.category}
                    placeholder={C01.placeholder.categoryCode}
                    required
                    onWatchValue={handleChangeCategory}
                  />
                )}
              </div>
            </FormProvider>
            {/* END STEP 1 */}
            {/* STEP 2 */}
            <div className={cn({ 'tw-hidden': step === 'STEP_1' })}>
              {step1Methods.watch('scopeCode') === '4' ||
              createMessageDataEntry(
                step1Methods.watch('scopeCode'),
                step1Methods.watch('greenHouseCode'),
              ) ? (
                <h4 className="tw-font-bold">{C01.label.dataEntry}</h4>
              ) : null}

              <div className="tw-space-y-24">
                <FormProvider {...step2Methods}>
                  {step1Methods.watch('scopeCode') !== '4' && (
                    <div>
                      {createMessageDataEntry(
                        step1Methods.watch('scopeCode'),
                        step1Methods.watch('greenHouseCode'),
                      ) ? (
                        <small className="tw-text-error-6">
                          {C01.message.dataEntryScopeNotice}
                        </small>
                      ) : (
                        <div className="tw-text-h4 tw-font-bold">
                          {
                            greenGasQuery?.result?.find(
                              (p) =>
                                p?.value ===
                                step1Methods.watch('greenHouseCode'),
                            )?.name
                          }
                        </div>
                      )}

                      <div className="tw-mt-16 tw-space-y-24">
                        {selectionInfos
                          ?.filter((item) => item.data)
                          .map((selectionInfo) =>
                            selectionInfo.type === 'LIST_BOX' ? (
                              <div key={selectionInfo.fieldTarget}>
                                <HgbSelect
                                  options={createSelectioInfoOptions(
                                    selectionInfo.fieldTarget ===
                                      'supplyMenuCode'
                                      ? selectionInfo.data?.sort((p1, p2) =>
                                          p1.value > p2.value
                                            ? 1
                                            : p1.value < p2.value
                                            ? -1
                                            : 0,
                                        )
                                      : selectionInfo.data,
                                    selectionInfo.fieldTarget,
                                  )}
                                  required
                                  label={selectionInfo?.name}
                                  name={
                                    selectionInfo.fieldTarget as keyof Step2Fields
                                  }
                                  onWatchValue={handleChangeBeforeCallApi}
                                  showSearch={isSearchSelect(
                                    selectionInfo?.fieldTarget,
                                    step1Methods.watch('scopeCode'),
                                    step1Methods.watch('categoryCode'),
                                  )}
                                  autoInitValue={checkInitValueSelectionInfo(
                                    selectionInfo,
                                  )}
                                />
                                {errors?.[
                                  selectionInfo?.fieldTarget || '_'
                                ] && (
                                  <HgbFieldError className="tw-mt-4">
                                    {errors[selectionInfo.fieldTarget]}
                                  </HgbFieldError>
                                )}
                              </div>
                            ) : (
                              <HgbAntdRadioGroup
                                key={selectionInfo.fieldTarget}
                                options={parseToHgbSelectOptions(
                                  selectionInfo.data,
                                )}
                                label={selectionInfo?.name}
                                name={selectionInfo.fieldTarget}
                                info={getInfoLink(selectionInfo.fieldTarget)}
                                direction={
                                  selectionInfo.fieldTarget ===
                                    'cityGasOptionSelected' ||
                                  selectionInfo.fieldTarget === 'heatSelected'
                                    ? 'vertical'
                                    : 'horizontal'
                                }
                                onChangeValue={handleChangeRadio}
                              />
                            ),
                          )}
                        {flowInputDetails.map(({ inputGroup, intensity }) => {
                          return (
                            <React.Fragment key={`${intensity?.fieldTarget}`}>
                              {(inputGroup ?? []).map((input) => {
                                return (
                                  <HgbAntdInput
                                    onUpdateValue={onUpdateValue}
                                    key={input?.fieldTarget}
                                    required
                                    label={input?.name}
                                    suffix={preventNull(input.unit)}
                                    subLabel={unitByRange(input)}
                                    readOnly={input.disabled}
                                    type={getInputType(input.fieldTarget)}
                                    minValue={
                                      input.constraints?.MIN_VALUE ?? undefined
                                    }
                                    maxValue={
                                      input.constraints?.MAX_VALUE ?? undefined
                                    }
                                    maxLength={input.maxLength}
                                    initValue={input.value}
                                    {...step2Methods.register(
                                      input.fieldTarget as keyof Step2Fields,
                                    )}
                                    forceMaxDecimal={
                                      input.constraints?.DECIMAL_LENGTH ??
                                      undefined
                                    }
                                  />
                                );
                              })}
                              {intensity && (
                                <HgbAntdInput
                                  onUpdateValue={onUpdateValue}
                                  required
                                  label={intensity?.name}
                                  suffix={preventNull(intensity.unit)}
                                  subLabel={unitByRange(intensity)}
                                  readOnly={intensity.disabled}
                                  maxLength={intensity.maxLength}
                                  name={intensity.fieldTarget}
                                  type={getInputType(intensity.fieldTarget)}
                                  minValue={
                                    intensity.constraints?.MIN_VALUE ??
                                    undefined
                                  }
                                  maxValue={
                                    intensity.constraints?.MAX_VALUE ??
                                    undefined
                                  }
                                  initValue={intensity.value}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                        {flowResultItem && (
                          <HgbAntdInput
                            required
                            name={flowResultItem?.fieldTarget}
                            label={flowResultItem?.name}
                            suffix={preventNull(flowResultItem.unit)}
                            subLabel={unitByRange(flowResultItem)}
                            readOnly={flowResultItem.disabled}
                            maxLength={flowResultItem.maxLength}
                            type={'positiveDecimal'}
                            minValue={
                              flowResultItem.constraints?.MIN_VALUE ?? undefined
                            }
                            maxValue={
                              flowResultItem.constraints?.MAX_VALUE ?? undefined
                            }
                            forceMaxDecimal={
                              flowResultItem.constraints?.DECIMAL_LENGTH ??
                              undefined
                            }
                          />
                        )}
                        <HgbAntdInput
                          {...step2Methods.register('activityName')}
                          label={
                            step1Methods.watch('scopeCode') === '3' &&
                            step1Methods.watch('greenHouseCode') === 'CO2'
                              ? C01.label.activityName
                              : C01.label.note
                          }
                          placeholder={C01.placeholder.activityName}
                          required={
                            step1Methods.watch('scopeCode') === '3' &&
                            step1Methods.watch('greenHouseCode') === 'CO2'
                          }
                          maxLength={200}
                        />
                      </div>
                    </div>
                  )}
                  <FormProvider {...offsetMethods}>
                    {step1Methods.watch('scopeCode') === '4' && (
                      <div className="tw-space-y-24">
                        <small className="tw-text-error-6">
                          {parse(C01.message.dataEntryOffsetNotice)}
                        </small>
                        <HgbAntdInput
                          {...offsetMethods.register('offsetAmount')}
                          label={C01.label.offsetAmount}
                          placeholder={C01.placeholder.offsetAmount}
                          type="positiveDecimal"
                          maxLength={14}
                          required
                          forceMaxDecimal={3}
                          maxValue={9999999999}
                        />
                        <HgbSelect
                          {...offsetMethods.register('offsetAmountUnit')}
                          required
                          autoInitValue
                          options={offsetAmountUnitOptions}
                          label={C01.label.offsetAmountUnit}
                          placeholder={C01.placeholder.offsetAmountUnit}
                        />
                        <HgbDatePicker
                          {...offsetMethods.register('offsetCertificateDate')}
                          required
                          format="YYYY/MM/DD"
                          picker="date"
                          label={C01.label.offsetCertificateDate}
                          placeholder={C01.placeholder.offsetCertificateDate}
                        />
                        <HgbAntdInput
                          {...offsetMethods.register('offsetTarget')}
                          maxLength={255}
                          label={C01.label.offsetTarget + common.label.optional}
                          placeholder={C01.placeholder.offsetTarget}
                        />
                        <HgbAntdInput
                          {...offsetMethods.register('projectName')}
                          maxLength={255}
                          label={C01.label.projectName + common.label.optional}
                          placeholder={C01.placeholder.projectName}
                        />
                        <HgbAntdInput
                          {...offsetMethods.register('creditType')}
                          maxLength={255}
                          label={C01.label.creditType + common.label.optional}
                          placeholder={C01.placeholder.creditType}
                        />
                      </div>
                    )}
                  </FormProvider>
                </FormProvider>
              </div>
            </div>

            {/* END STEP 2 */}
          </HgbAntdModalFormBody>
          <HgbAntdModalFormFooter>
            <HgbAntdButton className="tw-min-w-100" onClick={handleCancel}>
              {common.button.cancel}
            </HgbAntdButton>
            {step === 'STEP_1' ? (
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="button"
                onClick={step1Methods.handleSubmit(
                  step1SubmitHandler,
                  step1SubmitError,
                )}
              >
                {common.button.next}
              </HgbAntdButton>
            ) : null}
            {step === 'STEP_2' ? (
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="button"
                disabled={disableStep2Submit(
                  formulaRef.current,
                  {
                    ...step1Methods.watch(),
                    ...step2Methods.watch(),
                    ...offsetMethods.watch(),
                  },
                  !!checkFlowFinished.current,
                )}
                onClick={
                  step1Methods.getValues('scopeCode') !== '4'
                    ? step2Methods.handleSubmit(
                        step2SubmitHandler,
                        step2SubmitErrorHandler,
                      )
                    : offsetMethods.handleSubmit(
                        step2SubmitHandler,
                        step2SubmitErrorHandler,
                      )
                }
              >
                {common.button.add}
              </HgbAntdButton>
            ) : null}
          </HgbAntdModalFormFooter>
        </HgbAntdModalForm>
      </Modal>

      <ConfirmModal
        okText={common.button.add}
        cancelText={common.button.cancel}
        title={C01.modal.emissionCreateConfirmTitle}
      >
        <div>{C01.message.emissionCreateConfirm}</div>
      </ConfirmModal>
    </>
  );
};
