import { PLUS_MINUS_SIGN_REGEX } from '@constants/regex';
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';

// eslint-disable-next-line
const HALF_SIZE_LIST =
  "!#$%&'()*+,-./:;<=>?@[]^_`{|}~" +
  '"' +
  ' ' +
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾞﾟ';

export const isFullSize = (value: string) => {
  return value.split('').every((item) => HALF_SIZE_LIST.indexOf(item) === -1);
};

export const isHalfSize = (value: string) => {
  return value.split('').every((item) => HALF_SIZE_LIST.indexOf(item) !== -1);
};

export const isValidLength = (value: string, length: number) =>
  new Blob([value]).size <= length;

export const checkChangeValue = <T>(
  initialValue: T,
  currentValue: Partial<T>,
): boolean => {
  for (const item in currentValue) {
    if (initialValue[item] !== currentValue[item]) {
      return true;
    }
  }
  return false;
};

export const floorNumber = (numb: number, length: number) => {
  return Math.floor(numb * Math.pow(10, length)) / Math.pow(10, length);
};

export const toBigDecimal = (value = '', length: number) => {
  let valueTmp = value.toString();
  if (isEmpty(valueTmp)) return valueTmp;
  if (valueTmp.includes('.')) {
    const decimals = valueTmp.split('.')[1];
    if (decimals.length > (length ?? 0)) {
      valueTmp =
        valueTmp.split('.')[0] +
        '.' +
        decimals.slice(
          0,
          PLUS_MINUS_SIGN_REGEX.test(decimals) ? decimals.length : length ?? 0,
        );
    }
  }
  const myNumber = new BigNumber(valueTmp);
  if (
    myNumber.toString().split('.').length > 0 &&
    (myNumber.toString().split('.')[1] ?? '').length <= length
  ) {
    return myNumber.toFixed();
  }
  return myNumber.toFixed(length);
};

export const toRoundInput = (value = '', length: number) => {
  let valueTmp = value.toString();
  if (isEmpty(valueTmp)) return valueTmp;
  if (valueTmp.includes('.')) {
    return Number(value).toFixed(length);
  } else {
    return value;
  }
};

export function parseFormula(formula?: string | null) {
  return `parseFloat(Number(${formula || '0'}).toPrecision(16))`;
}

export const formatFormula = (value: string, length: number) => {
  if (value?.toString()?.includes('.')) {
    if (value?.toString()?.split('.')[1].length > length) {
      return parseFloat(Number(value).toFixed(length)).toString();
    }
  }
  return value;
};
